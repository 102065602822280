import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"

const SipCalculator = () => {
    const [monthlyInvestment, setMonthlyInvestment] = useState(25000);
    const [rateOfReturn, setRateOfReturn] = useState(12);
    const [investmentPeriod, setInvestmentPeriod] = useState(10);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [estimatedReturn, setEstimatedReturn] = useState(0);
    // chart 

    const data = {
        labels: [
            'EST Amount',
            'Invested Amount',
        ],
        datasets: [{
            label: 'Eazr',
            data: [estimatedReturn, totalInvested],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
        }]
    }

    //calculation 
    const calculateReturns = () => {
        const monthlyInvestmentValue = parseFloat(monthlyInvestment);
        const rate = parseFloat(rateOfReturn) / 12 / 100;
        const periods = parseFloat(investmentPeriod) * 12;

        if (monthlyInvestmentValue > 0 && rate > 0 && periods > 0) {
            const { total, invested, estReturn } = calculateSIPReturns(monthlyInvestmentValue, rate, periods);
            setTotalAmount(total.toFixed(2));
            setTotalInvested(invested.toFixed(2));
            setEstimatedReturn(estReturn.toFixed(2));
        } else {
            setTotalAmount('');
            setTotalInvested('');
            setEstimatedReturn('');
        }
    };

    const calculateSIPReturns = (investment, rate, periods) => {
        let total = 0;
        let invested = 0;
        for (let i = 1; i <= periods; i++) {
            invested += investment;
            total += investment * Math.pow(1 + rate, i);
        }
        const estReturn = total - invested;
        return { total, invested, estReturn };
    };

    useEffect(() => {
        calculateReturns()

    }, [monthlyInvestment, rateOfReturn, investmentPeriod])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>SIP Return Calculator</h2>
                <p className='text__16 mb-3'>Embark on a journey of disciplined investing with our intuitive SIP Calculator. Whether you're a novice investor or a seasoned pro, this tool will help you visualize the potential growth of your SIP investments over time.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Input your monthly SIP amount (The amount you plan to invest regularly). <br />
                    2. Adjust the expected annual returns (Average annualized return you anticipate from your investment).<br />
                    3. Set the investment duration (Number of years you intend to continue the SIP). <br />
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount">
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Monthly Investment:</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={monthlyInvestment}
                                            id="loanamount"
                                            min="1000"
                                            onChange={(e) => setMonthlyInvestment(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="2000000"
                                            value={monthlyInvestment}
                                            onChange={(e) => setMonthlyInvestment(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Expected Return (p.a):</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={rateOfReturn}
                                            id="intersetrate"
                                            min="0"
                                            onChange={(e) => setRateOfReturn(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={rateOfReturn}
                                            onChange={(e) => setRateOfReturn(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="loantenure">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Time Period (Years):</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={investmentPeriod}
                                            id="loantenure"
                                            min="0"
                                            onChange={(e) => setInvestmentPeriod(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="2"
                                            value={investmentPeriod}
                                            onChange={(e) => setInvestmentPeriod(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {totalInvested && <div className='m-auto'> Total Invested: {totalInvested}</div>}
                    {estimatedReturn && <div className='m-auto'>Est Returns: {estimatedReturn}</div>}
                    {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount}</div>} </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Forecast Growth: Get an estimate of what your savings can amount to over time. <br />
                        2. Strategic Planning: Decide on the SIP amount based on your long-term goals.<br />
                        3. Motivation Boost: Seeing the potential growth can be a great motivator to stick to your investment plan. <br />
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default SipCalculator