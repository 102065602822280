export const USER_OTP_SENT = "USER_OTP_SENT";
export const OTP_SENT_FAILED = "OTP_SENT_FAILED";
export const SET_CONTACT_NUMBER = "SET_CONTACT_NUMBER";
export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGIN_USER_SUCCESSFUL = "LOGIN_USER_SUCCESSFUL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const DOWNLOAD_TRANSACTION = "DOWNLOAD_TRANSACTION";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";
export const GET_SEARCH_TRANSACTION = "GET_SEARCH_TRANSACTION";
export const GET_SEARCH_TRANSACTION_FAILED = "GET_SEARCH_TRANSACTION_FAILED";
export const CLEAN_UP_SIGN_IN = "CLEAN_UP_SIGN_IN";
export const CLEAN_UP_TRANSACTION = "CLEAN_UP_TRANSACTION";
