import React from 'react'
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from 'react';

import DefaultLayout from './Layouts/DefaultLayout';
import AuthLayout from './Layouts/AuthLayout';

import Homepage from './Pages/Homepage';
import HowItWorks from './Pages/HowItWorks';
import DetailEvent from './Pages/DetailEvent';
import DetailOrder from './Pages/DetailOrder';
import Payment from './Pages/Payment';
import Discover from './Pages/Discover';
import About from './Pages/About';
import Faq from './Pages/Faq';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';


// auth
import Signup from './Pages/Auth/Signup';
// import Login from './Pages/Auth/Login';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import RisetPassword from './Pages/Auth/RisetPassword';
import NewPassword from './Pages/Auth/NewPassword';
import OtpVerification from './Pages/Auth/OtpVerification';
import CalculatorComp from './Components/CalculatorComp';
import PersonalLoan from './Components/Calculator/PersonalLoan/PersonalLoan';
import Quickloan from './Components/PersonalLoan/Quickloan';
import UnsecuredLoan from './Components/PersonalLoan/UnsecuredLoan';
import PostOffice from './Components/Calculator/PostOfficemis/PostOffice';
import HomeLoan from './Components/Calculator/HomeLoan/Homeloan';
import SipCalculator from './Components/Calculator/SIP/SipCalculator';
import PfCalculator from './Components/Calculator/PF/PfCalculator';
import NpsCalculator from './Components/Calculator/NPS/NpsCalculator';
import Flexiloan from './Components/PersonalLoan/Flexiloan';
import BestPersonal from './Components/PersonalLoan/BestPersonal';
import ConsumerLoan from './Components/PersonalLoan/ConsumerLoan/ConsumerLoan';
import LoanAgainstLic from './Components/PersonalLoan/LoanAgainstLic/LoanAgainstLic';
import LoanRepayment from './Components/PersonalLoan/LoanRepayment/LoanRepayment';
import LoanSyndication from './Components/PersonalLoan/LoanSyndication/LoanSyndication';
import DownloadPage from './Components/DownloadPage/DownloadPage';
import ProtectedRouter from './Components/Router/ProtectedRouter';
import Dashboard from './Components/Dashboard/Dashboard';
import Swp from './Components/Calculator/SWP/Swp';
import MutualFund from './Components/Calculator/MutualFund/MutualFund';
import Pension from './Components/Calculator/Pension/Pension';
import Ppf from './Components/Calculator/PPF/Ppf';
import Fd from './Components/Calculator/FD/Fd';
import Rd from './Components/Calculator/RD/Rd';
import LumpSum from './Components/Calculator/LumpSum/LumpSum';
import Gst from './Components/Calculator/GST/Gst';
import CarLoan from './Components/Calculator/CarLoan/CarLoan';
import BikeLoan from './Components/Calculator/BikeLoan/BikeLoan';
import SimpleInterest from './Components/Calculator/SimpleInterest/SimpleInterest';
import CompoundInterest from './Components/Calculator/CompoundInterest/CompoundInterest';
import Gratuity from './Components/Calculator/Gratuity/Gratuity';
// import HraCalculator from './Components/Calculator/HRA/HraCalcultor';
import NscCalculator from './Components/Calculator/NSCCalculator/NscCalculator';
import CagrCalculator from './Components/Calculator/CAGR/CagrCalculator';
import ApyCalculator from './Components/Calculator/APY/ApyCalculator';
import NpvCalculator from './Components/Calculator/NPV/NpvCalculator';
import RoiCalculator from './Components/Calculator/ROI/RoiCalculator';
import HomeLoanPrePayment from './Components/Calculator/HomeLoanPrePayment/HomeLoanPrePayment';
import NewLoginPage from './Pages/Auth/NewLoginPage';
import Login from './Pages/Auth/Login';
import BlogPage from './Pages/BlogPage';
import NewBlogPage from './Pages/NewBlogPage';
import SingleBlog from './Pages/SingleBlog';
import Grievance from './Pages/Grievance';
import Informationsecurity from './Pages/Informationsecurity';
import Dpo from './Pages/Dpo';
import Responsible from './Pages/Responsible';
import Insuranceterms from './Pages/Insuranceterms';
import Fintechassociation from './Pages/Fintechassociation';
import Ethicalcode from './Pages/Ethicalcode';
import RefundandPrivacyPolicy from './Pages/RefundandPrivacyPolicy';

const App = () => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <Routes>
      <Route path="auth" element={<AuthLayout />}>
        <Route index element={<Signup />} />
        <Route path="signup" index element={<Signup />} />
        <Route path="login" index element={<Login />} />
        <Route path=":otp" index element={<OtpVerification />} />
        <Route
          path="forgot-password"
          index
          element={<ForgotPassword />}
        />
        <Route
          path="riset-password"
          index
          element={<RisetPassword />}
        />
        <Route path="new-password" index element={<NewPassword />} />
      </Route>
      <Route path="" element={<DefaultLayout />}>
        {/* <Route index element={<Homepage />} /> */}
        <Route path=":name" index element={<SingleBlog />} />

        <Route index path="/" element={<Homepage />} />
        <Route path="BlogPage" element={<NewBlogPage />} />
        <Route path="for-business" element={<HowItWorks />} />
        <Route path="blog/detail" element={<DetailEvent />} />
        <Route path="order/detail" element={<DetailOrder />} />
        <Route path="order/detail/payment" element={<Payment />} />
        <Route path="discover" element={<Discover />} />
        <Route path="about" element={<About />} />
        <Route path="help" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacyandpolicy" element={<Privacy />} />
        <Route path="grievancepolicy" element={<Grievance />} />
        <Route path="dpoterms" element={<Dpo />} />
        <Route path="responsiblepolicy" element={<Responsible />} />
        <Route
          path="informationpolicy"
          element={<Informationsecurity />}
        />
        <Route path="termsandcondition" element={<Terms />} />
        <Route path="insuranceterms" element={<Insuranceterms />} />
        <Route
          path="fintechassociation"
          element={<Fintechassociation />}
        />
        <Route path="ethicalcode" element={<Ethicalcode />} />
        <Route path="refund&cancellation" element={<RefundandPrivacyPolicy />} />

        <Route path="calculator/:name" element={<CalculatorComp />} />
        <Route path="download" element={<DownloadPage />} />
        <Route path="dashboard" element={<ProtectedRouter />} />

        <Route
          path="calculator/pesonalloan"
          element={<PersonalLoan />}
        />
        <Route
          path="calculator/postoffice"
          element={<PostOffice />}
        />
        <Route path="calculator/homeloan" element={<HomeLoan />} />
        <Route path="calculator/sip" element={<SipCalculator />} />
        <Route path="calculator/pf" element={<PfCalculator />} />
        <Route path="calculator/nps" element={<NpsCalculator />} />
        <Route path="calculator/swp" element={<Swp />} />
        <Route
          path="calculator/mutualfund"
          element={<MutualFund />}
        />
        <Route path="calculator/pension" element={<Pension />} />
        <Route path="calculator/ppf" element={<Ppf />} />
        <Route path="calculator/fd" element={<Fd />} />
        <Route path="calculator/rd" element={<Rd />} />
        <Route path="calculator/lumpsum" element={<LumpSum />} />
        <Route path="calculator/gst" element={<Gst />} />
        <Route path="calculator/carloan" element={<CarLoan />} />
        <Route path="calculator/bikeloan" element={<BikeLoan />} />
        <Route
          path="calculator/simpleinterest"
          element={<SimpleInterest />}
        />
        <Route
          path="calculator/compoundinterest"
          element={<CompoundInterest />}
        />
        <Route path="calculator/gratuity" element={<Gratuity />} />
        {/* <Route path="calculator/hra" element={<HraCalculator/>}/> */}
        <Route path="calculator/nsc" element={<NscCalculator />} />
        <Route path="calculator/cagr" element={<CagrCalculator />} />
        <Route path="calculator/apy" element={<ApyCalculator />} />
        <Route path="calculator/npv" element={<NpvCalculator />} />
        <Route path="calculator/roi" element={<RoiCalculator />} />
        <Route
          path="calculator/homeloan-prepayment"
          element={<HomeLoanPrePayment />}
        />

        {/* Loan */}
        <Route path="loan/quickloan" element={<Quickloan />} />
        <Route
          path="loan/unsecuredloan"
          element={<UnsecuredLoan />}
        />
        <Route path="loan/flexiloan" element={<Flexiloan />} />
        <Route
          path="loan/bestpersonalloan"
          element={<BestPersonal />}
        />
        <Route path="loan/consumerloan" element={<ConsumerLoan />} />
        <Route
          path="loan/loanagainstlic"
          element={<LoanAgainstLic />}
        />
        <Route
          path="loan/loanrepayment"
          element={<LoanRepayment />}
        />
        <Route
          path="loan/loansyndication"
          element={<LoanSyndication />}
        />
      </Route>
    </Routes>
  );
}

export default App
