import React from 'react'
// import Header from './UnsecuredLoan/Header'
import Main from './Main'
// import './UnsecuredLoancss.css'

const UnsecuredLoan = () => {
  return (
 <div style={{margin:'80px 0'}}> 
 <div><Main/></div>
 </div>
  )
}

export default UnsecuredLoan