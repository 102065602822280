import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';


const PopularBlogs = () => {
    return (
        <section className='py-2'>
            <h2 className='text-center text__32 pt-2 mb-3'>Browse Categories</h2>
            <div className='w-full h-auto flex flex-wrap'>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'>
                    <NavLink to="/BlogPage">Health</NavLink>
                </div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/BlogPage">Wellness Insurance</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/BlogPage">Critical Illness</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/BlogPage">Hospicash</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/BlogPage">Personal Accidental Coverage</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/BlogPage">Finance</NavLink></div>
            </div>
        </section>
    )
}

export default PopularBlogs