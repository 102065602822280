import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const Gratuity = () => {
  const [monthlySalary, setMonthlySalary] = useState(60000);
  const [yearsOfService, setYearsOfService] = useState(20);
  const [totalGratuity, setTotalGratuity] = useState(0);
  // chart 



  //calculation 
  const calculateGratuity = () => {
    const salary = parseFloat(monthlySalary);
    const service = parseFloat(yearsOfService);

    // As per the formula for gratuity calculation
    const gratuity = (salary * service * 15) / 26;
    setTotalGratuity(gratuity.toFixed(2));
  };


  useEffect(() => {
    calculateGratuity()
  }, [monthlySalary, yearsOfService])
  return (
    <section >
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'> Gratuity Calculator</h2>
        <p className='text__16 mb-3'>Navigating your post-retirement benefits? Our Gratuity Calculator simplifies the process, helping you estimate the gratuity amount you're entitled to receive upon retiring or leaving a company. Get a clearer understanding of this statutory benefit based on your service years and last drawn salary.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Specify your last drawn basic salary (excluding allowances, bonuses, or any other extra payouts).<br />
          2. If applicable, input the dearness allowance (a specific type of allowance in some sectors).<br />
          3. Indicate the total years of service with your employer.<br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="loanamount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Monthly salary:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={monthlySalary}
                      id="loanamount"
                      min="1000"
                      step="1000"
                      onChange={(e) => setMonthlySalary(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      className='form-range'
                      name="points"
                      min="0"
                      max="1000000"
                      step="2000"
                      value={monthlySalary}
                      onChange={(e) => setMonthlySalary(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />

              <div className='row w-full mb-5'>
                <label for="yearsOfService">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'> Years Of Service :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={yearsOfService}
                      id="yearsOfService"
                      max="5"
                      min="5"
                      onChange={(e) => setYearsOfService(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="yearsOfService"
                      className='form-range'
                      name="points"
                      min="0"
                      max="200"
                      step="5"
                      value={yearsOfService}
                      onChange={(e) => setYearsOfService(e.target.value)}
                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex justify-center text-center items-center'>
            {/* <div className='h-auto'><Pie data={data}/></div> */}
            {totalGratuity && <div className='text__24 leading-9'>Total Gratuity: <br /> <span className='font-bold text__32'>{totalGratuity}</span></div>}
          </div>

        </div>


        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Retirement Planning: Enhance your post-retirement financial planning by understanding potential gratuity payouts.<br />
            2. Legal Alignment: Stay informed about the statutory benefit you're eligible for as per legal norms.<br />
            3. Hassle-Free: Skip complex manual calculations and obtain instant, clear results.<br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default Gratuity