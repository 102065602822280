import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { CloseIcon } from '../Icon/Icon'

export const PaymentStepModal = ({ show, handleClose, handleShow, StatusModal, setStatusModal }) => {
  const onEventClick = (StatusModal) => {
    handleClose()
    setStatusModal(StatusModal)

    setTimeout(function () {
      handleShow()
    }, 500);
  }
  return (
    <Fragment>
      <StepModal show={show} handleClose={() => handleClose()} handleShow={() => handleShow()} StatusModal={StatusModal} setStatusModal={(e) => setStatusModal(e)} onEventClick={(e) => onEventClick(e)} />
    </Fragment>
  )
}

export const StepModal = ({ show, handleClose, handleShow, StatusModal, setStatusModal, onEventClick }) => {
  const showingForm = (e) => {
    switch (e) {
      case "success":
        return <SuccessContent onEventClick={(e) => onEventClick(e)} />
        break;
      case "ticket":
        return <TicketContent onEventClick={(e) => onEventClick(e)} handleClose={() => handleClose()} setStatusModal={(e) => setStatusModal(e)} />
        break;
      default:
        break;
    }
  }
  return (

    <Modal className='modalTicket' centered show={show} onHide={() => {
      handleClose()
      setStatusModal("success")
    }}>
      <Modal.Body>
        {
          showingForm(StatusModal)
        }

      </Modal.Body>
      {
        StatusModal === "ticket" ? <div className="text-center mt-6">
          <div onClick={() => {
            handleClose()
            setStatusModal("success")
          }} className="cursor-pointer w-[48px] h-[48px] rounded-full flex items-center justify-center bg-white mx-auto">
            <CloseIcon />
          </div>
        </div> : ""
      }

    </Modal>
  )
}


const SuccessContent = ({ onEventClick }) => {
  return <Fragment>
    <div className="text-center">
      <img src={window.location.origin + "/images/Illustration.svg"} className='mx-auto -mb-[1rem]' alt="" />

      <h3 className='font-medium text__32 mb-2 relative z-2'>Payment Success!</h3>
      <p className='text__16 text-Mgray relative z-2 mb-6'>Thanks for your order, the order confirmation has <br /> been sent to customer@gmail.com</p>

      <div onClick={() => onEventClick("ticket")} className={'cursor-pointer inline-block cursor-pointer font-medium text__14 text-Mpurple !border-Mpurple btnClass hover:bg-Mpurple hover:text-white relative z-2'}>Show Ticket</div>
    </div>
  </Fragment>
}
const TicketContent = ({ onEventClick, handleClose, setStatusModal }) => {
  return <Fragment>

    <div className="flex flex-wrap gap-3">

      <div className="relative w-full">
        <img src={window.location.origin + "/images/Auto Layout Horizontal.svg"} className='w-full' alt="" />
        <div className="grid grid-cols-2 grid-rows-1 text-center text__12 absolute z-2 w-full left-0 bottom-[1rem]">
          <span>029342</span>
          <span>0192342</span>
        </div>
      </div>

      <hr className='w-full border-dashed !border-[#818181] border-t-[1px]' />

      <div className="w-full">
        <p className='text__14 text-Mgray mb-2'>Event</p>
        <h3 className='font-medium text__18'>September Perform 2023 (Standing - Early Bird)</h3>
      </div>

      <hr className='w-full border-dashed !border-[#818181] border-t-[1px]' />

      <div className="w-full flex items-center justify-between">
        <div className="">
          <p className='text__14 text-Mgray mb-2'>Name</p>
          <h3 className='font-medium text__18'>Wilson Culhane</h3>
        </div>
        <div className="text-right">
          <p className='text__14 text-Mgray mb-2'>Seat</p>
          <h3 className='font-medium text__18'>-</h3>
        </div>
      </div>

      <div className="w-full flex items-center justify-between">
        <div className="">
          <p className='text__14 text-Mgray mb-2'>Date and Hour</p>
          <h3 className='font-medium text__18'>Order ID</h3>
        </div>
        <div className="text-right">
          <p className='text__14 text-Mgray mb-2'>24 Sep 2023 • 18.00 - 23.00 PM</p>
          <h3 className='font-medium text__18'>MOT3203920212</h3>
        </div>
      </div>

    </div>

    <div className="grid grid-cols-2 grid-rows-1 gap-3 mt-6">
      <div onClick={() => {
        handleClose()
        setStatusModal("success")
      }} className='cursor-pointer font-medium text__14 inline-block cursor-pointer font-medium text__14 text-Mpurple !border-Mpurple btnClass hover:bg-Mpurple hover:text-white w-full text-center'>Back to Home</div>

      <a href="#!" className=' font-medium text__14 inline-block cursor-pointer font-medium text__14 text-white btnClass bg-Mpurple w-full text-center'>Download Ticket</a>
    </div>


  </Fragment>
}