import React from "react";
// import cn from "classnames";
// import styles from "./Card.module.sass";
import './Cardcss.css'

const Card = ({
  title,
  head,
  children,
  
}) => {
  return (
    <div className="card">
      {title && (
        <div className="head">
          <div className="title">{title}</div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
