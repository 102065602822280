import axios from 'axios';
import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ip } from '../../config/config';

const SecContact = () => {
    const { handleSubmit, register, reset } = useForm()
    // const notify = () => ;

    const onSubmit = (data) => {
        console.log(data)
        axios.post(`${ip}/support-details`, data)
            .then(res => {
                console.log(res)
                toast.success('🦄 Wow so easy!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                }
                )
                reset()

            })
            .catch(err => console.log(err))

    }
    return (
        <section className='bg-Mbg'>
            <Container>
                <div className="p-6 bg-white rounded-[24px]">
                    <Row className='justify-between'>
                        <Col className='mb-4 md:mb-0' md={4}>
                            <h2 className='font-medium text__48 mb-3'>Get in Touch</h2>
                            <p className='text__18 text-[#525252]'>If you have any questions about Eazr or it’s services or are not sure how to go about, contact our team and let’s connect.</p>

                            <div className="my-8">
                                <p className='text__14 mb-2'>Don’t like forms?</p>
                                <h5 className='font-medium text__20 text-Mblue'>cloud@eazr.app</h5>
                            </div>

                            <p className='text__14 mb-2'>Social Media</p>
                            <div className="flex items-center gap-2">
                                <a href="https://twitter.com/eazrapp">
                                    <img src="./../images/as (1).svg" alt="" />
                                </a>

                                <a href="https://www.instagram.com/eazr.app/">
                                    <img src="./../images/as (3).svg" alt="" />
                                </a>
                                <a href="https://www.facebook.com/EazrApp">
                                    <img src="./../images/as (4).svg" alt="" />
                                </a>
                                <a href="https://in.linkedin.com/in/eazr-digipayments-705142227?trk=people_directory">
                                    <img src="./../images/as (5).svg" alt="" />
                                </a>
                            </div>
                        </Col>
                        <Col md={7}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col className='col-12 md:col-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Full Name<span className='text-[#ED544E]'>*</span></Form.Label>
                                            <input type="text" placeholder="Full Name" {...register("fullName")} className='block w-full font-medium text__14 bg-[#FFFFFF] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border border-solid border-[#F5F5F5] focus:border-[#F6F6F6] focus:border-none focus:bg-[#F6F6F6]' />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-12 md:col-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Company Name<span className='text-[#ED544E]'>*</span></Form.Label>
                                            <input type="text" placeholder="Company Name" {...register("companyName")} className=' block w-full font-medium text__14 bg-[#FFFFFF] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border border-solid border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-12'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Email<span className='text-[#ED544E]'>*</span></Form.Label>
                                            <input type="email" placeholder="Email address" {...register("email")} className=' block w-full font-medium text__14 bg-[#FFFFFF] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border border-solid border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Your Message<span className='text-[#ED544E]'>*</span></Form.Label>
                                            <textarea
                                                as="textarea"
                                                placeholder="How we can help?"
                                                {...register("message")}
                                                style={{ height: '100px' }}
                                                className='block font-medium text__14 w-full bg-[#FFFFFF] h-[54px] rounded-[20px] py-3 px-3 outline-none shadow-none focus:outline-none focus:shadow-none border border-solid border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                                            ></textarea>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <button type='submit'> <a className='inline-block cursor-pointer text-center font-medium text__16 text-white !py-[15px] !px-[28px] bg-Mblue !border-Mblue btnClass md:w-auto w-full'>Submit</a></button>
                            </form>
                        </Col>

                    </Row>
                </div>
            </Container>
            <ToastContainer />
        </section>
    )
}

export default SecContact
