import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const SecCreate = () => {
    return (
        <section className='bg-[#F8F8F8]'>
            <Container>
                <div className="w-full h-60 bg-Mpurple  md:text-center rounded-[32px] relative">
                <img
                        srcSet="/images/NewFrame-mobile.png 480w, /images/NewFrame.png 1024w"
                        sizes="(max-width: 480px) 100vw, 1024px"
                        className='w-full h-60 rounded-[18px] object-fill'
                        alt=""
                    />
                    <div className="absolute z-2 bottom-10 left-24">
                        {/* <h2 className='font-medium text__48 text-white mb-2'>Sign up and get started</h2>
                        <p className='text__16 mb-8 md:mb-12 text-[#F5F5F5]'>Our ticketing platform is made for you - and loved by everyone.</p> */}

                        
                             <div className="">
                                <NavLink to="/auth/login" className='py-2 px-4 bg-blue-500 text-white font-semibold rounded-full shadow-md hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:ring-opacity-75'>Login</NavLink>

                                 {/* <img className='absolute top-1/2 -right-[6rem] lg:-right-[9rem] w-[5rem] lg:w-auto' src="./../images/arrowws.svg" alt="" /> */}
                             </div>
                    


                    </div>
                </div>
            </Container>
        </section>
    )
}

export default SecCreate
