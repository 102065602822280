import {
  GET_TRANSACTIONS,
  CLEAN_UP_SIGN_IN,
  GET_TRANSACTIONS_FAILED,
  LOGIN_USER_FAILED,
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESSFUL,
  REMOVE_TOKEN,
  SET_CONTACT_NUMBER,
  SET_TOKEN,
  USER_OTP_SENT,
  GET_SEARCH_TRANSACTION,
  GET_SEARCH_TRANSACTION_FAILED,
  DOWNLOAD_TRANSACTION,
  CLEAN_UP_TRANSACTION,
} from "./ActionType";

const initialState = {
  isAuthenticated: false,
  loading: false,
  contactNumber: "",
  user: null,
  search: [],
  currentUSER: [],
  USERCurrent: [],
  error: null,
  otp_errors: null,
  accessToken: null,
  transactions: [],
  download_transaction: [],
  fetchUserProfile: null,
};

export const otpReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_UP_SIGN_IN:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        contactNumber: "",
        user: null,
        currentUSER: [],
        USERCurrent: [],
        error: null,
        otp_errors: null,
        accessToken: null,
        transactions: [],
        download_transaction: [],
        fetchUserProfile: null,
      };
    case USER_OTP_SENT:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CONTACT_NUMBER:
      return {
        ...state,
        contactNumber: action.payload,
      };
    case LOGIN_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_USER_SUCCESSFUL:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
        user: [],
      };
    case SET_TOKEN:
      return {
        ...state,
        loading: false,
        accessToken: action.payload,
      };
    case REMOVE_TOKEN:
      return {
        ...state,
        accessToken: null,
        isAuthenticated: false,
        transactions: [],
        transactionData: [],
        contactNumber: "",
        user: null,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case DOWNLOAD_TRANSACTION:
      return {
        ...state,
        download_transaction: action.payload,
      };
    case CLEAN_UP_TRANSACTION:
      console.log("cleaned transaction");
      return {
        ...state,
        download_transaction: [],
      };
    case GET_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SEARCH_TRANSACTION:
      return {
        ...state,
        loading: false,
        search: action.payload,
      };
    case GET_SEARCH_TRANSACTION_FAILED:
      return {
        error: action.payload,
      };

    default:
      return state;
  }
};
