import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';


const PopularCalculator = () => {
    return (
        <section>
            <h2 className='text-center text__32 mb-3'>Popular Calculators</h2>
            <div className='w-full h-auto flex flex-wrap'>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'>
                    <NavLink to="/calculator/sip">SIP Calculator</NavLink>
                </div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/calculator/mutualfund">Mutual Fund Calculator</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/calculator/pesonalloan">Personal Loan EMI Calculator</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/calculator/homeloan">Home Loan EMI Calculator</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/calculator/gratuity">Gratuity Calculator</NavLink></div>
                <div className='border my-2 mx-auto flex justify-center items-center p-3 rounded hover:text-Mblue w-[100%] md:w-[30%]'><NavLink to="/calculator/carloan">Car Loan EMI Calculator</NavLink></div>
            </div>
        </section>
    )
}

export default PopularCalculator