import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const Ppf = () => {
    // const { name } = useParams();
    const [yearlyInvestment, setYearlyInvestment] = useState(10000);
    const [timePeriod, setTimePeriod] = useState(15);
    const [interestRate, setInterestRate] = useState(7.1);
    const [investedAmount, setInvestedAmount] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [maturityValue, setMaturityValue] = useState(0);
    // chart 

    const data = {
        labels: [
            'principle amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [investedAmount, totalInterest],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculatePPF = () => {
        const totalYears = parseInt(timePeriod);
        const interestRatePerYear = parseFloat(interestRate);
        const annualInvestment = parseFloat(yearlyInvestment);
    
        const investedAmount = annualInvestment * totalYears;
        setInvestedAmount(investedAmount.toFixed(2));
    
        const totalInterest = (annualInvestment * ((1 + interestRatePerYear) ** totalYears - 1)) / (interestRatePerYear);
        setTotalInterest(totalInterest.toFixed(2));
    
        const maturityValue = investedAmount + totalInterest;
        setMaturityValue(maturityValue.toFixed(2));
      };

    useEffect(() => {
        calculatePPF()

    }, [yearlyInvestment, timePeriod])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>PPF Calculator</h2>
                <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col items-center'>
                            <div className='w-100'>
                                <label for="loanamount" >
                                    <div className="flex justify-between items-center">
                                        <p className="w-60">Yearly Investment (&#8377;):</p>
                                        <Form.Control
                                            type="number"
                                            value={yearlyInvestment}
                                            id="loanamount"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setYearlyInvestment(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="150000"
                                            step="2000"
                                            value={yearlyInvestment}
                                            onChange={(e) => setYearlyInvestment(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="timePeriod">
                                    <div className="w-full flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Time Period (Years):</p>
                                        <Form.Control
                                            type="number"
                                            value={timePeriod}
                                            id="timePeriod"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="interestRate">
                                    <div className="flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Rate of Interest (2022)</p>
                                        <Form.Control
                                            type="number"
                                            value={interestRate}
                                            id="interestRate"
                                            className='w-50'
                                            readOnly
                                            min="0"
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />{" "}
                                    </div>
                                    {/* <div className="text-center">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="25"
                                            value={interestRate}
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />
                                    </div> */}
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-auto'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {investedAmount && <div className='m-auto'>Total Investment: {investedAmount}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: ₹{totalInterest}</div>}
                    {maturityValue && <div className='m-auto'>Maturity Value: {maturityValue}</div>} </div>

                <div>
                    <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a PPF Calculator?</h1>
                    <p className='text__16 leading-7 mb-2'>A PPF (Public Provident Fund) calculator is a financial tool or online application used to calculate and estimate the future value of investments made in a PPF account. The Public Provident Fund is a popular long-term savings and investment scheme offered by the Indian government. It provides a safe and tax-efficient way for individuals to save for their retirement and other financial goals.</p>
                    <p className='text__16 leading-7 mb-2'>PPF calculators are valuable tools for individuals who want to plan and project the growth of their investments in a PPF account. They help users set realistic financial goals, understand the impact of their contributions, and make informed decisions about their investments.</p>
                    <h1 className='text__24 mb-2'>What is the Formula for PPF Calculation?</h1>
                    <p> The general formula used to calculate PPF is given below: <br/>

                        F = P[({1}-1)] <br/>

                        Where the variables represent the following: <br/>
                        F is the maturity proceeds of the PPF <br/>
                        P is the annual instalments <br/>
                        n is the number of years  <br/>
                        i is the rate of interest divided by 100 <br/>

                        For example, you invest an amount of ₹100,000 annually towards your PPF account at an interest rate of 7.10% for a period of 15 years. The maturity value at the closing year would be equal to ₹27,12,139.</p>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default Ppf