import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const SecFaq = () => {
    const dataFaq = [
        {
            title: "How does the free trial work?",
            desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        },
        {
            title: "Your fees are so low! How come?",
            desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        },
        {
            title: "Can I pass the fees on to my customers?",
            desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        },
        // {
        //     title: "Can I use the platform for free?",
        //     desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        // },
        // {
        //     title: "How do Pre pay ticket credits work?",
        //     desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        // },
        // {
        //     title: "Do you recommend Pay as you go or Pre pay?",
        //     desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`
        // },
    ]


    const [ToogleFaq, setToogleFaq] = useState(0)

    const setToogleFaqWrap = (e) => {
        if (e == ToogleFaq) {
            setToogleFaq(-1)
        } else {
            setToogleFaq(e)
        }
    }

    return (
        <section className='bg-[#F8F8F8]'>
            <Container>
                <div className="mb-10">
                    <h3 className='text__48 font-medium mb-2'>FAQs</h3>
                </div>

                <div className="flex flex-wrap gap-3">
                    {
                        dataFaq.map((obj, i) => {
                            return <Fragment>
                                <div className='bg-white px-3 rounded-[20px] overflow-hidden w-full'>
                                    <div className="py-3 flex justify-between items-center cursor-pointer  " onClick={() => setToogleFaqWrap(i)}>
                                        <h5 className='font-medium text__24'>{obj.title}</h5>
                                        <div className={"arrowPlus relative w-[24px] h-[24px] " + (ToogleFaq == i ? "active" : "")}>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className={ToogleFaq == i ? "block pb-3" : "hidden"}>
                                        <p className='text__18'>{obj.desc}</p>
                                    </div>
                                </div>
                            </Fragment>
                        })
                    }
                </div>
                <div className="text-center mt-5">
                        <div className="inline-block relative">
                            <NavLink to="/discover" className={'inline-block cursor-pointer font-medium text__14 text-Mpurple !border-Mpurple btnClass hover:bg-Mpurple hover:text-white relative z-2'}>Discover More
                            </NavLink>

                            <img className='absolute top-1/2 -right-[5rem]' src="./../images/fdgdfhfh.svg" alt="" />
                        </div>
                    </div>
            </Container>
        </section>

    )
}

export default SecFaq
