import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { CardEvents } from '../Components/Card/Card'
import SecCreate from '../Components/Pages/SecCreate'

const Discover = () => {
    const dataDiscover = [
        {
            icon: './../images/party-confetti.svg',
            title: 'All Event'
        },
        {
            icon: './../images/employee-computer-laptop.svg',
            title: 'Workshops and classes'
        },
        {
            icon: './../images/employee-computer-laptop-1.svg',
            title: 'Workshops and classes'
        },
        {
            icon: './../images/package-box-update-loading.svg',
            title: 'Charity events'
        },
        {
            icon: './../images/museum.svg',
            title: 'Museums and galleries'
        },
        {
            icon: './../images/show-theater-masks.svg',
            title: 'Theatre'
        },
    ]

    const dataEvents = [
        {
            img: "./../images/ev (1).jpg",
            title: "September Perform 2023",
            date: "Sep 31-36 , 2023",
            location: "San Diego, California - US"
        },
        {
            img: "./../images/ev (2).jpg",
            title: "September Perform 2023",
            date: "Sep 31-36 , 2023",
            location: "San Diego, California - US"
        },
        {
            img: "./../images/ev (3).jpg",
            title: "September Perform 2023",
            date: "Sep 31-36 , 2023",
            location: "San Diego, California - US"
        },
    ]


    const [selectDiscover, setselectDiscover] = useState(0)
    return (
        <Fragment>

            <section className='bg-Mbg'>
                <Container>
                    <h2 className='font-medium text__48 mb-10'>Discover events</h2>

                    <div className="overflow-auto">
                        <div className="grid grid-rows-1 grid-cols-6 gap-2 lg:gap-4 w-[919px] lg:w-auto">
                            {
                                dataDiscover.map((obj, i) => {
                                    return <div className="text-center cursor-pointer" onClick={() => setselectDiscover(i)}>
                                        <div className={"w-[80px] lg:w-[100px] xl:w-[140px] h-[80px] lg:h-[100px] xl:h-[140px] p-3 xl:p-0 rounded-full bg-[#F5F5F5] border border-solid  flex items-center justify-center items-center mb-3 mx-auto " + (selectDiscover == i ? "!border-Mpurple" : "!border-[#E5E5E5]")}>
                                            <img src={obj.icon} alt="" />
                                        </div>
                                        <h5 className={'font-medium ' + (selectDiscover == i ? "text__24" : "text__20")}>{obj.title}</h5>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </Container>
            </section>


            <section className='bg-Mbg'>
                <Container>
                    <div className="mb-10">
                        <h2 className='font-medium text__48 mb-2'>Featured events</h2>
                        <p className='text__18 text-Mgray'>Check out the amazing events that are live and currently trending.</p>
                    </div>

                    <Row className='gap-y-4'>
                        {
                            dataEvents.map((obj) => {
                                return <Col md={6} lg={4}>
                                    <CardEvents data={obj} />
                                </Col>
                            })
                        }

                    </Row>
                </Container>
            </section>



            <section className='bg-Mbg'>
                <Container>
                    <div className="mb-10">
                        <h2 className='font-medium text__48 mb-2'>Festivals</h2>
                        <p className='text__18 text-Mgray'>Discover hidden gems, through to chart-topping headline acts.</p>
                    </div>

                    <Row className='gap-y-6'>
                        {
                            dataEvents.map((obj) => {
                                return <Col md={6} lg={4}>
                                    <CardEvents data={obj} />
                                </Col>
                            })
                        }
                        {
                            dataEvents.map((obj) => {
                                return <Col md={6} lg={4}>
                                    <CardEvents data={obj} />
                                </Col>
                            })
                        }

                    </Row>
                </Container>
            </section>

            <SecCreate />
        </Fragment>
    )
}

export default Discover
