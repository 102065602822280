import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { CloseIcon, RightArrowIcon } from '../Components/Icon/Icon'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const DetailEvent = () => {

    const [PriceCount, setPriceCount] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [toogleType, settoogleType] = useState("Regular")

    const ListTicket = (s) => {
        const [numberCount, setnumberCount] = useState(0)

        const numberCounter = (e) => {
            if (e == "plus") {
                setnumberCount(numberCount + 1)
                setPriceCount(true)
            } else {
                if ((numberCount - 1) == 0) {
                    setPriceCount(false)
                }
                if ((numberCount - 1) >= 0) {
                    setnumberCount(numberCount - 1)
                }
            }
        }
        return <Fragment>
            <div className="flex justify-between w-full">
                <div className="">
                    <h5 className='text__16 font-medium'>Standing - Early Bird - (18:00 - 23:00)</h5>
                    <p className='text__14'>Standing ticket only, no access to tables.</p>
                    <h4 className='text__18 font-bold'>$18</h4>
                </div>

                {
                    s === "Sold Out" ? <div className='font-medium text__14 px-3 py-2 rounded-full bg-[#ED544E] text-white flex-shrink-0 h-fit'>Sold Out</div> : <div className="flex items-center gap-1">
                        <img src="./../images/size.svg" onClick={() => numberCounter()} className='cursor-pointer' alt="" />
                        <input type="text" value={numberCount} className='font-medium text__16 w-[32px] h-[32px] rounded-full border border-solid border-[#E5E5E5] bg-transparent outline-none focus:outline-none text-center' />
                        <img src="./../images/size (1).svg" onClick={() => numberCounter("plus")} className='cursor-pointer' alt="" />
                    </div>
                }
            </div>
        </Fragment>
    }
    return (
        <Fragment>

            <Modal className='modalTicket' show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="text-center mb-10">
                        <h2 className='text__32 font-medium mb-2'>September Perform 2023</h2>
                        <p className='font-medium text__16'>Wed 24 Sep 2023 - Tue 25 Sep 2023</p>
                    </div>

                    <div className="grid grid-rows-1 grid-cols-3 gap-3 p-1 bg-[#F2F2F2] font-medium text__14 rounded-full text-center">
                        <div onClick={() => settoogleType("Regular")} className={"w-full py-2 rounded-full cursor-pointer " + (toogleType == "Regular" ? "bg-Mpurple text-white" : "text-Mpurple")}>
                            Regular
                        </div>
                        <div onClick={() => settoogleType("Standard")} className={"w-full py-2 rounded-full cursor-pointer " + (toogleType == "Standard" ? "bg-Mpurple text-white" : "text-Mpurple")}>
                            Standard
                        </div>
                        <div onClick={() => settoogleType("VIP")} className={"w-full py-2 rounded-full cursor-pointer " + (toogleType == "VIP" ? "bg-Mpurple text-white" : "text-Mpurple")}>
                            VIP
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-4 mt-6">

                        <div className="w-full flex flex-wrap gap-3">
                            <div className="flex items-center gap-2 w-full">
                                <img src="./../images/calendar.svg" alt="" />
                                <span className='text__16'>Wed 24 Sep 2023</span>
                            </div>

                            {ListTicket("Sold Out")}
                            {ListTicket()}
                            {ListTicket()}
                        </div>

                        <div className="w-full flex flex-wrap gap-3">
                            <div className="flex items-center gap-2 w-full">
                                <img src="./../images/calendar.svg" alt="" />
                                <span className='text__16'>Wed 25 Sep 2023</span>
                            </div>

                            {ListTicket("Sold Out")}
                            {ListTicket()}
                            {ListTicket()}
                        </div>

                    </div>


                    <div className="flex items-center justify-between mt-10">
                        <p className='text__16'>Sub Total: <b>$20</b></p>
                        <div onClick={handleClose} className='cursor-pointer font-medium text__14 inline-block cursor-pointer font-medium text__14  text-white btnClass !py-[16px] bg-Mpurple'>Confirm</div>
                    </div>

                </Modal.Body>

                <div className="text-center mt-6">
                    <div onClick={handleClose} className="cursor-pointer w-[48px] h-[48px] rounded-full flex items-center justify-center bg-white mx-auto">
                        <CloseIcon />
                    </div>
                </div>
            </Modal>

            <section className='bg-Mbg'>
                <Container>
                    <div className="w-full p-4 bg-white rounded-[24px]">
                        <img src="./../images/dsfdsgd.png" className='w-full h-[200px] md:h-[300px] object-cover rounded-[20px] mb-4' alt="" />

                        <Row className='gap-y-6'>
                            <Col md={7} lg={8}>
                                <h3 className='font-medium text__32 mb-3'>September Perform 2023</h3>

                                <div className="flex items-center gap-2 mb-4">
                                    <div className="flex items-center">
                                        <img src="./../images/el (1).png" className='w-[32px] h-[32px] rounded-full object-cover border border-solid border-white' alt="" />
                                        <img src="./../images/el (2).png" className='-ml-[8px] w-[32px] h-[32px] rounded-full object-cover border border-solid border-white' alt="" />
                                        <img src="./../images/el (3).png" className='-ml-[8px] w-[32px] h-[32px] rounded-full object-cover border border-solid border-white' alt="" />
                                    </div>
                                    <span className='text__14'><b>+20,000</b> participant</span>
                                </div>

                                <h4 className='font-medium text__24 mb-2'>About Event</h4>
                                <p className='text__18'>The most important date of Germany's cultural calendar arrives back at Exeter Castle!</p>
                                <p className='text__18 mb-3'>Taking place inside what is set to be one of Europe’s Largest bier tent for a Bavarian style festival in Exeter Castle including a showcase some of the best and authentic oompah bands from around the world across both sessions. Plus a large selection of traditional German foods plus those all important steins!</p>
                                <p className='text__18 mb-3'>Over 18’s only - all sales are final - ID May be required by all - Right of admission reserved</p>
                                <p className='text__18 mb-3'>Tickets are available subject to license via www.oktoberfestexeter.co.uk</p>
                                <p className='text__18 mb-3'> If you would like more information about Oktoberfest Exeter please visit www.oktoberfestexeter.co.uk Prost!</p>


                                <div className="inline-block p-3 rounded-[20px] border border-solid border-[#F5F5F5] flex-wrap mb-4">
                                    <div className="flex items-center gap-2 w-full mb-3">
                                        <img src="./../images/ic (1).svg" alt="" />
                                        <div>
                                            <h5 className='font-medium text__16'>Wed 24 Sep 2023 - Tue 25 Sep 2023</h5>
                                            <p className='text__14 text-Mgray'>18.00 - 23.00 PM (GMT +08:00)</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2 w-full mb-3">
                                        <img src="./../images/ic (2).svg" alt="" />
                                        <div>
                                            <h5 className='font-medium text__16'>Highland Park</h5>
                                            <p className='text__14 text-Mgray'>1536 Stellar Dr, Kenai, Alaska 99611, USA</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2 w-full">
                                        <img src="./../images/ic (3).svg" alt="" />
                                        <div>
                                            <h5 className='font-medium text__16'>$20.00 - $1.200.00</h5>
                                            <p className='text__14 text-Mgray'>Ticket price depends on package.</p>
                                        </div>
                                    </div>
                                </div>

                                <h4 className='font-medium text__24 mb-2'>Location</h4>

                                <div className="flex items-center gap-2 mb-2">
                                    <img src="./../images/location.svg" alt="" />
                                    <span className='text__14 text-Mgray'>Grand City St. 100, New York, United States</span>
                                </div>

                                <img src="./../images/Map.png" className='w-full' alt="" />

                            </Col>
                            <Col md={5} lg={4}>
                                <div className="w-full p-4 border border-solid border-[#F5F5F5] rounded-[20px]">
                                    <h5 className='text__16 font-medium mb-3'>Ticket</h5>
                                    <div className="border border-solid border-[#F5F5F5] cursor-pointer rounded-full flex items-center justify-between px-3 py-3" onClick={handleShow}>
                                        <span className='font-medium text__16'>Select ticket</span>
                                        <RightArrowIcon width={24} height={24} />
                                    </div>

                                    {
                                        PriceCount ? <div className="flex items-center justify-between">
                                            <p className='text__16'>Sub Total:</p>
                                            <h5 className='font-semibold text__18'>$20</h5>
                                        </div> : ''
                                    }


                                    <NavLink to="/order/detail" className='w-full text-center font-medium text__16 inline-block cursor-pointer font-medium text__14 text-white btnClass !py-[16px] bg-Mblue my-4'>Buy Ticket</NavLink>

                                    <h5 className='text__18 mb-2'>Share this event:</h5>
                                    <div className="flex items-center gap-2">
                                        <a href="#!">
                                            <img src="./../images/sos (1).svg" alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src="./../images/sos (2).svg" alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src="./../images/sos (3).svg" alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src="./../images/sos (4).svg" alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src="./../images/sos (5).svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default DetailEvent
