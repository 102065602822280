import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"

const SimpleInterest = () => {
    const [totalInvestment, setTotalInvestment] = useState(25000);
    const [returnRate, setReturnRate] = useState(12);
    const [timePeriod, setTimePeriod] = useState(10);
    const [principalAmount, setPrincipalAmount] = useState('');
    const [interestAmount, setInterestAmount] = useState('');
    const [totalValue, setTotalValue] = useState('');
    // chart 

    const data = {
        labels: [
            'Total Interest ',
            'Principle Amount',
        ],
        datasets: [{
            label: 'Eazr',
            data: [interestAmount, principalAmount],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
        }]
    }

    //calculation 
    const calculateReturns = () => {
        const principal = parseFloat(totalInvestment);
        const rate = parseFloat(returnRate) / 100; // Convert rate to decimal
        const time = parseFloat(timePeriod);

        const interest = (principal * rate * time).toFixed(2);
        const principalAmt = (principal).toFixed(2);
        const totalValueAmt = (principal + parseFloat(interest)).toFixed(2);

        setPrincipalAmount(principalAmt);
        setInterestAmount(interest);
        setTotalValue(totalValueAmt);
    };



    useEffect(() => {
        calculateReturns()

    }, [totalInvestment, returnRate, timePeriod])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Simple Interest Calculator</h2>
                <p className='text__16 mb-3'>Dive into the world of finance with clarity using our Simple Interest Calculator. Designed for straightforward calculations, this tool is perfect for those who want a clear understanding of the interest earned or owed over a specific period.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Input the principal amount (The initial sum of money that you have invested or borrowed).<br />
                    2. Specify the rate of interest (The annual interest rate in percentage).<br />
                    3. Adjust the time duration (The period over which the interest will be calculated, usually in years).<br />
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount">
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Total Investment:</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={totalInvestment}
                                            id="loanamount"
                                            min="1000"
                                            onChange={(e) => setTotalInvestment(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="2000"
                                            value={totalInvestment}
                                            onChange={(e) => setTotalInvestment(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Expected Return (p.a):</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={returnRate}
                                            id="intersetrate"
                                            min="0"
                                            onChange={(e) => setReturnRate(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="1"
                                            value={returnRate}
                                            onChange={(e) => setReturnRate(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="loantenure">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Time Period (Years):</p>
                                        <input
                                            type="number"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={timePeriod}
                                            id="loantenure"
                                            min="0"
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="1"
                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {totalInvestment && <div className='m-auto'> Total Investment: {totalInvestment}</div>}
                    {interestAmount && <div className='m-auto'>Total Interest: {interestAmount}</div>}
                    {totalValue && <div className='m-auto'>Total Amount : {totalValue}</div>} </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Educational Tool: Perfect for students and finance novices to understand basic interest calculations.<br />
                        2. Financial Decisions: Assists in making informed decisions on investments or loans based on the interest outcome.<br />
                        3. Efficient and Precise: No need for manual calculations, ensuring accuracy and saving time. <br />
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default SimpleInterest