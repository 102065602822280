import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2';
// import './PersonalLoancss.css'
// import rupeelogo from './../../../LottieJson/eazrop-logo.png'

import {
  Chart as ChartJS
} from "chart.js/auto"

const RoiCalculator = () => {
  // const { name } = useParams();
  const [amountInvested, setAmountInvested] = useState(500000);
  const [amountReturned, setAmountReturned] = useState(1000000);
  const [duration, setDuration] = useState(5);
  const [annualizedROI, setAnnualizedROI] = useState(0);
  const [roi, setROI] = useState(0);
  const [investmentGain, setInvestmentGain] = useState(0);

  // chart 

  const data = {
    labels: [
      'Investment',
      'Profit',
    ],
    datasets: [{
      label: 'Eazr',
      data: [amountInvested, investmentGain],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateROI = () => {
    const invested = parseFloat(amountInvested);
    const returned = parseFloat(amountReturned);
    const years = parseInt(duration);

    // Calculate ROI
    const roi = ((returned - invested) / invested) * 100;
    setROI(roi.toFixed(2));

    // Calculate Annualized ROI
    const annualizedROI = ((1 + roi / 100) ** (1 / years) - 1) * 100;
    setAnnualizedROI(annualizedROI.toFixed(2));

    // Calculate Investment Gain
    const gain = returned - invested;
    setInvestmentGain(gain.toFixed(2));
  };

  useEffect(() => {
    calculateROI()
  }, [amountInvested, duration, amountReturned])
  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>ROI Calculator</h2>
        <p className='text__16 mb-3'>Dive into the essence of your investments with our ROI Calculator. A quintessential tool for both novices and experts, it quantifies the performance of an investment relative to its cost. By determining the ROI, you can easily gauge the efficiency and profitability of an investment.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Specify the initial cost of investment (The starting amount or cost you invested).<br />
          2. Indicate the final return value (The total amount received at the end of the investment period).<br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="amountInvested">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Amount Invested:</p>
                    <input
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      type="number"
                      value={amountInvested}
                      id="amountInvested"
                      min="1000"
                      onChange={(e) => setAmountInvested(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="amountInvested"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="2000"
                      value={amountInvested}
                      onChange={(e) => setAmountInvested(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="amountReturned">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Amount Returned:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={amountReturned}
                      id="amountReturned"
                      min="0"
                      onChange={(e) => setAmountReturned(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <input
                      type="range"
                      id="amountReturned"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="2"
                      value={amountReturned}
                      onChange={(e) => setAmountReturned(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="duration">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Duration(Years):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={duration}
                      id="duration"
                      min="0"
                      onChange={(e) => setDuration(parseInt(e.target.value) || 0)}

                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="duration"
                      name="points"
                      className='form-range'
                      min="0"
                      max="200"
                      step="25"
                      value={duration}
                      onChange={(e) => setDuration(parseInt(e.target.value) || 0)}

                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {investmentGain && <div className='m-auto'>Invested Gain: {investmentGain}&nbsp;</div>}
          {roi && <div className='m-auto'>ROI: {roi}</div>}
          {annualizedROI && <div className='m-auto'>Annualized ROI: {annualizedROI}</div>}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Performance Measurement: Instantly assess how effectively an investment has performed in relation to its cost. <br />
            2. Comparative Analysis: Compare ROIs of diverse investments to make informed decisions about where to allocate resources.<br />
            3. Objective Decision Making: ROI offers a clear, quantitative measure, aiding in objective decision-making processes. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default RoiCalculator