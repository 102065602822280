import React, { Fragment, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardEvents } from "../Components/Card/Card";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie";
import landing2 from "../LottieJson/trimmed-lottieline.json";
import ribbon from "../LottieJson/NewRibbonlottie.json";
import confitee from "../LottieJson/Confetti_1.json";
import "./HomePagecss.css";
import axios from "axios";
import { ip } from "../config/config";
import testImage from "./../LottieJson/abstract.jpg";
import "./../Style/Homepage.css";
import Herosection from "./component/Herosection";

const Homepage = () => {
  const [tooglePlan, settooglePlan] = useState("Mothly");
  const [blogData, setBlogData] = useState([]);

  const dataUsedFeatures = [
    {
      title: "How quick is this Health Credit thingy? ",
      desc: "Super quick! Once you're approved, you can usually get the credit activated in few minutes. No waiting games here!",
    },
    {
      title:
        "Zero Downpayment Insurance ? Sounds cool, but what's the catch?",
      desc: "No catch, promise! Pick a health insurance plan, get started without paying a dime upfront, and then just follow the repayment plan you pick. Easy peasy!",
    },

    {
      title: "So, I can buy wellness stuff now and pay later?",
      desc: "Exactly! Grab what you need, and then choose when and how you want to pay. We're all about giving you that flexibility.",
    },
    {
      title: "One-tap payments? Like, for real?",
      desc: "For real! We made it super simple. Just one tap and you're good to go, whether it's full payments, EMIs, or anything in between.",
    },
  ];

  const [ToogleAccordion, setToogleAccordion] = useState(0);

  const setToogleAccordionWrap = (e) => {
    if (e == ToogleAccordion) {
      setToogleAccordion(-1);
    } else {
      setToogleAccordion(e);
    }
  };

  const dataEvents = [
    {
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210309631-understanding-cashle.jpg",
      title: "Understanding Cashless Health Insurance",
      category: "Health Insurance",
      location: "San Diego, California - US",
    },
    {
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698211446277-what-s-hospicash.jpg",
      title: "What's hospicash",
      category: "Hospicash",
      location: "San Diego, California - US",
    },
    {
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210575769-your-complete-health.jpg",
      title: "Your complete health insurance package",
      category: "Health Insurance",
      location: "San Diego, California - US",
    },
    {
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698222621734-insure-now--pay-late.jpg",
      title: "Insure Now, Pay Later",
      category: "Finance",
      location: "San Diego, California - US",
    },
  ];
  const phrases = ["Health", "Insurance"];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [phrase, setPhrase] = useState(phrases[currentIndex]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      const nextIndex = (currentIndex + 1) % phrases.length;
      setCurrentIndex(nextIndex);
      setIsVisible(true);
    }, 3000); // Delay in milliseconds before fading out and changing text

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: landing2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Newribbon = {
    loop: false,
    autoplay: true,
    animationData: ribbon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Confitee = {
    loop: false,
    autoplay: true,
    animationData: confitee,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const scrollToBottom = () => {
    window.scrollTo(0, 4000);
  };

  const marqueeStyle = {
    width: "100%",
    height: "75px",
    backgroundColor: "#FFF689",
    fontSize: "42px",
    fontFamily: "Nayuki",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };
  const marqueeStyle2 = {
    width: "100%",
    height: "75px",
    backgroundColor: "#C9EBFF",
    fontSize: "42px",
    fontFamily: "Nayuki",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const content = "✱ DOWNLOAD NOW ✱ DOWNLOAD NOW ";
  const content2 = "✱ HEALTH NOW ✱ SECURE NOW ✱ PAY LATER ";
  const content3 = "✦ DOWNLOAD NOW ";

  useEffect(() => {
    axios
      .get(`${ip}/arthshaala-blogs`)
      .then((res) => setBlogData(res.data))
      // .then(res => console.log(res.data))
      .catch((err) => console.log(err));
  }, []);

  const ref = useRef(null);

  const handleClick = () => {
    console.log(ref);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Fragment>
      {/* start:hero */}

      {/* <section className="pt-0 pb-20 relative h-[] sm:h-[800px]">
        <div className="h-[800px] sm:h-[600px] Homebanner">
          <img
            src={testImage}
            className="h-[800px] sm:h-[600px] w-full "
          />
        </div>
        <div className="absolute top-0 w-full py-20">
          <Container>
            <Row className="gap-y-6 py-4 lg:py-0 md:py-2 ">
              <Col className="md:order-2 my-auto pt-0" md={6}>
                <div className="heading-container relative ">
                  <h1
                    className="font-medium text__78 mb-4 md:text-left"
                    style={{
                      fontFamily: "Nayuki",
                      fontWeight: "300",
                    }}
                  >
                    {phrases.map((item, index) => (
                      <span
                        key={index}
                        className={`animation phrasetext text__60 ${
                          index === currentIndex
                            ? "active"
                            : "inactive"
                        }`}
                        style={{
                          fontFamily: "Outfit",
                          color:
                            index % 2 === 0
                              ? "#ff84c1ff"
                              : "#ff84c1ff",
                        }}
                      >
                        {item}
                      </span>
                    ))}
                    <br />
                    <span className="sabke-liye"> Sabke Liye. </span>
                  </h1>
                </div>
                <h4
                  className="text__18 md:leading-7 mb-10 md:text-left"
                  style={{ fontFamily: "Outfit" }}
                >
                  Your health, our priority. Access interest-free
                  credit <br /> for your health and insurance needs.
                  <br />
                  Secure your future today and pay at your
                  convenience.
                </h4>
                <div>
                  <button className="" onClick={handleClick}>
                    <div
                      className={
                        "md:!inline-block cursor-pointer bg-Mblue font-medium text__18 text-white btnClass hover:bg-Mpink hover:text-Mblue downloadbtn"
                      }
                    >
                      Download the App
                    </div>
                  </button>
                </div>
              </Col>
              <Col className="md:order-2 my-auto pt-0" md={6}>
                <h4 className="text-center font-semibold scanqr">
                  SCAN QR & GET EAZR
                </h4>
                <img
                  src="https://eazr.s3.ap-south-1.amazonaws.com/1726209902759-onelinkto-eazr.png"
                  alt=""
                  className="h-[300px] md:h-[255px] lg:h-[260px] w-full lg:w-[48%] mx-auto mt-3"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section> */}

      <Herosection />

      {/* // added hero component */}

      <section className="p-0">
        <div className="marquee-container1">
          <div className="healthnowmarquee">
            {content.repeat(500)}
          </div>
          {/* <div className="paylatermarquee">{content2.repeat(100)}</div> */}
        </div>
      </section>
      <section className="p-0">
        <div className="marquee-container2">
          {/* <div className="healthnowmarquee">{content.repeat(100)}</div> */}
          <div className="paylatermarquee">
            {content2.repeat(500)}
          </div>
        </div>
      </section>
      {/* <section className="p-0">
                <div>
                    <marquee width="100%" height="75" behavior="scroll" bgcolor="#FFF689" loop direction="left" scrollamount="12" className="healthnowmarquee" style={marqueeStyle} > {content.repeat(100)} </marquee>
                    <marquee width="100%" height="75" behavior="scroll" bgcolor="#C9EBFF" loop direction="right" scrollamount="12" className="paylatermarquee" style={marqueeStyle2} >{content2.repeat(100)} </marquee>

                </div>

            </section> */}
      {/* <div className="line z-50">
        <Lottie
          style={{ height: "150%" }}
          options={defaultOptions}
          // height={200}
          // width={370}
          className="lottieribbontop"
        />
      </div> */}
      {/* <div className="instantcredit absolute bottom-10"></div> */}

      {/* end:hero */}

      <section className="getinstantwrapper">
        <Container>
          <div className="text-center mb-4">
            <h2 className="font-medium">
              <span className="inline-block sm:block text__18 ">
                {" "}
                GET INSTANT CREDIT{" "}
              </span>
              <span
                style={{ fontFamily: "Nayuki" }}
                className="inline-block text__64 sm:text__24"
              >
                Health Se No Samjhota <br />
              </span>
              <span className=" inline-block sm:block text__18">
                {" "}
                Getting health coverage was never this eazr.
              </span>
            </h2>
          </div>

          <Row className="">
            <Col md={6} lg={3} className=" healthcontent">
              <div className="px-3 mt-0 healthsub">
                <div className="w-[50px] lg:w-[50px] h-[50px] lg:h-[50px] rounded-full flex items-center justify-center bg-MInstantHealth border border-solid mb-4">
                  <img
                    src="./../images/instanthealth.svg"
                    alt=""
                    className="p-2.5"
                  />
                </div>

                <h2 className="text__20 mb-2 font-semibold">
                  Instant Health Credit
                </h2>
                <p className="text__feature">
                  When health emergencies arise, we've got your back.
                  Instant credit for all your health & wellness needs.
                </p>
              </div>
              <div className="px-3 healthsub">
                <div className="w-[50px] lg:w-[50px] h-[50px] lg:h-[50px] rounded-full flex items-center justify-center bg-MPayLater border border-solid mb-4">
                  <img
                    src="./../images/healthnowpaylater.svg"
                    alt=""
                    className="p-2.5"
                  />
                </div>

                <h4 className="text__20 mb-2 font-semibold">
                  Health Now, Pay Later.
                </h4>
                <p className="text__feature">
                  Health insurance made effortless. Easy health
                  coverage at your fingertips @ ZERO Downpayment.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3} className="healthcontent1">
              <div className="px-3 mt-0 healthsub">
                <div className="w-[50px] lg:w-[50px] h-[50px] lg:h-[50px] rounded-full flex items-center justify-center bg-MNocost border border-solid mb-4">
                  <img
                    src="./../images/nocost.svg"
                    alt=""
                    className="p-2.5"
                  />
                </div>

                <h2 className="text__20 mb-2 font-semibold">
                  No Cost EMI
                </h2>
                <p className="text__feature">
                  We prioritize your health, at no added cost. Enjoy
                  the freedom of no cost credit and make your life
                  eazr.
                </p>
              </div>
              <div className="px-3 healthsub">
                <div className="w-[50px] lg:w-[50px] h-[50px] lg:h-[50px] rounded-full flex items-center justify-center bg-MOnetap border border-solid mb-4">
                  <img
                    src="./../images/onetap.svg"
                    alt=""
                    className="p-2.5"
                  />
                </div>

                <h4 className="text__20 mb-2 font-semibold">
                  One Tap Payments
                </h4>
                <p className="text__feature">
                  Health payment made effortless. Now experience the
                  ease of one tap payments with the eazr app.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-4 lg:mt-6 md:mt-8 sm:mt-10 lg:p-8 md:p-20 sm:p-2">
                <img
                  src="images/dogwalk.svg"
                  alt="mobile screen"
                  loop="false"
                  className="p-auto md:p-4 mobilescreen "
                />
              </div>
            </Col>
          </Row>
          {/* <Row >

<Col className="columndiv">
    <div className="ribbondiv">
        <Lottie
            options={Newribbon}
            height={350}
            width={1200}
            
        />
    </div>
</Col>
</Row> */}

          {/* <Row className="gap-y-6">
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/coins.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">Download the App</h4>
                                <p className="text__16">
                                    Download the Eazr app, complete your profile, and view your
                                    credit limit.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/hand-coins-currency.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">Unlock your Limit</h4>
                                <p className="text__16">
                                    Complete KYC, set up automatic repayments and you are ready to
                                    go.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/airline-ticket-hand.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">Shop Now</h4>
                                <p className="text__16">
                                    Use Eazr to pay at the partner brands whether Online or In
                                    Store.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/hand-coins-currency.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">Upto ₹10,000/-</h4>
                                <p className="text__16">
                                    Get instant credit upto ₹10,000/- every payment cycle.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/coins.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">One Tap Pay</h4>
                                <p className="text__16">
                                    Get your shopping even eazr with out one-tap payment &
                                    checkout.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/airline-ticket-hand.svg" alt="" />
                                </div>

                                <h4 className="font-medium text__24 mb-2">15 days Credit</h4>
                                <p className="text__16">
                                    Pay your due balance every 15 days without any fees or
                                    interest.
                                </p>
                            </div>
                        </Col>
                    </Row> */}
        </Container>
      </section>

      <section className="pt-0 pb-4">
        <Container>
          <Row className="gap-y-6">
            <Col
              className="pt-2 md:order-1 my-auto md:pt-2 lg:pt-4"
              md={6}
            >
              <img
                src="./../images/health.svg"
                alt=""
                className="lg:h-[540px] md:h-[325px] sm:h-[300px] w-full lg:w-[90%] mx-auto mt-4 p-2"
              />
            </Col>

            <Col className="order-2 md:my-auto" md={6}>
              <h3 className="text__48 font-semibold mb-2">
                Eazr Health In 1-2-3-4!
              </h3>
              <p className="text__18 text-Mgray mb-12">
                Joining the Eazr fam? Here's your quick Dive-in!
              </p>
              <Row>
                <div
                  className="pb-0 md:pb-5 flex justify-center"
                  style={{ flexWrap: "wrap" }}
                >
                  <div className=" pb-5 mt-0 innereazrFeature">
                    <h2 className="text__20 mb-2 font-semibold">
                      1. Download & Kick Start
                    </h2>
                    <p className="text__14">
                      Let's Start Fresh : Kick things off by
                      downloading the Eazr app. Welcome to the Eazr
                      fam!
                    </p>
                  </div>
                  <div className="px-0 pb-5 mt-0 md:px-3 innereazrFeature">
                    <h2 className="text__20 mb-2 font-semibold">
                      2. Unlock Your Potential
                    </h2>
                    <p className="text__14">
                      Flex Your Financial Muscle: Quick profile check,
                      unlock your credit. Ready, set, spend!
                    </p>
                  </div>
                  <div className="pb-5 innereazrFeature">
                    <h4 className="text__20 mb-2 font-semibold">
                      3. Wellness @ ZERO Upfront
                    </h4>
                    <p className="text__14">
                      Wellness, Uninterrupted: Shop health & wellness
                      products without the upfront cost. Stress-free
                      vibes!
                    </p>
                  </div>

                  <div className="pb-0 px-0 md:px-3 pb-5 innereazrFeature">
                    <h4 className="text__20 mb-2 font-semibold">
                      4. Repay Your Way
                    </h4>
                    <p className="text__14">
                      Smooth & Simple: Choose your repayment style -
                      be it one-off or EMIs. We're all about options!
                    </p>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="somedoubt">
        <Container>
          <Row className="gap-y-6">
            <Col className=" md:order-2 my-auto pt-20" md={6}>
              <img
                src="./../images/SomeDoubtNew.png"
                alt=""
                className="lg:h-[450px] md:h-[325px] sm:h-[300px] w-full lg:w-[75%] mx-auto mt-5"
              />
            </Col>
            <Col className="order-1 md:my-auto" md={6}>
              <h3 className="text__48 font-semibold mb-2">
                Straight Talk, No Jargon!
              </h3>
              <p className="text__18 text-Mgray mb-10">
                Making sense of the cool stuff we do.
              </p>

              <div className="flex flex-wrap gap-3">
                {dataUsedFeatures.map((obj, i) => {
                  return (
                    <div
                      className={
                        "p-4 rounded-2xl w-full cursor-pointer " +
                        (ToogleAccordion == i
                          ? "bg-[#FAFAFA]"
                          : "border border-solid border-[#F5F5F5]")
                      }
                      onClick={() => setToogleAccordionWrap(i)}
                    >
                      <div className="flex items-center justify-between">
                        <h5 className="font-medium text__20">
                          {obj.title}
                        </h5>
                        <img
                          src="./../images/majesticons_chevron-down-line.svg"
                          className={
                            "transition-all duration-500 " +
                            (ToogleAccordion == i ? "rotate-180" : "")
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className={
                          "py-2 " +
                          (ToogleAccordion == i ? "block" : "hidden")
                        }
                      >
                        <p className="text__16">{obj.desc}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-Marthshala rounded-3xl arthshalawrapper">
        <Container>
          <div className="mb-3 arthshaala">
            <div>
              <img
                src="images/arthshala.png"
                alt="arthshala"
                className="artshalaimage"
              />
            </div>
            {/* <h3 className="text__48 font-medium mb-2" style={{fontFamily:"Nayuki"}}>Blogs</h3> */}

            <p className="text__18 text-Mgray font-semibold mb-4">
              <NavLink to="/BlogPage"> Explore More 📒</NavLink>
            </p>
          </div>
          <div>
            <p className="text__18 text-Mgray mb-14">
              Welcome to Eazr Arthshaala. Making the money matters
              simple, engaging & fun.
            </p>
          </div>

          <Row
            className="mb-6 gap-y-6 flex-nowrap overflow-x-auto scrollblog"
            style={{ zIndex: "100" }}
          >
            {dataEvents.map((obj) => {
              return (
                <Col sm={5} md={8} lg={5}>
                  <CardEvents data={obj} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <section>
        <div className="marquee-container3">
          {/* <div className="healthnowmarquee">{content.repeat(100)}</div> */}
          <div className="downloadmarquee">
            {content3.repeat(100)}
          </div>
        </div>
      </section>
      {/* <section>
                <div>
                    <marquee width="100%" height="100" behavior="scroll" bgcolor="" loop direction="left" scrollamount="12" className="downloadmarquee"> {content3.repeat(100)} </marquee>
                </div>

            </section> */}

      {/* // removed component  */}
      <section className="pt-0 pb-10" ref={ref}>
        <Container>
          <div className="text-center mb-3 ">
            <h2 className="font-medium">
              <span className="inline-block sm:block text__18 ">
                {" "}
                GET INSTANT CREDIT{" "}
              </span>
              <span
                style={{ fontFamily: "Nayuki" }}
                className="text__64 inline-block"
              >
                GET THE EAZR APP
              </span>
              <span className=" inline-block sm:block text__18">
                {" "}
                Your passport to stress free Health Financing
              </span>
            </h2>
          </div>

          <Row className="gap-y-6 py-4 lg:py-0 md:py-2">
            <Col className="md:order-2 my-auto pt-0" md={6}>
              <img
                src="./../images/mobilescreens.gif"
                alt=""
                className="lg:h-[520px] md:h-[325px] sm:h-[300px] w-full lg:w-[90%] mx-auto mt-0"
              />
            </Col>
            <Col className="md:order-2 my-auto pt-0" md={6}>
              <h4 className="text-center font-semibold scanqr">
                SCAN QR & GET EAZR
              </h4>
              <img
                src="https://eazr.s3.ap-south-1.amazonaws.com/1726209902759-onelinkto-eazr.png"
                alt=""
                className="lg:h-[260px] md:h-[355px] sm:h-[320px] w-full lg:w-[48%] mx-auto mt-3"
              />
            </Col>
          </Row>
          {/* <Row > */}

          <Row className="pt-7 md:pt-0 pb-24">
            <Col lg={3}>
              <div className="flex justify-center">
                <img
                  src="/images/featured/spotlight.svg"
                  alt="featured"
                  className="spotlight"
                />
              </div>
            </Col>
            <Col lg={9}>
              <Row>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/econ.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/business.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/hindustan.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/midday.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/republic.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className="text-center pt-4">
                    <img
                      src="/images/featured/zee.png"
                      alt="featured"
                      className="h-[100px] sm:h-auto m-auto"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section>
    <div style={{ display: "flex", justifyContent: "center", height: "auto" }}>
                        <div style={{}}>
                            <img src="images/Eazr-QR.svg" alt="qrcode" height="300" width="300" />
                        </div>
                    </div>
    </section> */}

      {/* <SecFaq /> */}
      {/* <CarouselComp/> */}
      {/* <SecCreate /> */}
    </Fragment>
  );
};

export default Homepage;
