import React from 'react'
import { Fragment } from 'react'
import { RightArrowIcon } from '../Components/Icon/Icon'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useState } from 'react'
import { PaymentStepModal } from '../Components/Modal/Modal'

const Payment = () => {
    const [ToogleCheck, setToogleCheck] = useState("")
    const roundChekck = (e) => {
        return <div onClick={() => setToogleCheck(e)} className={"w-[24px] h-[24px] rounded-full border border-solid flex items-center justify-center cursor-pointer " + (ToogleCheck == e ? "!border-Mpurple bg-Mpurple" : "border-[#A3A3A3]")}>
            {
                ToogleCheck == e ? <div className="bg-Mpurple w-full h-full rounded-full border-[3px] border-solid border-white"></div> : ""
            }
        </div>
    }


    const [StatusModal, setStatusModal] = useState("success");
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => {
        setShow(true);
    };

    return (
        <Fragment>
            <PaymentStepModal show={show} handleClose={() => handleClose()} handleShow={() => handleShow()} StatusModal={StatusModal} setStatusModal={(e) => setStatusModal(e)} />
            <section className='bg-Mbg'>
                <Container>
                    <div className="w-full p-3 sm:!p-6 bg-white rounded-[24px]">
                        <Row>
                            <Col md={7} lg={8}>
                                <div className="flex items-center gap-1 sm:gap-2 text__16">
                                    <span className='text-[#A3A3A3]'>Ticket</span>
                                    <RightArrowIcon width={24} height={24} color='#A3A3A3' />
                                    <span className='text-[#A3A3A3]'>Detail Order</span>
                                    <RightArrowIcon width={24} height={24} color='#A3A3A3' />
                                    <span className='text-black' >Payment Method</span>
                                </div>

                                <h4 className='font-medium text__24 my-4'>Payment Method</h4>

                                <div className="mb-4">
                                    <h4 className='font-medium text__16 mb-3'>Online Payment</h4>
                                    <Row>
                                        <Col md={6} className='mb-3 md:mb-0'>
                                            <div className={"flex justify-between items-center px-3 py-3 border border-solid rounded-[20px] cursor-pointer " + (ToogleCheck == "paypal" ? "!border-Mpurple" : "border-[#E5E5E5]")} onClick={() => setToogleCheck("paypal")}>
                                                <div className="flex items-center gap-3">
                                                    <img src={window.location.origin + "/images/paypal.png"} alt="" />

                                                    <h4 className='font-medium text-[12px] lg:text-[14px]'>Paypal</h4>

                                                </div>
                                                {roundChekck("paypal")}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className={"flex justify-between items-center px-3 py-3 border border-solid rounded-[20px] cursor-pointer " + (ToogleCheck == "Apple Pay" ? "!border-Mpurple" : "border-[#E5E5E5]")} onClick={() => setToogleCheck("Apple Pay")}>
                                                <div className="flex items-center gap-3">
                                                    <img src={window.location.origin + "/images/applepay.png"} alt="" />

                                                    <h4 className='font-medium text-[12px] lg:text-[14px]'>Apple Pay</h4>

                                                </div>
                                                {roundChekck("Apple Pay")}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>


                                <div>
                                    <h4 className='font-medium text__16 mb-3'>Credit Card</h4>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className="text__14 text-[#A3A3A3]">Cardholder Name</Form.Label>
                                        <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                            <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="Ex:  San Andreas" />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className="text__14 text-[#A3A3A3]">Card Number</Form.Label>
                                        <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                            <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="Ex:  31111 1111 1111 1111" />
                                        </div>
                                    </Form.Group>

                                    <Row>
                                        <Col className='col-4 mb-4'>
                                            <Form.Group>
                                                <Form.Label className="text__14 text-[#A3A3A3]">Postal Code</Form.Label>
                                                <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                                    <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="123456" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-4 mb-4'>
                                            <Form.Group>
                                                <Form.Label className="text__14 text-[#A3A3A3]">Expiration</Form.Label>
                                                <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                                    <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="MM/YY" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-4 mb-4'>
                                            <Form.Group>
                                                <Form.Label className="text__14 text-[#A3A3A3]">CCV</Form.Label>
                                                <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                                    <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="123" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                            <Col md={5} lg={4}>
                                <div className="w-full p-4 border border-solid border-[#F5F5F5] rounded-[20px]">
                                    <h4 className='font-medium text__20 mb-2'>September Perform 2023 (Standing - Early Bird)</h4>
                                    <p className='text__14 text-Mgray mb-4'>Wed 24 Sep 2023 - Tue 25 Sep 2023</p>

                                    <div className="flex flex-wrap gap-3 mb-6">
                                        <div className="w-full flex items-center justify-between">
                                            <p className='text__16 text-Mgray'>Total Price</p>
                                            <h5 className='font-semibold text__18'>$20</h5>
                                        </div>
                                        <div className="w-full flex items-center justify-between">
                                            <p className='text__16 text-Mgray'>Tax Platform</p>
                                            <h5 className='font-semibold text__18'>-</h5>
                                        </div>
                                        <hr className='!border-[#E5E5E5] my-0 w-full' />
                                        <div className="w-full flex items-center justify-between">
                                            <p className='text__16 text-Mgray'>Order Total</p>
                                            <h5 className='font-semibold text__18'>$20</h5>
                                        </div>
                                    </div>

                                    <div onClick={handleShow} className='cursor-pointer font-medium text__16 inline-block cursor-pointer font-medium text__14 text-white btnClass !py-[16px] bg-Mpurple w-full text-center'>Continue to Payment</div>

                                    <h5 className='text__18 mb-2 mt-4'>Share this event:</h5>
                                    <div className="flex items-center gap-2">
                                        <a href="#!">
                                            <img src={window.location.origin + "/images/sos (1).svg"} alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src={window.location.origin + "/images/sos (2).svg"} alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src={window.location.origin + "/images/sos (3).svg"} alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src={window.location.origin + "/images/sos (4).svg"} alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src={window.location.origin + "/images/sos (5).svg"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default Payment
