import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';

const Privacy = () => {
    return (
        <Fragment>
            <section className='bg-Mbg py-16'>
                <Container>
                    <div className="text-center mb-2 relative">
                        <h3 className='text__48 font-medium mb-2 relative z-2'>Privacy Policy</h3>

                        <img src="./../images/ballon.svg" className='absolute w-[3rem] lg:w-auto -top-[5rem] lg:-top-[3rem] left-[90%] md:left-[80%] lg:left-[73%] -translate-x-1/2' alt="" />
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className='justify-center'>
                        <Col md={10}>
                            <h5 className='font-bold text__18 mb-2'>1. WHO IS EAZR AND HOW DOES EAZR PRIVACY STATEMENT WORK?  </h5>
                            <p className='text__16'>  EAZR Digipayments Private limited (“EAZR” “we”, “our”, “us”) is a company incorporated in India bearing CIN No. U72900MH2020PTC343134, having its registered in Mumbai, India and corporate office at corporate office at 302, Tower 2B South Annex, One World Center Lower Parel West Mumbai 400013. EAZR is engaged in the business of providing technology products and platforms to various businesses and merchants through its Websites, Apps and Platforms (defined below), in addition to certain data processing services to its customers and other businesses. EAZR provides its products and services to you by partnering with lending partners (“Lending Partners”)
                                who are registered with the Reserve Bank of India (“RBI”) to provide financial products and services to you. <br /> <br />
                                The purpose of this privacy statement (“EAZR Privacy Statement”) is to give you information on how EAZR collects, stores, uses, discloses, transfers and processes your Personal Information including Sensitive Personal Information (defined below) when: (a) you use our websites including www.Eazr.app (“Websites”), software applications or mobile applications including the EAZR application and licensed to EAZR (“Apps”) or platform(s) of any of our partners (“Platforms”), or (b) you avail any products or services which we may operate or offer to you either directly through our Websites or Apps or Platforms or through a merchant or partner platform; including the steps we take to protect your Personal Information. The term Websites includes all pages that are sub-domains or are associated with or exist within each Website which are hosted by EAZR. By accessing the Websites, Apps, Platforms and/or using products or services provided by EAZR directly or indirectly, you agree that you have read and understood, and agree to be bound by this EAZR Privacy Statement.<br /> <br />
                                While accessing the Websites, Apps, Platforms, and/or using products or services provided by EAZR directly or indirectly, in the past, present or future, you maybe required to provide your specific and prior consent for us to collect, use, store, disclose or otherwise process of your Personal Information (including Sensitive Personal Information). You further acknowledge and accept that your specific consent provided on our Website, Apps, Platforms and / or during usage of products or services provided by us shall also be applicable to your Personal Information and Sensitive Personal Information submitted to us, directly or indirectly, in the past. <br /> <br />
                                It is strongly recommended for you to return to this page periodically to review the most current version of this EAZR Privacy Statement which is amended by us from time to time. <br /> <br />
                            </p>

                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>2. WHAT IS PERSONAL INFORMATION AND WHAT TYPES OF PERSONAL INFORMATION DO WE COLLECT ABOUT YOU?</h5>
                                <p className='text__16'>“Personal information” means any information that relates to an individual, which directly or indirectly, in combination with other information available or likely to be available with us, is capable of identifying such individual. <br />
                                    “Sensitive Personal Information” means any Personal Information of an individual, which consists of information relating to financial information such as bank account, credit card, debit card or other payment instrument details; passwords; biometric information; sexual orientation; physical, physiological and mental health condition; and medical records and history. Information freely available or accessible in public domain is not treated as Sensitive Personal Information. Depending on who you are (e.g., a customer, cardholder, consumer, supplier or business partner) and how you interact with us (e.g., through Website, Platform, App, telephone, IVR, API, online or offline), we may collect, use, receive, store, analyze, combine, transfer or otherwise process different categories of your Personal Information (including Sensitive Personal Information). Below is a table of the categories of Personal Information and Sensitive Personal Information which we may collect about you: <br />
                                    “Phone Data” With your permission, we collect mobile phone resources like file and media, call logs, telephony functions (such as SMS data as further defined below), camera, microphone, location data (as further defined below), or any other facility in your electronic device(s) (including your mobile phone(s)) for the purposes of on-boarding / KYC requirements in connection with your loan or credit facility application after seeking necessary permissions.
                                    We may also facilitate access to Phone Data to our Lending Partners, from your mobile device for the purposes of onboarding, including for making credit risk assessments and related activities in connection with your application for the loan or credit facility that you apply for. <br /><br />

                                </p>


                                <Table striped bordered hover>

                                    <tr>
                                        <td className="col-4">Categories of Personal <br /> Information</td>
                                        <td >Which includes information such as:</td>

                                    </tr>
                                    <tr>
                                        <td>Identity and Account Log- In <br />Information</td>
                                        <td>Full name(s), title, age, user ID, gender, and your date of birth.</td>


                                    </tr>
                                    <tr>
                                        <td>Contact Information</td>
                                        <td>Telephone/mobile number(s), permanent and current address, state/city of residence, country, pin code, email address, and chosen billing address.</td>

                                    </tr>
                                    <tr>
                                        <td>Financial Information</td>
                                        <td>Personal bank account numbers and bank account data including e-nach, NEFT, IMPS and UPI ID details, credit or debit card information, including the credit or debit card number, card type, expiry date on the credit or debit card, and name on the card, salary and income details.</td>

                                    </tr>  <tr>
                                        <td>Identification Information</td>
                                        <td>Know Your Customer (KYC) documents including all proofs of identity and address, photograph, Permanent Account Number (PAN), GST Number, etc.</td>

                                    </tr>  <tr>
                                        <td>“Payment Information” (transactional information)</td>
                                        <td>Payment gateway ID, name of issuing bank, merchant’s name, category and other identifiers along with the details of products or services purchased or paid for, mode of payment, the date, currency and amount of the transaction, whether the payment was successful, and other information provided by you directly or indirectly to us or provided to us by banks, merchants or other service providers or business partners.</td>

                                    </tr>  <tr>
                                        <td>Usage and Technical Information</td>
                                        <td>IP addresses, application type, device or browser type, versions and configurations, operating systems, device brand and model, time zone setting, geo-location information, content, unique identifiers associated with your device(s) and pages that you access on the Website(s), Apps or Platforms, and the dates and times that you visit the Website, App or Platform, and paths taken.</td>

                                    </tr>
                                    <tr>
                                        <td>Credit and Lending Information</td>
                                        <td>Credit or affluence related information or assessment, Credit information (and income information (e.g., employment contract, salary details, employer name, income tax returns, bank account statements, income statements, balance sheet, cashflow statement), credit or affluence score or reliability scores provided by third parties such as financial institutions or account aggregators or similar third parties as permitted under applicable laws of India including our business partners, independent service providers and our group entities.</td>

                                    </tr>           <tr>
                                        <td>Marketing and Communications Information</td>
                                        <td>Communications with customer service support, behavioural data (for example, data collected using cookies), information about promotions, surveys, promotional campaigns and records of your decision(s) to subscribe or to withdraw from receiving marketing materials, if any.</td>

                                    </tr>           <tr>
                                        <td>SMS Log</td>
                                        <td>With your permission, we will collect and transmit your SMS log data to our internal server including (but not limited to) bank transactions, Employee Provident Fund, credit, debit etc. This allows us to assess you for credit risk and limits, assist in our collections process, personalize promotional content and prevent fraudulent actors from acting on your behalf.</td>

                                    </tr>
                                    <tr>
                                        <td>Location data</td>
                                        <td>With your permission, we may receive, collect and analyse your location data which may be accessed through a variety of methods including (but not limited to) GPS, IP address, cell tower location and your precise location based on GPS data, so as to provide you with hyper-local information and application capabilities, to prevent fraud, verify your address and place of acceptance of documents, etc.  Please note that, in the event of IP detection failure, we will collect the last cached location as your current location.</td>

                                    </tr>

                                </Table>
                                <p className='text__16'>We collect such Phone Data as a one-time activity and limit the collection to the time duration for which you complete your loan application on our Websites, Apps and Platform. <br /> <br />
                                    Further, while Lending Partners may collect Phone Data from you, EAZR does not collect, have access to or store Phone Data from you for any purposes. Any storage of the Phone Data will be done by the Lending Partners only. EAZR also does not collect, have access to or store any Phone Data for purposes other than those mentioned above. We are committed to ensure that we store only store minimal data that will be required for us to enable the products and services through our Partner Lender’s Websites, Apps and Platforms. <br /> <br />
                                    We may also collect, use, process and/or share non-Personal Information or anonymized and / or aggregated data such as statistical or demographic data in accordance with this EAZR Privacy Statement. As a matter of principle, we do not collect certain special categories of Personal Information about you (such as details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health or any genetic and biometric information). However, we may collect certain Sensitive Personal Information in order to provide our services or products to you, such as some categories of Financial Information including bank account number, name and number on the credit or debit card and your Credit and Lending Information as described above. In such cases, we ensure that such Sensitive Personal Information is used, processed, stored and/or disclosed in accordance with this EAZR Privacy Statement and in compliance with applicable laws of India. To the extent the Websites, Apps or Platforms include links to third-party websites, plug-ins and applications (including cookies, tracking technologies and widgets by third party advertisers), it is important that you understand that by clicking on those links or enabling those connections, you may allow third parties to collect or share data about you. EAZR does not have oversight of these third-party websites, plug-ins and applications and we are not responsible for their processing or using of your Personal Information or Sensitive Personal Information. Any information collected by a third party in this manner is subject to that third party’s own data collection, use and disclosure policies and you must make yourselves conversant with those.
                                </p>
                            </div>

                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>3. HOW DO WE COLLECT YOUR PERSONAL INFORMATION?</h5>
                                <p className='text__16'>How we collect Personal Information (including Sensitive Personal Information) will depend on the following broader situations: <br /> <br />
                                </p>
                                <h5 className='font-bold text__16 mb-3'>3.1. If we receive or collect it directly from you</h5>
                                <p className='text__16'>We collect Personal Information (including Sensitive Personal Information) directly from you in different ways on the Websites, Apps, Platforms or product or service offerings. For example, you give us your Personal Information (including Sensitive Personal Information) when you:<br /> <br />
                                </p>

                                <p className='text__16 mb-2'> 1. give us your Contact Information so that we can contact you about our services and products <br />
                                    2.	conclude a contract or verify to us who you are (whether by email, phone or electronic verification);<br />
                                    3.	provide your Personal Information (including Sensitive Personal Information) on the Apps, Websites, Platforms or the checkout pages in order to register for availing our services or for completing a transaction(s);<br />
                                    4.	apply for our products or services directly with us or through our business partners (such as various third party websites, resellers or credit providers);<br />
                                    5.	directly register with us for availing any products or services including initiating an account-based relationship with us;<br />
                                    6.	are prompted to give us your Identity and Account Log- In Information, Contact Information, Financial Information, Identification Information, Credit Related Information and/or Payment Information on our App, Website or web-checkout /payment pages or via other similar channels to register or complete a transaction;<br />
                                    7.	give us your Credit and Lending Information to avail any loans or services directly from us, or from our business partners and third party service providers;<br />
                                    8.	enter a competition, promotion or survey or you consent to be provided with marketing materials to be sent to you;<br />
                                    9.	send us a support request for any of our products or services through one or more of our support desks; or<br />
                                    10.	access, use and/or browse our Website(s), Apps or Platforms;<br />
                                    11.	give us permission for a one-time access to the Phone Data, including your location, etc., and other sources available through various means including cookies. <br /> <br />
                                </p>
                                <h5 className='font-bold text__16 mb-2'>3.2. If we collect it from third parties, or from publicly available sources</h5>
                                <p className='text__16'>We obtain Personal Information(including Sensitive Personal Information) through third parties including our business partners, independent service providers and our group entities, third parties or from publicly available sources, if publicly available, as permitted under applicable laws of India, including:<br /> <br />
                                </p>
                                <p className='text__16'>
                                    1.	from various merchants, marketplaces, re-sellers, banks, payment partners, payment aggregators, payments systems, payment service providers and financial institutions. For example, a merchant or a financial institution can share your Personal Information with us for the purpose of doing an eligibility check for our services. We encourage you to read such third parties’ privacy statements to learn more on how they process your personal information. <br />
                                    2.	from group entities or independent third parties for other activities. For example, we may receive information as may be required for our internal analysis, credit checks etc. from Lending Partners may also receive your information from various third parties in the process of providing services to such third parties;<br />
                                    3.	Phone Data may be provided by Lending Partners for operational reasons including reaching out to you for referrals, etc.<br />
                                    4.	social platforms and networks when you give us permission to do so either directly or through a cookie. For example, depending on your social media settings, if you choose or consent to connect your social media account to a EAZR product or do not disable cookies while accessing the Website(s), Platform or an App, certain information from your social media account may be shared with us (which may include information that is part of your profile);<br />
                                    5.	from financial institutions, and fraud prevention agencies for the purposes of conducting fraud and risk assessments or analysis. For example, before we provide or enable the provision of any services, goods or finances to you (as applicable), we may undertake checks for the purposes of preventing fraud and money laundering, and to verify your identity. These checks require us to collect information about you from our business partners and independent service providers including technology providers who have entered into contracts with us;<br />
                                    6.	from credit information companies, such as TransUnion CIBIL Limited to receive your credit information and to share the same with Lending Partners and/or their respective service providers; h. from third party service providers or technology providers, including service providers engaged in the business of collecting data from publicly available sources, who have entered into contracts with us to assist us with our business operations;<br />
                                    7.	from third parties such as merchants or e-commerce platforms who have either entered into a contract with you or have obtained your consent or through any other legal means as may be available in accordance with applicable laws of India, to share your information with other parties;<br />
                                    8.	from publicly available sources in accordance with applicable laws of India;

                                </p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>4. WHAT ARE THE LAWFUL GROUNDS THAT WE RELY ON TO PROCESS YOUR SENSITIVE PERSONAL INFORMATION?</h5>
                                <p className='text__16'>We process Sensitive Personal Information only when we have obtained your consent in manner provided hereinunder. For collecting, processing (including using and storing), disclosing or sharing your Sensitive Personal Information as described in this EAZR Privacy Statement, we hereby through this EAZR Privacy Statement, seek your consent and give you a notice regarding collecting, processing (including using and storing), and sharing your Sensitive Personal Information for one or more of the purposes detailed herein. For any purposes, where, as per applicable laws of India, a specific consent is required, we will seek such specific consent from you from time to time. In relation to information obtained from third parties or group entities, we rely on the consent taken by these parties on their respective platforms in our favour for us to process the information. You may, of course, decline to share certain information with us, in which case we may not be able to provide to you some or all of the features and functionalities of the Websites, Apps and the Platform. This may also affect our ability to process your Personal Information (including Sensitive Personal Information) and may
                                    therefore lead to the non-availability or discontinuation of the services for which such Personal Information (including Sensitive Personal Information) are to be used or was being used, at our sole discretion.</p>

                            </div>
                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>5. THE PURPOSES FOR WHICH WE PROCESS YOUR PERSONAL INFORMATION</h5>
                                <p className='text__16'>We process your Personal Information (including Sensitive Personal Information) for the purposes mentioned below: <br /> <br />  </p>
                                <h5 className='font-bold text__16 mb-2'>5.1. To verify, authenticate and authorize your use of our products or services</h5>
                                <p className='text__16'>We process your Personal Information (including Sensitive Personal Information) for creating login credentials or establishing an account based relationship, doing KYC evaluation and risk assessments in order to authenticate and authorize your use of our products or services depending on if you are a merchant, consumer, borrower, service provider or business partner, and your choice of services or products. The type of
                                    Personal Information (including Sensitive Personal Information) typically required is Identification Information, Identity, and Account Log-In Information, Contact Information and Financial Information. This is necessary for EAZR and our Lending Partners to assess your application and is also necessary to fulfil our legal obligations
                                    under certain applicable laws of India. <br /> <br /></p>
                                <h5 className='font-bold text__16 mb-2'>5.2. To process the transactions</h5>
                                <p className='text__16'> The types of Personal Information (including Sensitive Personal Information) we require to provide a product or service depends on the specific product made available by EAZR to you, which may also include any additional information required by EAZR or Partner Lender or the merchant or the third party service provider, including financial institutions. <br />
                                    Our Lending Partners offers multiple types of credit products, which are subject to product specific service terms (contracts) and legal obligations. In certain cases, credit and re-payment transactions require you to provide Personal Information (including Sensitive Personal Information) directly to us onto the Websites, Apps and/or Platforms in order for us to process a transaction, for example when you avail a credit facility and make a drawdown on that credit facility for the purposes of either receiving credit or paying for a product or service using such credit facility. <br /> <br />
                                </p>
                                <h5 className='font-bold text__16 mb-2'>5.3. To provide products and services to you</h5>
                                <p className='text__16'> We also process your Personal Information (including Sensitive Personal Information) in order to provide you products or services being offered by us, our group companies or our business partners. We may also process your Personal Information (including Sensitive Personal Information) for improving and expediting your journey on the Platforms, Websites, Apps, merchant platforms, etc. If any entities other than EAZR offer any products such as prepaid cards or any other services on the Platforms, Websites, Apps or merchant platforms, we process your Personal Information (including Sensitive Personal Information). <br /> <br />
                                </p>
                                <h5 className='font-bold text__16 mb-2'>5.4. To do internal risk assessments and analysis</h5>
                                <p className='text__16'>We may process your Personal Information (including Sensitive Personal Information) for the purposes of risk assessments and evaluations including or KYC checks, to improve our services, reduce fraud rates, build risk models, etc. <br /><br />
                                </p>
                                <h5 className='font-bold text__16 mb-2'>5.5. To protect our business and to ensure compliance with the law</h5>
                                <p className='text__16'>We process your Personal Information (including Sensitive Personal Information) to meet the requirements of applicable laws, regulations, standards, rules, codes and the requirements as may be applicable on non-banking financial institutions and other codes with which EAZR must comply. This includes: <br /> <br />
                                    1.	authenticating and validating your access to your account or to mitigate and protect against identity theft or fraud. To do this, some of your Personal Information (including Sensitive Personal Information) and non-Personal Information may be collected by EAZR or these may be directly or indirectly delivered to EAZR by merchants, group companies or other business partners of EAZR. EAZR will use this Personal Information (including Sensitive Personal Information) for the purpose of anti-fraud systems and this may be retained by us for our future use for reference and cross-reference of information;<br />
                                    2.	consulting and reporting your Personal Information (including Sensitive Personal Information) and behaviour on monetary obligations to legitimately constituted financial, commercial or service risk centres, or to other financial institutions, under applicable laws of India or in furtherance to contracts;<br />
                                    3.	verifying your identity and comparing your Personal Information (including Sensitive Personal Information) to verify accuracy for reporting obligations under applicable laws or payment scheme rules or KYC norms;<br />
                                    4.	processing your information for the purpose of ensuring compliance under applicable laws and for resolving or contesting any claims made by or against you against EAZR, our group companies, our business partners or any other third party;<br />
                                    5.	processing or using your Personal Information (including Sensitive Personal Information) if you exert your right of refusal for any of our services or any services provided by Eazr, our group companies, our business partners or any other third party; and<br />
                                    6.	processing (including storing or using) your Personal Information (including Sensitive Personal Information) to ensure business continuity of our businesses and appropriate disaster recovery for the Websites, Apps, Platforms, services and products.<br /><br />
                                </p>
                                <h5 className='font-bold text__18 mb-2'>5.6. To manage our relationship with you</h5>
                                <p className='text__16'>If you contact us or otherwise give us your Contact Information (for example by registering, by completing an enquiry form on the Website/s, Apps and / or Platforms, or by subscribing to receipt of support and service status communications from us regarding security or fraud monitoring alerts), we process your Personal Information (including Sensitive Personal Information):<br /> <br />
                                    1.	to inform you about our products or services and any changes to these products or services and any associated legal documents;<br />
                                    2.	to notify you if there is any interruption of services or products;<br />
                                    3.	to ask you to provide information on how we can improve or develop services or products and to otherwise effectively communicate with you;<br />
                                    4.	to provide you with service assistance and problem solutions or to contact or send you notifications related to the services or products we offer to you;<br />
                                    5.	to use your Personal Information (including Sensitive Personal Information) in transactional or fraud monitoring reports (or both) as part of the performance of our contracts with, our group companies, our business partners or any other third party and to comply with any regulatory obligations. You have the option to unsubscribe from such reports in accordance with the terms of our contract.<br /> <br />
                                </p>

                            </div>

                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>5.7. To market our products and services including products and services of our business partners</h5>
                                <p className='text__16'>We use your Personal Information to market our products and services including products and services of our business partners, independent service providers and /or our group entities and to notify you about events, offers, sponsorships, marketing programmes and similar marketing campaigns. We may also process and analyse your Personal Information (including Sensitive Personal Information) to understand your usage trends and preferences, and to develop and market (either ourselves or through our group companies, our business partners or any other third parties) new products, services, features and functions to you based on such analysis of your Personal Information (including Sensitive Personal Information).</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-bold text__18 mb-2'>5.8.To conduct research and to develop and improve our products and services</h5>
                                <p className='text__16'>We use Personal Information (including Sensitive Personal Information) that we collect:
                                    1.	to research and gain insights into market trends and needs and to develop or innovate our technologies, products and services to meet such market trends and needs. We may use machine learning and artificial intelligence techniques to conduct research to gain such insights;
                                    2.	to analyze visitor use of the Websites, Apps, Platform, products or services in accordance with this EAZR Privacy Statement;
                                    3.	to improve and personalize our merchant and customer relationships; and
                                    4.	to provide merchants with statistical insights and reports based on your information we receive from them. </p>
                            </div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18 mb-2'> 6. WHEN AND TO WHOM DO WE DISCLOSE YOUR PERSONAL INFORMATION?</h5>
                                <p className='text__16'>  1.	6.1. We may share your Personal Information (including Sensitive Personal Information) with Lending Partners, and other entities from the group of companies to which EAZR belongs, We may disclose your Personal Information (including Sensitive Personal Information) to these companies, to: <br /> <br />
                                    1.	provide support services and technical services to EAZR and group companies of EAZR (including Lending Partners) and to receive some of these services from them <br />
                                    2.	provide support services and technical services to EAZR and group companies of EAZR (including Lending Partners) and to receive some of these services from them. <br />
                                    3.	to offer their products and services to you including offering combined products and services. </p> <br /><br />
                                2.	6.2. We may share your Personal Information (including Sensitive Personal Information) with Lending Partners. We also share Phone Data with Lending Partners for the purposes of assessing your eligibility for the loan or credit facility applied for. Further, Lending Partners will also have access to your Phone Data for the purposes of making credit risk assessments and related activities in connection with your application for the loan or credit facility that you have applied for. We encourage you to read the Partners Lenders’ privacy policies / statements to learn more on how they process your personal information.<br /><br />
                                3.	6.3. Lending Partners may also share with, or disclose, subject to applicable laws, your credit information, and/or any other information(s)/document(s) received by such Partner Lender from credit information companies, and KYC details or documents held with or received by the Partner Lender with its affiliates and/or group companies and/or EAZR and any agents or service providers of EAZR including its affiliates and/or group companies and/or with any regulatory or government authority, for the purposes of, as applicable: <br /> (i) evaluating any applications made by you to the Partner Financial Institutions Lender and/or EAZR for any credit facilities,<br />
                                (ii) conducting a credit assessment on you in relation to such credit facilities, <br /> (iii) your onboarding in relation to such credit facilities, <br />(iv) conducting your due diligence in relation to such credit facilities; and (v) monitoring your credit worthiness in relation to such credit facilities. <br /><br />
                                4.	6.4. We may share your Personal Information (including Sensitive Personal Information) with external third parties such as: <br /><br />
                                1.	Other third parties and financial institutions for the purpose of enabling such entities to market and offer their products and services to you. We may also share your Personal Information (including Sensitive Personal Information) with such third parties and financial institutions to assist them in understanding your interests, habits, and usage patterns for certain programs, content, services, advertisements, promotions and such third parties and financial institutions may market and offer their products and services to you based on this.<br />
                                2.	merchants to in accordance with our service agreements (also referred to as our terms and conditions or contracts) whether directly or through a third party such as a payment aggregator or a referral partner.<br />
                                3.	CKYC (Central KYC Registry) as a mandatory requirement under applicable laws in India.<br />
                                4.	 Third parties or service providers or vendors under contract who assist us with our business operations. including in relation to completion of your KYC process, eligibility checks and storage of such information or data, as required by Lending Partners. This will also include any other third parties which may be engaged by EAZR and/or Lending Partners including their affiliates and/or group companies, agents and service providers.<br />
                                5.	 companies that we plan to merge with or entities that we may acquire or be acquired by subject to the provisions of applicable laws, in which situation we will require that the new combined entity or the acquired/ acquiring entity follows this EAZR Privacy Statement with respect to your Personal Information.<br />
                                6.	when required by law enforcement, government officials, fraud detection agencies, financial institutions or other third parties and when we are compelled to do so under applicable laws (such as via a subpoena, court order, regulatory query or similar procedure).<br />
                                7.	international entities we partner with for the offer and/or development of products and services subject to the requirements under this EAZR Privacy Statement and applicable laws. EAZR ensures that every third party that is involved in the processing of your Personal Information (including Sensitive Personal Information) under a contract with us has the required organizational and technical protections in place as may be required in accordance with applicable laws in India.<br /><br />
                                The detailed list of third parties is provided here <br /> <br />
                                EAZR ensures that every third party that is involved in the processing of your Personal Information (including Sensitive Personal Information) under a contract with us has the required organizational and technical protections in place as may be required in accordance with applicable laws in India. EAZR will take an explicit consent from you before sharing your Personal Information (including Sensitive Personal Information) with third-parties, except in cases where the information is to be shared for regulatory or statutory purposes.
                                In case you need further details about service providers/third parties with whom your data will be shared, please write to us at privacy@eazr.app  </div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'>7. MARKETING </h5><br />
                                <p className='text__16'>
                                    In relation to the products and services provided by us or by third parties with whom we share your Personal Information and Sensitive Personal Information as per this EAZR Privacy Statement, you may receive marketing communications from EAZR or such other third parties, by messages, or email or through other digital modes, on Platforms, Websites and Apps or such other platforms including social media platforms, or on such third party platforms Websites and Apps including social media platforms, if you have provided us your Personal Information or Sensitive Personal Information. We may market our own products or services including the products and services offered by our Partners and the product and services being offered by third parties using such communication. The provision of such marketing activities shall be in accordance with applicable laws. For the marketing activities done by us, we execute contracts with our merchants, service providers, banks or other third parties. You are entitled to opt out from receiving such marketing communication by either clicking on opt-out link (if available) or un-subscribe by clicking the link provided (if available) or by writing to us at privacy@eazr.app EAZR may also use Marketing and Communications Information in order to improve and customize the content of our ads, promotions and advertising that may be of interest to you. </p></div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'>8. INTERNATIONAL DATA TRANSFERS</h5> <br />
                                <p className='text__16'>Your Personal Information and Sensitive Personal Information may be processed either locally in India, or in any other country where our approved third-party service providers operate, worldwide, as permitted by applicable laws, provided that transaction related data i.e. data pertaining to our products and services provided to you will be stored in India. Through this EAZR Privacy Statement, we hereby seek your consent to transfer your Personal Information (including Sensitive Personal Information) to our approved third party service providers under designated contracts for the purposes defined under this EAZR Privacy Statement or as may be communicated to you from time to time. Should your Personal 11Information (including Sensitive Personal Information) move outside India, we will ensure to comply with the provisions of applicable laws and will also ensure that our third party processors shall also comply with the same level of data protection as are applicable in India under applicable laws.</p></div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'> 9. DATA RETENTION </h5><br />
                                <p className='text__16'> EAZR will only retain minimal Personal Information and Sensitive Personal Information for our operational purposes. However, Lending Partners would store Personal Information and Sensitive Personal Information till such time as is required to fulfil the purposes for such information was collected, including you continue to have a loan or credit facility. The retention of Personal Information and Sensitive Personal Information by EAZR and Lending Partners is determined by considering compliance with legal (contractual, statutory or regulatory requirements), accounting and compliance reporting requirements. <br />
                                    Subject to the rights provided to you, the storage and purpose limitation restrictions provided within this Privacy Statement, and applicable laws, EAZR shall retain your Personal Information and Sensitive Personal Information for a maximum period of 10 years. </p></div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'> 10. WHAT ARE YOUR INDIVIDUAL RIGHTS?</h5> <br />
                                <p className='text__16'>We ensure that you are able to exercise your individual privacy rights under applicable privacy and data protection laws and as per our internal policies. This means that EAZR seeks to provide reasonable assistance to cater to requests from individuals regarding the processing of their Personal Information and the right to amend their Personal Information and withdraw permission to the processing of their Sensitive Personal Information. In accordance with applicable laws and as per our policies, you can exercise the following rights: <br /> <br />
                                    •	Review, Correct or Rectify: your Personal Information: You can ask us to have inaccurate Personal Information (including Sensitive Personal Information) we process about you, reviewed, fixed or changed in the time frame as specified in the applicable law. <br />
                                    •	Right to access: You may request record of your Personal Information (including Sensitive Personal Information) being processed by us, upon the verification of your identity. <br /> <br />
                                    Please submit a request at privacy@eazr.app if you would like to exercise any of the above rights. These rights are limited in some situations, such as where we are legally or otherwise bound to process or retain your Personal Information (including Sensitive Personal Information). Please note that this may also affect our ability to process or enable your Personal Information (including Sensitive Personal Information) and may therefore lead to the discontinuation of those services or products for which this Personal Information (including Sensitive Personal Information) was being used for, at our sole discretion. Please note that you do not have an unqualified right under applicable laws for deletion of your Personal Information (including Sensitive Personal Information). Further, in case you withdraw your consent / request for deletion of your Personal Information and Sensitive Personal Information, you would be required to intimate EAZR and the relevant Partner Financial InstitutionLenders in writing at privacy@eazr.app <br /><br />

                                    In case you desire to delete your Personal Information (including Sensitive Personal Information) stored by EAZR and/or its Partner Lender, you may be required to clear all your outstanding dues in relation to such product(s) and service(s) that such Personal Information is related to. This can be availed by you through Lazpay and/or Partner Lender. Please submit a request at privacy@eazr.app Please note that the right to request deletion of data is limited in some situations. In case EAZR and/or its Partner Lender is legally or otherwise bound to process or retain your Personal Information for a specified period as per applicable law, they may continue to retain and / or process the same. Please note that your data deletion request may affect EAZR and/or its Partner Lender’s ability to process Personal Information and therefore, EAZR and/or its Partner Lender’s at its sole discretion, may discontinue those services or products for which this Personal Information is required. <br /><br />
                                    In case you desire to revoke/withdraw your consent to collect, store, use, process or share any Personal Information (including Sensitive Personal Information), you shall intimate EAZR by writing at privacy@eazr.app and immediately cease the use of the loan or credit facility, and make payments of all outstanding dues in relation to the loan or credit facility availed by you from EAZR and/or its Lending Partners. In such cases, EAZR and/or its Lending Partners will also not be able to continue providing the loan or credit facility to you. </p></div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'> 11. SECURITY: HOW WE PROTECT & STORE PERSONAL INFORMATION  </h5><br />
                                The security of your Personal Information and Sensitive Personal Information is important to us. We take all physical, technical, managerial, and operational measures that are that are designed to improve the integrity and security of information that we collect and maintain as required by applicable law. EAZR regularly reviews its policies regarding the collection, storage and processing of your Personal Information (including Sensitive Personal Information), including physical security measures, to prevent alteration, loss or fraudulent or unauthorized access of your Personal Information (including Sensitive Personal Information). EAZR has put in place procedures to deal with breach of Personal Information and will notify you and any applicable regulator or authority of a breach where we are legally required to do so. </div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'>12. MINORS</h5> <br />
                                EAZR does not voluntarily or actively collect, use or disclose Personal Information of minors, according to the minimum age equivalent in the relevant jurisdiction, without the prior consent of the parents or guardians of the minor. The services of EAZR are not intended or designed to attract minors. If we learn that we collected the Personal Information (including any Sensitive Personal Information) of a minor, without first receiving a verifiable parental consent, we will take reasonable steps to delete the information in a manner consistent with applicable laws of India as soon as possible. </div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'>13. CHANGES TO THE EAZR PRIVACY STATEMENT AND YOUR DUTY TO INFORM US OF CHANGES </h5><br /><br />
                                This EAZR Privacy Statement may change or be amended over time. The recent version of this EAZR Privacy Statement is published on this Website or App or Platform, as the case may be. Please revisit this page periodically to stay aware of any changes to this EAZR Privacy Statement. We will notify you of any material changes to this EAZR Privacy Statement by publishing the same on our Website, App or Platform, as applicable. Your continued use of our services confirms your acceptance of this EAZR Privacy Statement, as amended. If you do not agree to the terms and conditions as contained in our EAZR Privacy Statement, as amended, you must stop using our services and notify us. It is very important that any Personal Information and Sensitive Personal Information we hold about you is up to date and correct. Please inform us of any changes to your Personal Information and/or Sensitive Personal Information </div>
                            <div className='mb-3'>
                                <h5 className='font-bold text__18'>14. HOW TO CONTACT US </h5><br />
                                <p className='text__16'> The controller of this Website, App and/or Platform (as applicable) is EAZR Digipayments Private limited having its registered office at Mumbai, Maharashtra and corporate office at 302, Tower 2B South Annex, One World Center Lower Parel West Mumbai 400013. We are happy to address any of your queries, concerns or questions relating to processing (including storing and using) of your information which you can raise by writing to us at privacy@eazr.app You may address any complaints or discrepancies in relation to the processing of your Personal Information to: <br /> <br />
                                    Mr. Manish R Grievance Officer EAZR Digipayments Private limited 302, Tower 2B South Annex, One World Center Lower Parel West Mumbai 400013.
                                </p>
                            </div>


                            <div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </Fragment >
    )
}

export default Privacy
