import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const Rd = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [timePeriod, setTimePeriod] = useState(0);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [estimatedReturns, setEstimatedReturns] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  // console.log(investmentAmount,annualInterestRate,investmentPeriod,expectedReturn,maturityAmount,totalInvestment,totalInterest)
  // chart 

  const data = {
    labels: [
      'Investment amount',
      'Total Interest',
    ],
    datasets: [{
      label: 'Eazr',
      data: [monthlyInvestment, estimatedReturns],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateRD = () => {
    const monthlyInvestments = parseFloat(monthlyInvestment);
    const annualRate = parseFloat(interestRate) / 100;
    const years = parseInt(timePeriod);

    // Calculate Invested Amount
    const investedAmount = monthlyInvestments * 12 * years;
    setInvestedAmount(investedAmount.toFixed(2));

    // Calculate Estimated Returns
    const interest = (monthlyInvestments * years * (years + 1) * annualRate) / 24;
    setEstimatedReturns(interest.toFixed(2));

    // Calculate Total Value
    const totalValue = investedAmount + interest;
    setTotalValue(totalValue.toFixed(2));
  };

  useEffect(() => {
    calculateRD()

  }, [monthlyInvestment, timePeriod, interestRate])
  return (
    <section >
      <Container>
        <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>Recurring Deposit Calculator</h2>
        <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col items-center'>
              <div className='w-100'>
                <label for="loanamount" >
                  <div className="flex justify-between items-center">
                    <p className="w-60">Monthly Investment (&#8377;):</p>
                    <Form.Control
                      type="number"
                      value={monthlyInvestment}
                      id="loanamount"
                      className='w-50'
                      min="0"
                      onChange={(e) => setMonthlyInvestment(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <input
                      type="range"
                      id="loanamount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="40000"
                      value={monthlyInvestment}
                      onChange={(e) => setMonthlyInvestment(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='w-100'>
                <label for="interestRate">
                  <div className="flex justify-between items-center">
                    <p className="w-40 sm:w-60">Rate of Interest (p.a)</p>
                    <Form.Control
                      type="number"
                      value={interestRate}
                      id="interestRate"
                      className='w-50'
                      min="0"
                      onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <input
                      type="range"
                      id="interestRate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="15"
                      step="0.1"
                      value={interestRate}
                      onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='w-100'>
                <label for="timePeriod">
                  <div className="w-full flex justify-between items-center">
                    <p className="w-40 sm:w-60">Time Period (Years):</p>
                    <Form.Control
                      type="number"
                      value={timePeriod}
                      id="timePeriod"
                      className='w-50'
                      min="0"
                      onChange={(e) => setTimePeriod(parseInt(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <input
                      type="range"
                      id="intersetrate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="25"
                      step="1"
                      value={timePeriod}
                      onChange={(e) => setTimePeriod(parseInt(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />

            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-auto'><Pie data={data} /></div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {monthlyInvestment && <div className='m-auto'>Total Investment: {monthlyInvestment}</div>}
          {estimatedReturns && <div className='m-auto'>Est Return: ₹{estimatedReturns}</div>}
          {totalValue && <div className='m-auto'>Total Value: {totalValue}</div>}  

          </div>  
        <div>
          <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a Recurring Deposit (RD) Calculator ?</h1>
          <p className='text__16 leading-7 mb-2'>
            A Recurring Deposit (RD) calculator is a financial tool or online application that helps individuals calculate the potential returns and maturity value of their recurring deposit accounts. A recurring deposit is a type of savings account offered by banks and financial institutions, allowing customers to deposit a fixed amount of money at regular intervals (usually monthly) for a predetermined period. RDs offer a fixed interest rate, making them a popular choice for individuals looking to save and earn a predictable return on their savings.</p>
          <p className='text__16 leading-7'>Recurring Deposit calculators are valuable tools for individuals who want to plan and project the growth of their investments in recurring deposits. They help users make informed decisions about the monthly deposit amount, the tenure, and the choice of bank or financial institution for their RDs. </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default Rd