import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SecFaq from '../Components/Pages/SecFaq'
import SecCreate from '../Components/Pages/SecCreate'

const HowItWorks = () => {
    return (
        <Fragment>
            <section className='bg-Mbg'>
                <Container>
                    <div className="text-center mb-10 relative">
                        <h3 className='text__48 font-medium mb-2 relative z-2'>Superboost your sales with Eazr <br/> <span className='text__24'>Attract, convert, and retain customers with deferred payment options.</span></h3>

                        <img src="./../images/ballon.svg" className='absolute w-[3rem] lg:w-auto -top-[5rem] lg:-top-[5rem] left-[90%] md:left-[87%] -translate-x-1/2' alt="" />
                    </div>

                    <Row className='gap-y-6'>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/24-support-circle-checkmark.svg" alt="" />
                                </div>

                                <h4 className='font-medium text__24 mb-2'>Support that never sleeps</h4>
                                <p className='text__16'>Well almost! Our support team are stationed all over the world – to give you fast, friendly advice anywhere, any time.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/coins.svg" alt="" />
                                </div>

                                <h4 className='font-medium text__24 mb-2'>Get paid directly</h4>
                                <p className='text__16'>We don’t touch your ticket money. When a ticket is sold, you get paid into your payment account instantly and directly.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="p-4 w-full bg-white rounded-[24px] h-full shadow-[40px_40px_120px_0px_rgba(46,47,58,0.04)]">
                                <div className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px] rounded-full flex items-center justify-center bg-MsoftPurple border border-solid border-Mpurple mb-4">
                                    <img src="./../images/hand-coins-currency.svg" alt="" />
                                </div>

                                <h4 className='font-medium text__24 mb-2'>Make everything simple</h4>
                                <p className='text__16'>We guide you through to get you set up and selling fast. You’re in control – customise as much or as little as you like.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section>
                <Container>

                    <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 gap-4 justify-between">
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>01</h5>
                            <h2 className='font-medium text__48 mb-2'>Simple box-office set-up</h2>
                            <p className='text__16 mb-12'>Set-up a simple, professional box-office page on your site (or standalone) in minutes. Then design and customise it as much (or as little) as you like with our user-friendly dashboard.</p>

                            <img src="./../images/vf (2).png" alt="" />
                        </div>
                        <div className='md:mt-[8rem]'>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>02</h5>
                            <h2 className='font-medium text__48 mb-2'>Sell tickets anywhere online</h2>
                            <p className='text__16 mb-12'>Run your box office as its own website, or embed it into your website with a simple piece of HTML or widget. All with a reliable, mobile-friendly design.</p>

                            <img src="./../images/vf (4).png" alt="" />
                        </div>
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>03</h5>
                            <h2 className='font-medium text__48 mb-2'>Manage guests easily</h2>
                            <p className='text__16 mb-12'>You can send updates to guests anytime and use our dedicated mobile app to check people in on the day. Order confirmation and email ticket delivery is all automated and customisable.</p>

                            <img src="./../images/vf (1).png" alt="" />
                        </div>
                        <div className='md:mt-[8rem]'>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>04</h5>
                            <h2 className='font-medium text__48 mb-2'>Lots of ways to get paid - fast</h2>
                            <p className='text__16 mb-12'>Ticket buyers can use a credit or debit card, Apple Pay, Google Pay, PayPal, Stripe or Square – and pay in any currency and language. You get paid instantly via Stripe or PayPal in any currency.</p>

                            <img src="./../images/vf (3).png" alt="" />
                        </div>
                    </div>

                </Container>
            </section>

            <SecFaq />

            <SecCreate />
        </Fragment>
    )
}

export default HowItWorks
