import {
    FETCH_USER_PROFILE,
    FETCH_USER_FAILED,
    FETCH_USER_SUCCESS,
  } from "./ActionType";



  export const initialState = {
    user: [],
    error: "",
  };

  export const UserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_PROFILE:
        return {
          ...state,
          errors: null,
        };

      case FETCH_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          error: null,
        };

      case FETCH_USER_FAILED:
        return {
          ...state,
          user: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };