import React from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const RefundandPrivacyPolicy = () => {
  return (
    <Fragment>
      <section className="bg-Mbg py-16">
        <Container>
          <div className="text-center mb-2 relative">
            <h3 className="text__48 font-medium mb-2 relative z-2">
              Refund & Cancellation Policy
            </h3>

            <img
              src="./../images/ballon.svg"
              className="absolute w-[3rem] lg:w-auto -top-[5rem] lg:-top-[3rem] left-[90%] md:left-[80%] lg:left-[73%] -translate-x-1/2"
              alt=""
            />
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="justify-center">
            <Col md={10}>
              <h5 className="font-bold text__18 mb-2">
                EAZR DIGIPAYMENTS PRIVATE LIMITED believes in helping
                its customers as far as possible, and has therefore a
                liberal cancellation policy. Under this policy
              </h5>
              <p className="text__16">
                {" "}
                • Cancellations will be considered only if the request
                is made immediately after placing the order. However,
                the cancellation request may not be entertained if the
                orders have been communicated to the vendors/merchants
                and they have initiated the process of shipping them.
                <br /> <br />
                • EAZR DIGIPAYMENTS PRIVATE LIMITED does not accept
                cancellation requests for perishable items like
                flowers, eatables etc. However, refund/replacement can
                be made if the customer establishes that the quality
                of product delivered is not good.
                <br /> <br />
                • In case of receipt of damaged or defective items
                please report the same to our Customer Service team.
                The request will, however, be entertained once the
                merchant has checked and determined the same at his
                own end. This should be reported within 2 Days days of
                receipt of the products. In case you feel that the
                product received is not as shown on the site or as per
                your expectations, you must bring it to the notice of
                our customer service within 2 Days days of receiving
                the product. The Customer Service Team after looking
                into your complaint will take an appropriate decision.
                <br /> <br />
                • In case of complaints regarding products that come
                with a warranty from manufacturers, please refer the
                issue to them. In case of any Refunds approved by the
                EAZR DIGIPAYMENTS PRIVATE LIMITED, it’ll take 3-5 Days
                days for the refund to be processed to the end
                customer. <br /> <br />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default RefundandPrivacyPolicy;
