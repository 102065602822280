import React from 'react'
import Lottie from "react-lottie";
import landing2 from "../../LottieJson/landing2.json";
import { Carousel, Col, Container, Nav, Row } from "react-bootstrap";
import { useEffect } from 'react';
import { useState } from 'react';
import Navbar from '../Nav/Navbar';
import qrcode from '../../LottieJson/qrcode.webp'

const DownloadPage = () => {

  const phrases = ["Health", "Insurance", "Education"];
  const [index, setIndex] = useState(0);
    const [phrase, setPhrase] = useState(phrases[index]);

    useEffect(() => { 
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 1800);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setPhrase(phrases[index]);
    }, [index]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: landing2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },  
    };
  return (
    <>
      {/* <Navbar/> */}
      <section className="relative overflow-hidden min-h-[calc(100vh_-_88px)] lg:min-h-[calc(100vh_-_98px)] bg-Mbg flex flex-wrap pb-0 pt-12 bg-#f8f8f8 ">
        <Container className="relative z-[2] w-full">
          <div className="block md:flex ">
            <div className="w-full lg:w-1/2">
              <h1
                className="font-medium text__64 mb-4 sm:text-center md:text-left font-light"
                style={{ fontFamily: "Nayuki" }}
              >
                Ab{" "}
                <span
                  className="font-extralight"
                  style={{
                    fontFamily: "poppins",
                    color: "#FF84C2",
                  }}
                >
                  {phrase}
                </span>{" "}
                <br />
                <span> Sabke Liye. </span>
              </h1>
              {/* <h4
                                className="text-lg mb-10"
                                style={{
                                    lineHeight: "30px",
                                }}
                            >
                                Avail Interest Free credit to embrace health
                                <br />
                                and education today, pay at your convenience.
                            </h4> */}

              <div className="box">
                <img
                  src="/images/qrcode.webp"
                  alt="qrcode"
                  className="qrcode"
                />
                <h2 className="m-auto leading-6 font-bold text__18">
                  Download Now
                </h2>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <img
                src="images/Color/Finance.png"
                height={500}
                width="100%"
                style={{ marginTop: "-60px" }}
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default DownloadPage