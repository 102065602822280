import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const NpvCalculator = () => {
  const [initialInvestment, setInitialInvestment] = useState(100000);
  const [discountRate, setDiscountRate] = useState(12);
  const [numOfYears, setNumOfYears] = useState(5);
  const [cashInflowType, setCashInflowType] = useState('fixed');
  const [fixedCashInflows, setFixedCashInflows] = useState(10000);
  const [variableCashInflows, setVariableCashInflows] = useState([]);
  const [presentValue, setPresentValue] = useState(0);
  const [netPresentValue, setNetPresentValue] = useState(0);
  // chart 

  

  //calculation 
  const calculateNPV = () => {
    const discountRateDecimal = discountRate / 100;
    const years = parseInt(numOfYears);
    const initial = parseFloat(initialInvestment);

    // Calculate the present value of fixed cash inflows
    let presentValueFixed = 0;
    if (cashInflowType === 'fixed') {
      const fixed = parseFloat(fixedCashInflows);
      for (let i = 1; i <= years; i++) {
        presentValueFixed += fixed / Math.pow(1 + discountRateDecimal, i);
      }
    }

    // Calculate the present value of variable cash inflows
    let presentValueVariable = 0;
    if (cashInflowType === 'variable') {
      for (let i = 0; i < years; i++) {
        const variable = parseFloat(variableCashInflows[i]);
        presentValueVariable += variable / Math.pow(1 + discountRateDecimal, i + 1);
      }
    }

    const totalPresentValue = presentValueFixed + presentValueVariable;

    const npv = totalPresentValue - initial;
    setPresentValue(totalPresentValue.toFixed(2));
    setNetPresentValue(npv.toFixed(2));
  };


  useEffect(() => {
    calculateNPV()
  }, [initialInvestment,discountRate,numOfYears,cashInflowType,fixedCashInflows])
  return (
    <section >
    <Container>
      <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Net Present Value (NPV) Calculator</h2>
                <p className='text__16 mb-3'>Venture into the analytical depths of finance with our NPV Calculator. This vital tool evaluates the profitability of an investment by calculating the difference between the present value of cash inflows and outflows over a period. It's an indispensable asset for anyone aiming to gauge the potential return on investment.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Specify the discount rate (The rate at which future cash flows are discounted back to present value).<br />
                    2. Input the initial investment (Generally a negative value as it's an outflow).<br />
                    3. Enter the projected cash inflows/outflows for each subsequent period (Yearly, quarterly, etc., depending on your analysis).<br />
                    
                </p>
      <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
        <div className=' w-full sm:w-1/2 p-3 h-max h-[60vh]'>
          <div className='flex flex-col items-center'>
            <div>
              <label for="initialInvestment">
                <div className="row">
                  <p className='col-6'>Initial Investment: </p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={initialInvestment}
                    id="initialInvestment"
                    min="0"
                    step="100000"
                    onChange={(e) => setInitialInvestment(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    id="initialInvestment"
                    className='form-range'
                    name="points"
                    min="0"
                    max="1000000"
                    step="100000"
                    value={initialInvestment}
                    onChange={(e) => setInitialInvestment(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
            <br />
       
            <div>
              <label for="discountRate">
                <div className="row">
                 <p className='col-6'>Discount Rate (%):</p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={discountRate}
                    id="discountRate"
                    max="5"
                    min="5"
                    onChange={(e) => setDiscountRate(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
              <div className="text-center">
                  <input
                    type="range"
                    id="discountRate"
                    className='form-range'
                    name="points"
                    min="0"
                    max="200"
                    step="25"
                    value={discountRate}
                    onChange={(e) => setDiscountRate(parseFloat(e.target.value) || 0)}
                  />
                </div> 
              </label>
            </div>
            <br />
       
       <div>
         <label for="numOfYears">
           <div className="row">
            <p className='col-6'>Number of Years:</p>
             <input
               type="number"
               className='col-6 w-[30%]'
               value={numOfYears}
               id="numOfYears"
               max="5"
               min="5"
               onChange={(e) => setNumOfYears(parseInt(e.target.value) || 0)}
             />{" "}
           </div>
         <div className="text-center">
             <input
               type="range"
               id="numOfYears"
               className='form-range'
               name="points"
               min="0"
               max="200"
               step="25"
               value={numOfYears}
               onChange={(e) => setNumOfYears(parseInt(e.target.value) || 0)}
             />
           </div> 
         </label>
       </div>
       <div>
        <label>Nature of Cash Inflows:</label>
        <select value={cashInflowType} onChange={(e) => setCashInflowType(e.target.value)}>
          <option value="fixed">Fixed</option>
          <option value="variable">Variable</option>
        </select>
      </div>
      {cashInflowType === 'fixed' && (
        <div>
          <label>Amount of Fixed Cash Inflows P.A:</label>
          <input
            type="number"
            value={fixedCashInflows}
            onChange={(e) => setFixedCashInflows(parseFloat(e.target.value) || 0)}
          />
        </div>
      )}
      {/* {cashInflowType === 'variable' && (
        <div>
          <label>Yearly Variable Cash Inflows:</label>
          <input
            type="text"
            placeholder="Enter comma-separated values"
            value={variableCashInflows.join(',')}
            onChange={(e) => setVariableCashInflows(e.target.value.split(','))}
          />
        </div>
      )} */}
      <div></div>
          </div>

        </div>
        <div className=' w-full sm:w-1/2 p-3 h-max flex justify-center text-center items-center'>
          {/* <div className='h-auto'><Pie data={data}/></div> */}
          {presentValue && <div className='text__24 leading-9'>Present Value Of Cash Flows: <br/> <span className='font-bold text__32'>{presentValue}</span></div>}
          {netPresentValue && <div className='text__24 leading-9'>Net Present Value (NPV): <br/> <span className='font-bold text__32'>{netPresentValue}</span></div>}
          {/* {totalAmount && <div className='text__24 leading-9'>Total Amount: <br/> <span className='font-bold text__32'>{totalAmount}</span></div>} */}
          
          </div>
      
      </div>

    
      <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Investment Decision Making: A positive NPV suggests a potentially profitable investment, while a negative NPV indicates potential loss. <br />
                        2. Comparative Analysis: Compare NPVs of different projects or investments to prioritize and allocate resources.<br />
                        3. Time Value Consideration: Unlike basic ROI calculations, NPV considers the time value of money, offering a more holistic view.
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
      <PopularCalculator />
    </Container>
  </section>  
  )
}

export default NpvCalculator