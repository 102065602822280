import React from 'react'
import { Fragment } from 'react'
import { LogoIcon } from '../../Components/Icon/Icon'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { sendOtp, setContactNumbers } from '../../Redux/authTransaction/Action'
import { useForm } from "react-hook-form";
import './Logincss.css';

const Login = () => {
    const [isValid, setIsValid] = useState(false);
    const [mobno, setMobno] = useState("")
    const [error, setError] = useState(null)

    const handleMobileNumberChange = (event) => {
        const inputNumber = event.target.value;
        // Regular expression to match a 10-digit mobile number
        const mobileNumberRegex = /^[0-9]{10}$/;
        setIsValid(mobileNumberRegex.test(inputNumber));
        setMobno(inputNumber);
        setError(null)
    };

    const dispatch = useDispatch()
    const [tooglePassword, settooglePassword] = useState(true)
    const [toogleChecklist, settoogleChecklist] = useState(false)
    console.log(mobno)
    const history = useNavigate()
    const { loading, contactNumber } = useSelector(state => state?.otp);
    console.log(loading)

    const submit = (e) => {
        //  e.preventDefault();
        if (isValid) {
            //   alert(`Mobile Number: ${mobno} is valid.`);
            dispatch(sendOtp(mobno, history))
            dispatch(setContactNumbers(mobno))
            // You can perform additional actions here, such as making an API request.
        } else {
            setError('Please enter a valid 10-digit mobile number.');
        }


    }
    return (
        <Fragment>
            <NavLink to="/" className=''>
                <div className="flex items-center gap-2 mb-10">
                    <LogoIcon />
                    {/* <span className='font-medium text__20 text-Mpurple'>TicketHub</span>  */}
                    {/* <img src="./../images/Eazr-Logo-Blue.png"/> */}
                </div>
            </NavLink>
            {/* <div className="flex items-center gap-2 mb-10">
                <LogoIcon />
            </div> */}

            <div className='py-20 ' >
                <div className=''>
                    <div className="text-center mb-10" >
                        <h4 className='font-medium text__48 mb-2 font-bold'>Get started!</h4>
                        <h5 className='font-medium text__20 mb-2 '>Please enter your mobile number</h5>
                        {/* <p>Don’t have an account? <NavLink to="/auth/signup" className='text-Mblue'>Sign Up</NavLink></p> */}
                    </div>


                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='font-normal text__16 text-[#A3A3A3]'>Mobile No. </Form.Label>
                        <Form.Control type="tel" placeholder="9800 XXX XXX" min="10" max="10" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                            onChange={handleMobileNumberChange} />
                    </Form.Group>


                    <p className='text-red-500 text__12 text-center mb-2'>{error}</p>
                    <div className="text-center">
                        <button type="submit" onClick={() => submit()}><NavLink
                            //  to="/auth/otp"
                            className='inline-block cursor-pointer text-center font-medium text__16 text-white !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Continue</NavLink></button>

                        {/* <div className="my-3 relative">
                    <div className="absolute h-[1px] left-0 w-full top-1/2 -translate-y-1/2 bg-[#F5F5F5]"></div>
                    <div className='px-4 py-2 bg-white relative inline-block text__12 text-[#525252] relative z-[2]'>Or Log In with</div>
                </div> */}

                        {/* <div className="grid grid-cols-1 grid-rows-2 gap-3">
                    <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                        <img src="./../images/Icon - Google.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                        <div className="relative z-2 font-medium text__14">
                            Google
                        </div>
                    </a>
                    <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                        <img src="./../images/facebook-3-2 1.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                        <div className="relative z-2 font-medium text__14">
                            Facebook
                        </div>
                    </a>
                </div> */}
                    </div>
                </div>
            </div>


        </Fragment>
    )
}

export default Login
