import React from 'react'
import { NavLink } from 'react-router-dom'

export const CardEvents = (props) => {
    console.log(props)
    return (
        <NavLink to="/BlogPage" className='w-full rounded-[20px]'>
            <img src={props.data.img} className='w-[450px] h-[240px]  object-fill m-auto text-left rounded-md sm:rounded-2xl object-fill mb-3 hover:w-[454px] hover:h-[242px]  duration-500' alt="" />
            <h5 className='font-medium text__20 ml-5'>{props.data.title}</h5>
            <p className='text__16 text-Mgray flex gap-1 justify-left '>
                <span className='font-medium flex-shrink-0 ml-5'>{props.data.category}</span>
                {/* <span className='flex-shrink-0'>·</span> */}
                {/* <span className='flex-shrink-0'>{props.data.location}</span> */}
            </p>
        </NavLink>
    )
}