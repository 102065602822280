import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2';
import './PersonalLoancss.css'

const PersonalLoan = () => {
  let [loan, setLoan] = useState(10000);
  let [roi, setRoi] = useState(2);
  let [loantenure, setloantenure] = useState(25);
  const [emi, setEmi] = useState('');
  const [totalInterest, setTotalInterest] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [principleamount, setPrinicipleamount] = useState(0)
  // chart 

  const data = {
    labels: [
      'Principle Amount',
      'Total Interest',
    ],
    datasets: [{
      label: 'Eazr',
      data: [principleamount, totalInterest],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateEMI = () => {
    const principal = parseFloat(loan);
    const rate = parseFloat(roi) / 12 / 100;
    const tenure = parseFloat(loantenure);

    if (principal > 0 && rate > 0 && tenure > 0) {
      const emiValue = (principal * rate * Math.pow(1 + rate, tenure)) / (Math.pow(1 + rate, tenure) - 1);
      setEmi(emiValue.toFixed(2));

      const totalInterestValue = (emiValue * tenure) - principal;
      setTotalInterest(totalInterestValue.toFixed(2));

      const totalAmountValue = principal + totalInterestValue;
      setTotalAmount(totalAmountValue.toFixed(2));

      const totalprinciple = totalAmount - totalInterestValue;
      setPrinicipleamount(totalprinciple)

    } else {
      setEmi('');
      setTotalInterest('');
      setTotalAmount('');

    }
  };

  useEffect(() => {
    calculateEMI()
  }, [loan, loantenure, roi])
  return (
    <section className='py-4'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Personal Loan EMI Calculator</h2>
        <p className='text__16 mb-3'>Elevate your financial planning and make informed decisions with our easy-to-use Personal Loan EMI Calculator. Get instant estimates on your monthly EMI payments for personal loans and plan your expenses better.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Enter the loan principal amount (The amount you wish to borrow). <br />
          2. Set the interest rate (Annual rate provided by your lender).<br />
          3. Choose the loan tenure (In months or years, depending on your loan term). <br />
          4. Click on the "Calculate" button to get your monthly EMI, total interest payable, and total amount payable.
        </p>

        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="loanamount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Loan Amount :</p>
                    <input
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      type="number"
                      value={loan}
                      id="loanamount"
                      min="1000"
                      onChange={(e) => setLoan(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="10000000"
                      step="5000"
                      value={loan}
                      onChange={(e) => setLoan(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="intersetrate">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Interest Rate (p.a) :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={roi}
                      id="intersetrate"
                      min="0"
                      onChange={(e) => setRoi(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="intersetrate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="0.5"
                      value={roi}
                      onChange={(e) => setRoi(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="loantenure">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Loan Tenure (months):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={loantenure}
                      id="loantenure"
                      min="0"
                      onChange={(e) => setloantenure(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loantenure"
                      name="points"
                      className='form-range w-100'
                      min="1"
                      max="240"
                      step="1"
                      value={loantenure}
                      onChange={(e) => setloantenure(e.target.value)}
                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className='w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
          {totalInterest && <div className='m-auto'>Total Interest Paid: {totalInterest}</div>}
          {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount}</div>}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Plan Your Budget: Know your monthly obligations and plan your budget effectively. <br />
            2. Compare Loan Offers: Different banks offer different interest rates. Use this calculator to make an apples-to-apples comparison.<br />
            3. Save Time: Get instant results without manual calculations or consultations. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />


      </Container>
    </section>
  )
}

export default PersonalLoan