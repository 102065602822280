import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const LumpSum = () => {
  // const { name } = useParams();
  const [initialInvestment, setInitialInvestment] = useState(25000);
  const [expectedReturnRate, setExpectedReturnRate] = useState(12);
  const [timePeriod, setTimePeriod] = useState(10);
  const [totalValue, setTotalValue] = useState(null);
  const [estimatedReturns, setEstimatedReturns] = useState(null);
  // chart 

  const data = {
    labels: [
      'Invested Amount',
      'Est Return',

    ],
    datasets: [{
      label: 'Eazr',
      data: [initialInvestment, estimatedReturns],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'initialInvestment') setInitialInvestment(value);
    if (name === 'expectedReturnRate') setExpectedReturnRate(value);
    if (name === 'timePeriod') setTimePeriod(value);
  };

  const calculateTotalValue = () => {
    const principal = parseFloat(initialInvestment);
    const rate = parseFloat(expectedReturnRate) / 100; // Convert percentage to decimal
    const years = parseFloat(timePeriod);

    // Calculate the total value using the formula: Total = Principal * (1 + Rate)^Years
    const totalValue = principal * Math.pow(1 + rate, years);

    setTotalValue(totalValue);

    // Calculate estimated returns
    const estimatedReturns = totalValue - principal;
    setEstimatedReturns(estimatedReturns);
  };



  useEffect(() => {
    calculateTotalValue()

  }, [initialInvestment, expectedReturnRate, timePeriod])
  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>LumpSum Calculator</h2>
        <p className='text__16 mb-3'>Thinking of investing a substantial amount at once? The Lump Sum Calculator is tailored to help you estimate the potential growth of your one-time investment over a set period, factoring in the compounding effect.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Specify your initial lump sum amount (The amount you plan to invest as a one-off).<br />
          2. Set the expected annual returns (The average yearly return you anticipate from your investment).<br />
          3. Choose the investment duration (Number of years you aim to keep the investment intact). <br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="loanamount" >
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Total Investment (&#8377;):</p>
                    <input
                      type="number"
                      name="initialInvestment"
                      value={initialInvestment}
                      onChange={handleInputChange}
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="4000"
                      value={initialInvestment}
                      onChange={(e) => setInitialInvestment(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="interestRate">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Expected return rate (p.a)</p>
                    <input
                      type="number"
                      name="expectedReturnRate"
                      value={expectedReturnRate}
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      onChange={handleInputChange}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="interestRate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="15"
                      step="0.1"
                      value={expectedReturnRate}
                      onChange={(e) => setExpectedReturnRate(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="timePeriod">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Time Period (Years):</p>
                    <input
                      type="number"
                      name="timePeriod"
                      value={timePeriod}
                      onChange={handleInputChange}
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="intersetrate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="25"
                      step="1"
                      value={timePeriod}
                      onChange={(e) => setTimePeriod(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />

            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>
          </div>
        </div>

        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {initialInvestment && <div className='m-auto'>Total Investment: {initialInvestment}</div>}
          {estimatedReturns && <div className='m-auto'>Total Interest: ₹{estimatedReturns.toFixed(2)}</div>}
          {totalValue && <div className='m-auto'>Maturity Value: {totalValue.toFixed(2)}</div>}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Financial Planning: Gain insights into the future value of your investment, helping you align with your financial goals. <br />
            2. Scenario Analysis: Experiment with varying inputs to understand the best-case, average, and worst-case growth scenarios.<br />
            3. Simplified Estimations: Eliminate the need for complex formulas and receive instant, straightforward results. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        {/* <Statements/> */}
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default LumpSum