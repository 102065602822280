import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2';
import './PersonalLoancss.css'
// import rupeelogo from './../../../LottieJson/eazrop-logo.png'

import {
  Chart as ChartJS
} from "chart.js/auto"

const CagrCalculator = () => {
  // const { name } = useParams();
  const [initialInvestment, setInitialInvestment] = useState(100000);
  const [finalInvestment, setFinalInvestment] = useState(1000000);
  const [duration, setDuration] = useState(5);
  const [cagrResult, setCAGRResult] = useState(0);
  // chart 

  const data = {
    labels: [
      'Initial Investment',
      'Final Investment',
    ],
    datasets: [{
      label: 'Eazr',
      data: [initialInvestment, finalInvestment],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateCAGR = () => {
    if (duration === 0) {
      alert('Duration must be greater than zero.');
      return;
    }

    const cagr = ((finalInvestment / initialInvestment) ** (1 / duration) - 1) * 100;
    setCAGRResult(cagr.toFixed(2));
  };

  useEffect(() => {
    calculateCAGR()
  }, [initialInvestment, duration, finalInvestment])
  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>CAGR Calculator</h2>
        <p className='text__16 mb-3'>Step into the realm of investments with a clear perspective using our CAGR Calculator. This powerful tool helps you gauge the geometric progression ratio, providing a smoothed annual rate of growth, eliminating the effects of volatility and fluctuations.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Indicate your initial investment value (The value of your investment at the starting period).<br />
          2. Input the final investment value (The value of your investment at the end of the period).<br />
          3. Specify the number of years over which the investment grew.<br />
          4. Press "Calculate" to unveil the Compound Annual Growth Rate for your investment.
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="loanamount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Initial Investment:</p>
                    <input
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      type="number"
                      value={initialInvestment}
                      id="loanamount"
                      min="1000"
                      onChange={(e) => setInitialInvestment(parseFloat(e.target.value))}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="2000"
                      value={initialInvestment}
                      onChange={(e) => setInitialInvestment(parseFloat(e.target.value))}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="finalInvestment">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Final Investment:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={finalInvestment}
                      id="finalInvestment"
                      min="0"
                      onChange={(e) => setFinalInvestment(parseFloat(e.target.value))}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="finalInvestment"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="2"
                      value={finalInvestment}
                      onChange={(e) => setFinalInvestment(parseFloat(e.target.value))}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="duration">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Loan Tenure (Months) :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={duration}
                      id="duration"
                      min="0"
                      onChange={(e) => setDuration(parseFloat(e.target.value))}

                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="duration"
                      name="points"
                      className='form-range'
                      min="0"
                      max="200"
                      step="25"
                      value={duration}
                      onChange={(e) => setDuration(parseFloat(e.target.value))}

                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {initialInvestment && <div className='m-auto'>initial Investment: {initialInvestment}</div>}
          {finalInvestment && <div className='m-auto'>final Investment: {finalInvestment}</div>}
          {cagrResult && <div className='m-auto'>CAGR Result: {cagrResult}&nbsp;%</div>}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Performance Evaluation: Measure and compare the annualized growth of various investments over a specified period. <br />
            2. Eliminate Volatility: Unlike average annual return, CAGR considers investment duration and provides a smoothed annual rate.<br />
            3. Strategic Insights: Use CAGR as a metric to guide future investments or assess past investment decisions.
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default CagrCalculator