import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style/font.css";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import jwtDecode from "jwt-decode";
import { Provider } from "react-redux";
import { store } from "./store/store";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser } from "./Redux/authTransaction/Action";

const root = ReactDOM.createRoot(document.getElementById("root"));

// if (localStorage.accessToken) {
//   setAuthToken(localStorage.accessToken);
//   const decoded = jwtDecode(localStorage.accessToken);
//   store.dispatch(setCurrentUser(decoded.admin, localStorage.accessToken));
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(setCurrentUser(null));
//   }
// };

if (localStorage.accessToken) {
  // debugger;
  try {
    setAuthToken(localStorage.accessToken);
    const decoded = jwtDecode(localStorage.accessToken);
    store.dispatch(setCurrentUser(decoded.admin, localStorage.accessToken));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      store.dispatch(setCurrentUser(null));
    }
  } catch (error) {
    // Handle the error, e.g., log it or take appropriate action.
    console.error("Error decoding JWT token:", error);
    // You might want to clear the invalid token from localStorage or perform other error handling.
  }
} else {
  // Handle the case where localStorage.accessToken is missing or undefined.
}
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
