import React from 'react';
import { Tab, Row, Col, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './NewBlogPagecss.css';

const NewBlogPage = () => {
    return (
      <>
        <Container>
          <div className="flex justify-center mb-4 mt-2">
            <NavLink to="/BlogPage">
              {" "}
              <img
                src="images/arthshala.png"
                alt="arthshala"
                height={200}
                width={200}
              />
            </NavLink>
          </div>
        </Container>

        <div className="container mt-2">
          <Tab.Container id="tabs-example" defaultActiveKey="tab1">
            <Row className="justify-center">
              <Col sm={12} md={4}>
                <Nav
                  variant="pills"
                  className="flex-column"
                  style={{ background: "white" }}
                >
                  <Nav.Item style={{ border: "1px solid" }}>
                    <Nav.Link
                      eventKey="tab1"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Health
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab2"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Wellness Insurance
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab3"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Critical Illness
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12} md={4}>
                <Nav
                  variant="pills"
                  className="flex-column"
                  style={{ background: "white" }}
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab4"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Hospicash
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab5"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Personal Accidental Coverage
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab6"
                      style={{ border: "1px solid grey" }}
                      className="mb-3"
                    >
                      Finance
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12} md={12} className="wrapper">
                <Tab.Content>
                  <Tab.Pane eventKey="tab1">
                    <Row className="justify-center">
                      <Col sm={12} md={6}>
                        <div className="mb-8">
                          <NavLink to="/Why buy Health Insurance?">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210449012-why-buy-halth-insura.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Why buy Health Insurance?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                        <div className="mb-8">
                          <NavLink to="/Is Your Health Coverage Keeping Up with Your Needs">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210540332-is-your-health-cover.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Is Your Health Coverage Keeping Up
                                with Your Needs?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                        <div className="mb-8">
                          <NavLink to="/How to select right coverage for your health insurance">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210671878-how-to-select-right-.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                How to select right coverage for your
                                health insurance ?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="mb-8">
                          <NavLink to="/Understanding Cashless Health Insurance">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210309631-understanding-cashle.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Understanding Cashless Health
                                Insurance?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                        <div className="mb-8">
                          <NavLink to="/Your complete health insurance package">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210575769-your-complete-health.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Your complete health insurance
                                package?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                        <div className="mb-8">
                          <NavLink to="/Add On for your health Insurance">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210723317-add-ons-for-your-hea.jpg"
                                alt="health"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Add On for your health Insurance ?
                              </h2>
                              <h6>Health</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    <Row className="justify-center">
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Rising demand of wellness plans">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210802915-rising-demands-of-we.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Rising demand of wellness plans
                              </h2>
                              <h6>Wellness Insurance</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Holistic Wellness: Embracing Total Well-Being with Eazr">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698210879599-holistic-wellness.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                {" "}
                                Holistic Wellness: Embracing Total
                                Well-Being with Eazr
                              </h2>
                              <h6>Wellness Insurance</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab3">
                    <Row className="justify-center">
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Critical illness you can’t ignore">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698211076571-critical-illness-you.jpg"
                                alt="illness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Critical illness you can’t ignore
                              </h2>
                              <h6>Critical illness</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    <Row className="justify-center">
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/What's hospicash">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698211446277-what-s-hospicash.jpg"
                                alt="illness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                What's hospicash ?
                              </h2>
                              <h6>Hospicash</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab5">
                    <Row className="justify-center">
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Rising Accidents in India">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698211821737-rising-accidents-in-.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Rising Accidents in India
                              </h2>
                              <h6>Personal Accidental Coverage</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/What’s in? Personal Accident">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698212442811-what-s-in-personal-a.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                What’s in? Personal Accident
                              </h2>
                              <h6>Personal Accidental Coverage</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab6">
                    <Row className="justify-center">
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Tax benifits of health insurance">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698222399553-tax-benefits-of-heal.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Tax benifits of health insurance
                              </h2>
                              <h6>Finance</h6>
                            </div>
                          </NavLink>
                        </div>

                        <div className="mb-8">
                          <NavLink to="/Insure Now, Pay Later">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698222621734-insure-now--pay-late.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Insure Now, Pay Later
                              </h2>
                              <h6>Finance</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-8">
                          <NavLink to="/Cash Crunch During Hospitalization">
                            <div className="mb-2">
                              <img
                                src="https://eazr.s3.ap-south-1.amazonaws.com/1698222526106-cash-crunch---during.jpg"
                                alt="wellness"
                                className="rounded-xl"
                              />
                            </div>
                            <div>
                              <h2 className="mb-2 text_18 font-bold">
                                Cash Crunch? During Hospitalization
                              </h2>
                              <h6>Finance</h6>
                            </div>
                          </NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </>
    );
};

export default NewBlogPage;
