import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const Fd = () => {
    // const { name } = useParams();
    const [yearlyInvestment, setYearlyInvestment] = useState(100000);
    const [timePeriod, setTimePeriod] = useState(5);
    const [interestRate, setInterestRate] = useState(6.5);
    const [investedAmount, setInvestedAmount] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [maturityValue, setMaturityValue] = useState(0);
    // chart 

    const data = {
        labels: [
            'Invested amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [investedAmount, totalInterest,interestRate],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculatePpf = () => {
        if (yearlyInvestment > 0 && timePeriod > 0 && interestRate > 0) {
            const interestRateDecimal = interestRate / 100.0;
            let totalInvestment = 0;
            let calculatedTotalInterest = 0;

            for (let year = 1; year <= timePeriod; year++) {
                const annualInterest = (totalInvestment + Number(yearlyInvestment)) * interestRateDecimal;
                totalInvestment += Number(yearlyInvestment);
                calculatedTotalInterest += annualInterest;
            }

            const calculatedMaturityValue = yearlyInvestment + calculatedTotalInterest;
            console.log(calculatedMaturityValue, totalInvestment, calculatedTotalInterest, typeof (yearlyInvestment), timePeriod)
            setInvestedAmount(yearlyInvestment);
            setTotalInterest(calculatedTotalInterest);
            setMaturityValue(calculatedMaturityValue);
        }
    };

    useEffect(() => {
        calculatePpf()

    }, [yearlyInvestment, timePeriod])

    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>FD Calculator</h2>
                <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col items-center'>
                            <div className='w-100'>
                                <label for="loanamount" >
                                    <div className="flex justify-between items-center">
                                        <p className="w-60">Total Investment (&#8377;):</p>
                                        <Form.Control
                                            type="number"
                                            value={yearlyInvestment}
                                            id="loanamount"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setYearlyInvestment(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="1000000"
                                            step="40000"
                                            value={yearlyInvestment}
                                            onChange={(e) => setYearlyInvestment(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="interestRate">
                                    <div className="flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Rate of Interest (p.a)</p>
                                        <Form.Control
                                            type="number"
                                            value={interestRate}
                                            id="interestRate"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="interestRate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="15"
                                            step="0.1"
                                            value={interestRate}
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="timePeriod">
                                    <div className="w-full flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Time Period (Years):</p>
                                        <Form.Control
                                            type="number"
                                            value={timePeriod}
                                            id="timePeriod"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="25"
                                            step="1"
                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />

                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-auto'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {yearlyInvestment && <div className='m-auto'>Total Investment: {yearlyInvestment}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: ₹{totalInterest.toFixed(2)}</div>}
                    {maturityValue && <div className='m-auto'>Maturity Value: {maturityValue}</div>} </div>

                <div>
                    <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a Fixed Deposit (FD) Calculator?</h1>
                    <p className='text__16 leading-7 mb-2'>A Fixed Deposit (FD) calculator is a financial tool or online application that helps individuals calculate the potential returns and interest earned on their fixed deposits. Fixed deposits are a popular form of savings or investment account offered by banks and financial institutions. They offer a fixed interest rate for a predetermined period, making them a relatively low-risk investment option</p>
                    <p className='text__16 leading-7 mb-2'>Fixed Deposit calculators are valuable tools for individuals who want to plan and project the growth of their investments in fixed deposits. They help users make informed decisions about the amount to invest, the tenure, and the choice of bank or financial institution for their fixed deposits</p>
                    <h1 className='text__24 mb-2'>How to Calculate the Maturity Amount of a Fixed Deposit?</h1>
                    <p> Your fixed deposit maturity amount depends upon the amount invested, interest rate, and FD tenure. With the help of the Navi Fixed Deposit Calculator, you can calculate the maturity amount in seconds.
                        <br /> <br />
                        Here’s how to calculate your FD maturity amount
                   
                    </p>
                    <ul style={{listStyleType:"disc"}}>
                            <li> Select the amount to be invested (deposit amount)</li>
                            <p>Choose the amount you want to invest with the help of a slide bar.</p>
                            <li>Select the interest rate</li>
                            <p>Move the slide bar to select the interest rate and also select the compounding period for interest, i.e., monthly, quarterly, or yearly</p>
                            <li>Select the FD tenure</li>
                            <p>Use the slide bar to choose the FD tenure of your choice.</p>
                        </ul>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default Fd