import React, { useState, useEffect } from 'react';
import './Maincss.css'; // Import your CSS file

function Main() {
    const sections = ['What is an Unsecured Loan?', 'How Does an Unsecured Loan Work?', 'What are the Types of Unsecured Loan?', 'How Does an Unsecured Loan Work', 'What are the Types of Unsecured Loan'];
const content = {
  'What is an Unsecured Loan?': 'Content for Section 1 hiii',
  'How Does an Unsecured Loan Work?': 'Content for Section 2 byy',
  'What are the Types of Unsecured Loan?': 'Content for Section 3 sss',
  'How Does an Unsecured Loan Work': 'Content for Section 4 byy',
  'What are the Types of Unsecured Loan': 'Content for Section 5 sss',
};
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = sections.map(section => ({
        id: section,
        offset: document.getElementById(section).offsetTop,
      }));

      const scrollPosition = window.scrollY;

      for (let i = sectionOffsets.length - 1; i >= 0; i--) {
        if (scrollPosition >= sectionOffsets[i].offset) {
          setActiveSection(sectionOffsets[i].id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
<>
    <div className="scroll-spy-container">
      <div className="scroll-spy-sidebar">
        <ul>
          {sections.map(section => (
            <li key={section}>
              <a href={`#${section}`} className={section === activeSection ? 'active' : ''}>
                {section}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="scroll-spy-content"> 
        {sections.map(section => (
          <div key={section} id={section} className='contentdiv'>
            {content[section]}
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default Main;
