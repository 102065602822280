import {legacy_createStore as createStore,compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../Redux/Reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer,persistStore} from 'redux-persist';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key:'root',
    storage
}

const persistedReducer = persistReducer(persistConfig,rootReducer);
export const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk))

)

export const persistor = persistStore(store);