import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PopularCalculator from "../PopularCalculator/PopularCalculator";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const CompoundInterest = () => {
  const [principalAmount, setPrincipalAmount] = useState(1000);
  const [roi, setRoi] = useState(6);
  const [timePeriod, setTimePeriod] = useState(5);
  const [compoundFrequency, setCompoundFrequency] = useState("yearly");
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  // chart

  const data = {
    labels: ["Total Interest ", "Principle Amount"],
    datasets: [
      {
        label: "Eazr",
        data: [totalInterest, principalAmount],
        backgroundColor: [
          "rgb(174, 230, 255)",
          "rgb(13, 75, 218)",
          // 'rgb(255, 205, 86)'
        ],
        hoverOffset: 4,
      },
    ],
  };

  //calculation
  const calculateCompoundInterest = () => {
    const principal = parseFloat(principalAmount);
    const rate = parseFloat(roi) / 100; // Convert rate to decimal
    const time = parseFloat(timePeriod);
    const n = getCompoundFrequency();

    const compoundInterest =
      principal * Math.pow(1 + rate / n, n * time) - principal;
    const totalValueAmt = principal + compoundInterest;
    setTotalInterest(compoundInterest.toFixed(2));
    setTotalValue(totalValueAmt.toFixed(2));
  };

  useEffect(() => {
    calculateCompoundInterest();
  }, [principalAmount, roi, timePeriod, compoundFrequency]);

  const getCompoundFrequency = () => {
    switch (compoundFrequency) {
      case "yearly":
        return 1;
      case "half-yearly":
        return 2;
      case "quarterly":
        return 4;
      default:
        return 1;
    }
  };
  return (
    <section className="py-6">
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'> Compound Interest Calculator</h2>
        <p className='text__16 mb-3'>Unlock the power of compounding with our Compound Interest Calculator. Whether you're an investor aiming to maximize returns or a borrower wanting to understand the cost of borrowing, this tool deciphers the impact of compound interest on your finances.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Indicate the principal amount (The initial sum of money invested or borrowed).<br />
          2. Set the rate of interest (Annual interest rate in percentage terms).<br />
          3. Choose the compounding frequency (How often the interest is compounded: annually, semi-annually, quarterly, monthly, etc.).<br />
          4. Define the time duration (The total period for which the interest will compound, usually in years). <br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=" w-full sm:w-1/2 p-3 py-4 h-max">
            <div className="flex flex-col">
              <div className='row w-full mb-5'>
                <label for="loanamount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Total Investment:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={principalAmount}
                      id="loanamount"
                      min="1000"
                      onChange={(e) => setPrincipalAmount(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      name="points"
                      className="form-range"
                      min="0"
                      max="1000000"
                      step="2000"
                      value={principalAmount}
                      onChange={(e) => setPrincipalAmount(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="intersetrate">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Expected Return (p.a):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={roi}
                      id="intersetrate"
                      min="0"
                      onChange={(e) => setRoi(e.target.value)}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <input
                      type="range"
                      id="intersetrate"
                      name="points"
                      className="form-range"
                      min="0"
                      max="40"
                      step="0.5"
                      value={roi}
                      onChange={(e) => setRoi(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="loantenure">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Time Period (Years):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={timePeriod}
                      id="loantenure"
                      min="0"
                      onChange={(e) => setTimePeriod(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loantenure"
                      name="points"
                      className="form-range"
                      min="0"
                      max="200"
                      step="1"
                      value={timePeriod}
                      onChange={(e) => setTimePeriod(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="loantenure">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Compound Frequency:</p>

                    <div
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                    ><select
                      value={compoundFrequency}
                      onChange={(e) => setCompoundFrequency(e.target.value)}
                    >
                        <option value="yearly">Yearly</option>
                        <option value="half-yearly">Half-Yearly</option>
                        <option value="quarterly">Quarterly</option>
                      </select>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className="h-80 piechart">
              <Pie data={data} />
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {principalAmount && (
            <div className="m-auto"> Total Investment: {principalAmount}</div>
          )}
          {totalInterest && (
            <div className="m-auto">Total Interest: {totalInterest}</div>
          )}
          {totalValue && (
            <div className="m-auto">Total Amount : {totalValue}</div>
          )}{" "}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Strategic Planning: Visualize the impact of compounding on your investments or loans, aiding in strategic financial planning.<br />
            2. Scenario Analysis: Play around with various parameters to understand best-case and worst-case outcomes.<br />
            3. Quick & Accurate: Say farewell to tedious manual computations, ensuring precision and efficiency. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  );
};

export default CompoundInterest;
