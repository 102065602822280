// YourApp.js
import React, { useRef } from "react";
import ScrollSpy from "./ScrollSpy";
import "./LoanSyndication.css";

const LoanSyndication = () => {
  const headingRefs = [
    { id: "section1", text: "What is an Consumer Loan?", ref: useRef() },
    { id: "section2", text: "What Are LoanSyndication Loans?", ref: useRef() },
    {
      id: "section3",
      text: "How Does the LoanSyndication Loan Work?",
      ref: useRef(),
    },
    {
      id: "section4",
      text: "Features & Benefits of LoanSyndicationble Personal Loans",
      ref: useRef(),
    },
    {
      id: "section5",
      text: "Features & Benefits of LoanSyndicationble Personal Loans",
      ref: useRef(),
    },
    // {
    //   id: "section6",
    //   text: "Features & Benefits of LoanSyndicationble Personal Loans",
    //   ref: useRef(),
    // },
  ];

  return (
    <div className="container1">
      <div className="scrolldiv">
        <ScrollSpy headings={headingRefs} />
      </div>
      <div className="contentdiv">
        <div className="content">
          <section id="section1" ref={headingRefs[0].ref} className="sections">
            <h2 className="font-semibold text__20">
              What is an Unsecured Loan?
            </h2>
            <p>
              A LoanSyndication loan is a special type of personal loan with a
              pre-approved limit set by the lender from which you can withdraw
              money as and when you need it. The limit amount is set initially
              by the lender based on your credit profile. A LoanSyndication
              personal loan helps meet an immediate need for money during an
              emergency.
            </p>
          </section>
          <section id="section2" ref={headingRefs[1].ref} className="sections">
            <h2 className="font-semibold text__20">
              What Are LoanSyndication Loans?
            </h2>
            <p>
              A flexi loan is a type of personal loan with a pre-set credit
              limit. Depending on your credit profile, the lender will offer a
              pre-approved cash limit in the form of a credit line or overdraft
              facility. LoanSyndication loan interest rate is charged only on
              the amount used by the borrower and not on the entire sanctioned
              amount.
            </p>
            <p>
              You can repay the principal amount as and when you like. However,
              you need to clear the interest every month. You may also prepay
              the loan whenever you have surplus money in hand. Any salaried or
              self-employed individual can get a LoanSyndication loan and use
              the amount to meet any financial needs.
            </p>
          </section>
          <section id="section3" ref={headingRefs[2].ref} className="sections">
            <h2 className="font-semibold text__20">
              How Does the LoanSyndication Loan Work?
            </h2>
            <p>
              The LoanSyndication loan works in a manner similar to overdraft
              facilities. Once you apply for a LoanSyndication personal loan,
              the lender pre-approves a loan amount based on your repayment
              capacity, income, credit score, etc. Money will be disbursed to
              your account without any added documentation or formalities.
            </p>
            <p>
              After that, you can withdraw money within this credit limit for
              whatever reason and as many times as you want. Interest is charged
              on the amount that you spend. No interest is charged on the
              remaining loan amount. Whether you are self-employed or salaried,
              you can opt for a LoanSyndication loan.
            </p>

            <p>
              5. Credit Card Loan Credit cards are usually unsecured. So, if you
              are in need of urgent cash, you can get loans against your credit
              card. These are usually pre-approved loans and may come with a
              higher interest rate than personal loans. However, banks offer
              credit card loans to select credit cardholders depending on their
              payment history, credit score, and more.
            </p>
          </section>
          <section id="section4" ref={headingRefs[3].ref} className="sections">
            <h2 className="font-semibold text__20">
              Feature and Benefits of Unsecured Loan
            </h2>
            <p>
              LoanSyndicationble loan or LoanSyndication loan has several
              advantages over personal or any other type of loan which are
              discussed here:
            </p>
            <h1>1. Funds Available at All Times</h1>
            <p>
              LoanSyndicationble loans will help you sail through any financial
              emergency requiring immediate cash outflow. Once the
              LoanSyndication loan limit is approved, you can withdraw money
              anytime you want and in as many tranches as you wish.
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
            </p>
          </section>
          <section id="section5" ref={headingRefs[4].ref} className="sections">
            <h2 className="font-semibold text__20">
              Feature and Benefits of Unsecured Loan
            </h2>
            <p>
              LoanSyndicationble loan or LoanSyndication loan has several
              advantages over personal or any other type of loan which are
              discussed here:
            </p>
            <h1>1. Funds Available at All Times</h1>
            <p>
              LoanSyndicationble loans will help you sail through any financial
              emergency requiring immediate cash outflow. Once the
              LoanSyndication loan limit is approved, you can withdraw money
              anytime you want and in as many tranches as you wish.
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
            </p>
          </section>
          {/* <section id="section6" ref={headingRefs[5].ref} className="sections">
            <h2 className="font-semibold text__20">
              Feature and Benefits of Unsecured Loan
            </h2>
            <p>
              LoanSyndicationble loan or LoanSyndication loan has several
              advantages over personal or any other type of loan which are
              discussed here:
            </p>
            <h1>1. Funds Available at All Times</h1>
            <p>
              LoanSyndicationble loans will help you sail through any financial
              emergency requiring immediate cash outflow. Once the
              LoanSyndication loan limit is approved, you can withdraw money
              anytime you want and in as many tranches as you wish.
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
            </p>
          </section> */}
          {/* <section id="section7" ref={headingRefs[6].ref} className="sections">
            <h2 className="font-semibold text__20">
              Feature and Benefits of Unsecured Loan
            </h2>
            <p>
              LoanSyndicationble loan or LoanSyndication loan has several advantages over personal
              or any other type of loan which are discussed here:
            </p>
            <h1>1. Funds Available at All Times</h1>
            <p>
              LoanSyndicationble loans will help you sail through any financial emergency
              requiring immediate cash outflow. Once the LoanSyndication loan limit is
              approved, you can withdraw money anytime you want and in as many
              tranches as you wish.
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
              <p>
                The interest rate on unsecured loans starts from 9.9% p.a and
                goes up to 45% p.a., which depends upon your credit score and
                credit history. Here are some of the other fees and charges:{" "}
              </p>
            </p>
          </section> */}
        </div>
      </div>
    </div>
  );
};

export default LoanSyndication;
