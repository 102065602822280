import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SecContact from '../Components/Pages/SecContact'
import SecCreate from '../Components/Pages/SecCreate'

const Faq = () => {
    const [ToogleFaq, setToogleFaq] = useState(0)

    const setToogleFaqWrap = (e) => {
        if (e == ToogleFaq) {
            setToogleFaq(-1)
        } else {
            setToogleFaq(e)
        }
    }

    /// Accordian

    const dataUsedFeatures = [
        {
            title: "What is Eazr Pay Later?",
            desc: (<>
                <p>Eazr Pay Later is a bi-monthly credit facility of upto Rs. 10,000 every cycle that is available for select registered users of Eazr. This provides a seamless one click payment experience to make purchases/payments on Eazr or its merchants/partners and repay the dues with upto 15 days of credit period. This credit facility is extended by our lending partners.</p>
            </>)
        },
        {
            title: "How Does Eazr Work?",
            desc: (<>
                <p>Eazr lets you transact with a single tap on various websites and apps. All your purchases get added into one convenient bill, which you pay in one go. The bills are generated every two weeks – on the 15th, and on the 30th/31st.<br /> <br /> Unlike cards and net banking, you can skip multiple verifications and repeated OTPs. <br /> Unlike wallets, you aren’t forced to refill first. So no more Rs. 50 in one wallet, and Rs. 15 stuck in another. <br /> <br />Just one payment totalling all the orders you make online.</p>
            </>)
        },
        {
            title: "How Does Eazr's Billing Cycle Work?",
            desc: (<>
                <p>Your Eazr bill is generated twice a month. <br /> <br /> All your transactions between 1st and 15th are added into one bill, which is generated on the 15th. <br /> <br /> All transactions made between 16th and 30th/31st are added into one bill, which is generated on, you guessed it, 30th/31st.<br /> <br /> Of course, you can choose to pay for your transactions even before your bill is generated. You can do so by logging into your account via our website or app.</p>
            </>)
        },
        {
            title: "How Do I Deactivate My Account?",
            desc: (<>
                <p> We’re really sorry that you want to stop using Eazr. You may write us at deactivate@eazr.in to deactivate your Eazr account.</p>
            </>)
        },
        {
            title: "Where Can I Find Eazr's Terms And Conditions?",
            desc: (<>
                <p>"The links to our Terms and Privacy Policy are below:<br /><br /> Terms and Conditions:  https://itseazr.com/terms-conditions <br /> <br /> Privacy Policy:  https://itseazr.com/privacy-policy</p>
            </>)
        },
        {
            title: "How Is Eazr safer than other digital payments ?",
            desc: (<>
                <p>When you buy online, you have to carefully enter all your details every time. Sadly, this complicated verification process only gives you the illusion of security. In reality, the chances of fraud go up every time you share information over the internet.<br /> <br /> With Eazr, your payment details are never passed to the merchant. By drastically reducing the number of times you give out your banking data online, we drastically reduce the chances of fraud!</p>
            </>)
        },
        {
            title: "How Can I Set Up A Bill For Auto Debit?",
            desc: (<>
                <p> You can set up a bill for Auto Debit by tapping on “Auto Debit” from bill settings. If you have bill which is already generated, we will pay the bill before turning on Autopay. This is to ensure that the bill is paid on time. </p>
            </>)
        },
        {
            title: "Can I Manually Pay A Bill after setting up Auto Debit?",
            desc: (<>
                <p>Yes You Can. If your bill has already been paid, we will not autopay your bill. Only bills that have a pending amount will be auto paid.</p>
            </>)
        },
        {
            title: "What Happens When My Auto Debit Fails?",
            desc: (<>
                <p>In case we are unable to autopay your bill due to any reason, we will notify you via a Push Notification and SMS. You can also check the status of your biller by visiting the payments section.
                </p>
            </>)
        },
        {
            title: "Can I Disable/Cancel Auto Debit?",
            desc: (<>
                <p>You can disable Autopay for any bill anytime by visiting payment settings viz, Settings Disable Auto Debit  Confirm</p>
            </>)
        },
        {
            title: "When Do I Pay My Eazr Bill?",
            desc: (<>
                <p>Let’s take the month of October as an example. <br /> <br />  All transactions from the 1st to the 15th will be recorded in one bill, which is generated on the 15th evening. You’ll have to settle it within 5 days(20th October).<br /> <br />   Transactions from the 16th to the 31st will be recorded in the next bill, which is generated on the 31st(last day of the month). Again, you’ll have to settle it within 5 days(5th November).</p>
            </>)
        },
        {
            title: "How Do I Pay My Eazr Bill?",
            desc: (<>
                <p>You can repay the amount by clicking on repayment links which can be accessed by following ways: <br /> <br /> 1. Select the ‘Clear Due’ tab available on the home page itseazr.com. Enter your registered details and OTP received. Click on Pay Now.<br /> <br />  2. Select ‘Login’ tab on home page and enter your registered credentials. Confirm the OTP received and Select ‘Pay Now’ tab on your dashboard.<br /> <br />  3. Simply click on the tab “Pay now” sent to you on your registered email ID/SMS. 4. Download – Eazr App Login into the app with the registered mobile number, click on activity tab at the bottom, click on the ‘Clear Dues’ option available on the home screen. Pay via Credit Card (non-KYC user), Debit Card, UPI and Net Banking before the 5th/20th of each month.</p>
            </>)

        },
        {
            title: "My Online Payment Just Failed. What To Do?",
            desc: (<>
                <p>Sorry you’ve had to face this! Payment failures happen due to temporary outages on the bank’s side. The best solution is to just try again after some time. <br /> <br />  In case you’ve made more than 3 payment attempts at different times of the day, and it still failed, do send a message and let us know. We’ll pause all further action, and check with the bank right away.</p>
            </>)
        },
        {
            title: "What will happen if I do not repay on time?",
            desc: (<>
                <p>Please repay your Eazr Pay Later dues on Due Date in order to get the Pay Later balance for the next billing cycle and to continue using Eazr Pay Later. Delayed repayment will attract Late Fee and might impact your CIBIL score.</p>
            </>)
        },
        {
            title: "Where can I use Eazr Pay Later?",
            desc: (<>
                <p>You can use Eazr both online and in-store along with the payments on the Eazr platform e,g, Mobile, DTH, Electricity, Landline, Broadband etc. and to pay at Merchants where Eazr Pay Later is accepted.</p>
            </>)
        },
        {
            title: "How Can I Increase My Spending Limit?",
            desc: (<> <p>Your spending limit is initially set by default. But once you start using Eazr, the limit is constantly updated based on a few major factors.<br /> <br /> How regularly you repay: Regular and prompt repayment is the most important factor in updating your spending limit. If you’re quick and consistent in settling your dues with Eazr, your spending limit increases over time. Needless to say, consistently failing to do so will have the opposite effect. <br /> <br /> Your spending habits: The spending limit will mirror your purchasing habits. If your spending increases steadily, the limit will go up too. <br /> <br /> Using the Eazr app: The Eazr app lets us carry out further verifications based on data available on the phone. For example, based on your transactional SMS messages, Eazr can increase your spending limit to match your overall spending.<br /> <br /> The strength of your profile: A strong profile allows us to verify your identity, and get to know you better. Eazr uses this information to increase your spending limit. </p> </>)
        },
        {
            title: "Why Am I Being Charged A Late Fee?",
            desc: (<>
                <p>We do have late penalties in case of delayed payments. We have explained the basic late fee slabs for your quick reference below: <br /> <br /> Late Fee Slabs : <br /> <br /> Bill upto Rs. 100 – Late Fee of Rs. 10 <br /> Bill from Rs. 100 to Rs. 499 – Late Fee of Rs. 50 <br /> Bill from Rs. 500 to Rs. 1,999 – Late Fee of Rs. 100 <br /> Bill from Rs. 2000 to Rs. 4,999 – Late Fee of Rs. 150 <br /> Bill from Rs. 5000 to Rs. 9,999 – Late Fee of Rs. 300 <br /> <br /> A GST of 18% will be applied on the above mentioned amounts. <br /> <br /> The late is fee is applied on the 6th and 21st of every month if the amount is not cleared within 5 days of bill generation. <br /> <br /> Note: If the bill remains pending for 20 days from bill generation then a second late fee will be applied.</p>
            </>)
        },
        {
            title: "Why has my Eazr Credit limit reduced?",
            desc: (<>
                <p>Eazr determines your spending limit based on your usage, which includes your repayment behaviour. If your spending limit has reduced, it’s mostly due to repeated delayed payments to Eazr. If you think there’s been a mistake, you can contact credit@eazr.in or drop a message to us with your phone number linked with Eazr.</p>
            </>)
        },
        {
            title: "How Long Do Refunds Take On Eazr?",
            desc: (<>
                <p>Just like paying with Eazr, refunds on Eazr are instant. In case you haven’t received a refund, it’s probably because it hasn’t been initiated by the merchant yet. <br /> <br /> If you were promised a refund and it still isn’t showing up on your Eazr account, drop us a message along with the transaction details and we’ll contact the merchant and have it resolved for you.</p>
            </>)
        },
        {
            title: "What is bank bounce charge?",
            desc: (<>
                <p>It is your responsibility to ensure that you maintain sufficient funds in your bank account to cover your due amount.<br /> <br /> When the bank account you have linked for auto repayment doesn’t have sufficient funds in order to get the auto debit done successfully, we pay a penalty to your bank for this failed attempt. We add this to your statement. Bounce Charge is fixed at Rs 350 per bill cycle</p>
            </>)
        },

    ]

    const [ToogleAccordion, setToogleAccordion] = useState(0)

    const setToogleAccordionWrap = (e) => {
        if (e == ToogleAccordion) {
            setToogleAccordion(-1)
        } else {
            setToogleAccordion(e)
        }
    }


    //   const dataTeam = [
    //     {
    //       img: './../images/pr (4).png',
    //       name: "Nolan Korsgaard",
    //       job: "Founder & Owners",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (5).png',
    //       name: "Jaylon Vaccaro",
    //       job: "Founder & Owners",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (6).png',
    //       name: "Ryan Gouse",
    //       job: "Founder & Owners",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (7).png',
    //       name: "Cooper Saris",
    //       job: "Engineering Manager",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (8).png',
    //       name: "Marcus Philips",
    //       job: "Software Engineer",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (1).png',
    //       name: "Brandon Herwitz",
    //       job: "Tech Engineering Manager",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (2).png',
    //       name: "Miracle Dorwart",
    //       job: "Product Designer",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //     {
    //       img: './../images/pr (3).png',
    //       name: "Miracle Siphron",
    //       job: "Content",
    //       facebook: "#!",
    //       linkedin: "#!",
    //       twitter: "#!"
    //     },
    //   ]
    return (
        <Fragment>
            <section className='bg-Mbg'>
                <Container>
                    <div className="mb-10">
                        <h3 className='text__48 font-medium mb-2'>Need Help</h3>
                        <p className='text__18 text-m'>If you have questions that are not listed here, send <br className='hidden sm:block' /> them to us via <p className=' inline text-Mpurple'>email.</p></p>
                    </div>

                    <div className="flex flex-wrap gap-3">
                        {
                            dataUsedFeatures.map((obj, i) => {
                                return <Fragment>
                                    <div className='bg-white px-3 rounded-[20px] overflow-hidden w-full'>
                                        <div className="py-3 flex justify-between items-center cursor-pointer  " onClick={() => setToogleFaqWrap(i)}>
                                            <h5 className='font-medium text__24'>{obj.title}</h5>
                                            <div className={"arrowPlus relative w-[24px] h-[24px] " + (ToogleFaq == i ? "active" : "")}>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className={ToogleFaq == i ? "block pb-3" : "hidden"}>
                                            <p className='text__18'>{obj.desc}</p>
                                        </div>
                                    </div>
                                </Fragment>
                            })
                        }
                    </div>
                </Container>
            </section>
            {/* <section>
        <Container>
          <Row className='gap-y-6'>
            <Col className='my-auto' md={6}>
              <img src="./../images/sdfgsdgsdg.png" alt="" />
            </Col>
            <Col className='m-auto' md={10}>
              <h3 className='text__48 font-medium mb-2 text-center'>Need Help ?</h3>
              <p className='text__16 text-Mgray mb-10 text-center'>Documentation and Web Compliance has to be fulfilled to activate payment services.</p>

              <div className="flex flex-wrap gap-3">
                {
                  dataUsedFeatures.map((obj, i) => {
                    return <div className={"p-4 rounded-2xl w-full cursor-pointer " + (ToogleAccordion == i ? "bg-[#FAFAFA]" : "border border-solid border-[#F5F5F5]")} onClick={() => setToogleAccordionWrap(i)}>
                      <div className="flex items-center justify-between">
                        <h5 className='font-medium text__20'>{obj.title}</h5>
                        <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion == i ? "rotate-180" : "")} alt="" />
                      </div>
                      <div className={"py-2 " + (ToogleAccordion == i ? "block" : "hidden")}>
                        <p className='text__16'>{obj.desc}</p>
                      </div>
                    </div>
                  })
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

            <SecContact />

      

        </Fragment>
    )
}

export default Faq
