import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const NscCalculator = () => {
    const [amountInvested, setAmountInvested] = useState(1000);
    const [rateOfInterest, setRateOfInterest] = useState(6);
    const [timePeriod, setTimePeriod] = useState(5);
    const [compoundFrequency, setCompoundFrequency] = useState('yearly');
    const [totalInvestment, setTotalInvestment] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [maturityAmount, setMaturityAmount] = useState(0);
    // chart 

    const data = {
        labels: [
            'Principle Amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [amountInvested, maturityAmount],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculateNSC = () => {
        const principal = parseFloat(amountInvested);
        const rate = parseFloat(rateOfInterest) / 100; // Convert rate to decimal
        const years = parseFloat(timePeriod);
        const n = getCompoundFrequency();

        // Calculate total investment
        const totalInvestmentAmt = principal;

        // Calculate total amount
        const totalAmountAmt = principal * Math.pow(1 + rate / n, n * years);

        // Calculate maturity amount
        const maturityAmountAmt = totalAmountAmt - principal;

        setTotalInvestment(totalInvestmentAmt.toFixed(2));
        setTotalAmount(totalAmountAmt.toFixed(2));
        setMaturityAmount(maturityAmountAmt.toFixed(2));
    };

    const getCompoundFrequency = () => {
        switch (compoundFrequency) {
            case 'yearly':
                return 1;
            case 'half-yearly':
                return 2;
            default:
                return 1;
        }
    };

    useEffect(() => {
        calculateNSC()

    }, [amountInvested, rateOfInterest, timePeriod])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>NSC Calculator</h2>
                <p className='text__16 mb-3'>Investing in the National Savings Certificate? Our NSC Calculator aids you in deciphering the maturity amount and interest earned on your investment, ensuring you have a comprehensive view of this popular small savings instrument offered by the Indian Post Office.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Indicate your investment amount (The sum you're planning to invest in NSC).<br />
                    2. Choose the type of NSC (VIII Issue for 5 years or IX Issue, if available).<br />
                    3. Set the duration of investment (Typically 5 years for the VIII Issue).<br />
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount" >
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Amount Invested (&#8377;):</p>
                                        <input
                                            type="number"
                                            id="amountInvested"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={amountInvested}
                                            onChange={(e) => setAmountInvested(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="1000000"
                                            step="10000"
                                            value={amountInvested}
                                            onChange={(e) => setAmountInvested(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Rate of Interest (p.a):</p>
                                        <input
                                            type="number"
                                            id="rateOfInterest"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={rateOfInterest}
                                            onChange={(e) => setRateOfInterest(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="45"
                                            step="0.5"
                                            value={rateOfInterest}
                                            onChange={(e) => setRateOfInterest(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="loantenure">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Time Period (years):</p>
                                        <input
                                            type="number"
                                            id="timePeriod"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'

                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="72"
                                            step="1"
                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>

                    {totalInvestment && (
                        <div className="m-auto">Total Investment: {totalInvestment}</div>
                    )}
                    {maturityAmount && (
                        <div className="m-auto"> Total Interest: {maturityAmount}</div>
                    )}
                    {totalAmount && (
                        <div className="m-auto">Total Amount : {totalAmount}</div>
                    )}{" "}
                </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Investment Planning: Gain clarity on potential returns to fine-tune your investment strategies. <br />
                        2. Time-Saving: Swiftly calculate returns without engaging in manual computations.<br />
                        3. Informed Decision Making: Understand how NSC fares as an investment option in comparison to other instruments.
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default NscCalculator