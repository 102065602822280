import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const Pension = () => {
    const [currentAge, setCurrentAge] = useState(20);
    const [contributionPerMonth, setContributionPerMonth] = useState(1000);
    const [expectedReturn, setExpectedReturn] = useState(10);
    const [purchaseAnnuity, setPurchaseAnnuity] = useState(true);
    const [annuityDuration, setAnnuityDuration] = useState(10);
    const [expectedAnnuityRate, setExpectedAnnuityRate] = useState(6);
    const [monthlyExpectedPension, setMonthlyExpectedPension] = useState(0);
    const [annuityValue, setAnnuityValue] = useState(0);
    const [lumpsumValue, setLumpsumValue] = useState(0);
    const [annuityPercentage,setAnnuityPercentage] = useState(50)
    // chart 

    const data = {
        labels: [
            'LumpSum',
            'Annuity Value',
        ],
        datasets: [{
            label: 'Eazr',
            data: [lumpsumValue,annuityValue],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
 
  
    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
  
      if (type === 'checkbox') {
        setPurchaseAnnuity(checked);
      } else {
        if (name === 'currentAge') setCurrentAge(value);
        else if (name === 'contributionPerMonth') setContributionPerMonth(value);
        else if (name === 'expectedReturn') setExpectedReturn(value);
        else if (name === 'annuityDuration') setAnnuityDuration(value);
        else if (name === 'expectedAnnuityRate') setExpectedAnnuityRate(value);
      }
    };
  
    const calculateSavings = () => {
        const monthlyContribution = contributionPerMonth * 12;
        const annualReturn = expectedReturn / 100;
        const totalContribution = monthlyContribution * (purchaseAnnuity - currentAge);
        const futureValue = totalContribution * ((1 + annualReturn) ** (annuityDuration - (purchaseAnnuity - currentAge)));
        const monthlyExpectedPension = futureValue / (annuityDuration * 12);
    
        // Calculate Annuity Value
        const annuityValue = monthlyExpectedPension / (expectedAnnuityRate / 100);
    
        // Calculate Lumpsum Value
        const lumpsumValue = futureValue - (monthlyExpectedPension * (annuityDuration * 12));
    
        setMonthlyExpectedPension(monthlyExpectedPension.toFixed(2));
        setAnnuityValue(annuityValue.toFixed(2));
        setLumpsumValue(lumpsumValue.toFixed(2));
    }    
  


    useEffect(() => {
        calculateSavings()

    }, [currentAge,contributionPerMonth,expectedAnnuityRate,expectedReturn,annuityDuration])
    console.log(lumpsumValue,annuityValue)
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>Pension Calculator</h2>
                <div className='flex flex-wrap mb-2 container w-100'>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col items-center'>
                            <div className='w-100'>
                                <label for="currentAge">
                                    <div className="flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Current Age (years):</p>
                                        <Form.Control
                                                type="text"
                                                id="currentAge"
                                                className='w-50'
                                                name="currentAge"
                                                value={currentAge}
                                                onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="currentAge"
                                            name="points"
                                            className='form-range w-100'
                                            min="0"
                                            max="100"
                                            step="1"
                                            value={currentAge}
                                            onChange={(e) => setCurrentAge(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="contributionPerMonth">
                                    <div className="w-full flex justify-between items-center">
                                    <p className="w-40 sm:w-60">Contribution Per Month ($):</p>
                                        <Form.Control
                                               type="text"
                                               id="contributionPerMonth"
                                               className='w-50 '
                                               name="contributionPerMonth"
                                               value={contributionPerMonth}
                                               onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="contributionPerMonth"
                                            name="points"
                                            className='form-range w-100 '
                                            min="0"
                                            max="100000"
                                            step="1000"
                                            value={contributionPerMonth}
                                            onChange={(e) => setContributionPerMonth(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="expectedReturn">
                                    <div className="flex justify-between">
                                    <p className="w-40 sm:w-60">Expected Return (% p.a):</p>
                                        <Form.Control
                                                 type="text"
                                                 id="expectedReturn"
                                                 className='w-50'
                                                 name="expectedReturn"
                                                 value={expectedReturn}
                                                 onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="expectedReturn"
                                            name="points"
                                            className='form-range w-100'
                                            min="0"
                                            max="30"
                                            step="1"
                                            value={expectedReturn}
                                            onChange={(e) => setExpectedReturn(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br/>
                            {/* <div>
                                <label for="expectedReturn">
                                    <div className="flex justify-between">
                                    Purchase Annuity:
                                        <Form.Control
                                              type="checkbox"
                                              name="purchaseAnnuity"
                                              checked={purchaseAnnuity}
                                              onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="25"
                                            value={loantenure}
                                            onChange={(e) => setloantenure(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div> */}
                            {/* <br/> */}
                            <div className='w-100'> 
                                <label for="annuityDuration">
                                    <div className="flex justify-between items-center">
                                   <p className="w-40 sm:w-60">Annuity Duration (years):</p> 
                                        <Form.Control
                                            type="text"
                                            id="annuityDuration"
                                            className='w-50'
                                            name="annuityDuration"
                                            value={annuityDuration}
                                            onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="annuityDuration"
                                            name="points"
                                            className='form-range w-100'
                                            min="0"
                                            max="30"
                                            step="1"
                                            value={annuityDuration}
                                            onChange={(e) => setAnnuityDuration(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br/>
                            <div className='w-100'>
                                <label for="expectedAnnuityRate">
                                    <div className="flex justify-between items-center">
                                    <p className="w-40 sm:w-60">Expected Annuity Rate (p.a):</p>
                                        <Form.Control
                                               type="text"
                                               id="expectedAnnuityRate"
                                               className='w-50'
                                               name="expectedAnnuityRate"
                                               value={expectedAnnuityRate}
                                               onChange={handleInputChange}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="expectedAnnuityRate"
                                            name="points"
                                            className='form-range w-100'
                                            min="0"
                                            max="20"
                                            step="1"
                                            value={expectedAnnuityRate}
                                            onChange={(e) => setExpectedAnnuityRate(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-auto'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {monthlyExpectedPension && <div className='m-auto'>Monthly Expected Pension: {monthlyExpectedPension}</div>}
                    {annuityValue && <div className='m-auto'>Annuity Value: {annuityValue}</div>}
                    {lumpsumValue && <div className='m-auto'>Lumpsum Value: {lumpsumValue}</div>}
                     </div> 

                <div>
                    <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a Pension Calculator?</h1>
                    <p className='text__16 leading-7 mb-2'>A pension calculator is a financial tool or online application designed to help individuals estimate their retirement income and plan for their financial future. It assists individuals in determining how much money they need to save or invest in order to achieve their desired retirement goals. Pension calculators are particularly useful for retirement planning, as they provide individuals with valuable insights into their financial preparedness for retirement.</p>
                    <p className='text__16 leading-7'>Pension calculators are valuable tools for individuals of all ages who want to plan for a financially secure retirement. They empower individuals to make informed decisions about saving, investing, and spending in order to achieve their retirement goals</p>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default Pension