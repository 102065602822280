import React from "react";
// import cn from "classnames";
// import styles from "./Tooltip.module.sass";
// import Icon from "../Icon";
import './Tooltipcss.css'

const Tooltip = ({ className, title, icon, place }) => {  
  return (
    <div className="tooltip">
      <span data-tip={title} data-place={place}>
        {/* <Icon name={icon} /> */}
      </span>
    </div>
  );
};

export default Tooltip;
