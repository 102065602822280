import React from "react";
// import styles from "./Statements.module.sass";
// import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "../Statements/Overview/index";
import TooltipGlodal from "../TooltipGlodal";
import Transactions from "./Transactions";

const Statements = () => {
  return (
    <>
      <div>
        <Overview />

        <Transactions />
      </div>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default Statements;
