import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"

const PfCalculator = () => {
    let [monthlySalary, setMonthlySalary] = useState(10000);
    let [currentAge, setCurrentAge] = useState(25);
    let [retirementAge, setRetirementAge] = useState(60);
    let [yourContribution, setYourContribution] = useState(12);
    let [employerContribution,setEmployerContribution] = useState(0)
    let [annualIncrement, setAnnualIncrement] = useState(10);
    let [interestRate, setInterestRate] = useState(8.15);
    let [maturityAmount, setMaturityAmount] = useState(0);
    let [timeDuration, setTimeDuration] = useState(0);
    let [totalContribution, setTotalContribution] = useState(0);
    // chart 

    // const data = {
    //     labels: [
    //         'principle amount',
    //         'Total Interest',
    //     ],
    //     datasets: [{
    //         label: 'Eazr',
    //         data: [monthlySalary, totalInterest],
    //         backgroundColor: [
    //             'rgb(174, 230, 255)',
    //             'rgb(13, 75, 218)',
    //             // 'rgb(255, 205, 86)'
    //         ],
    //         // hoverOffset: 1
    //     }]
    // }

    //calculation 
    const calculateEPF = () => {
        let salary = parseFloat(monthlySalary);
        let age = parseInt(currentAge);
        let retirement = parseInt(retirementAge);
        let yourContrib = parseFloat(yourContribution);
        let employerContrib = parseFloat(employerContribution);
        let annualInc = parseFloat(annualIncrement);
        let rateOfInterest = parseFloat(interestRate);
    
        let totalContribution = 0;
        let balance = 0;
        let maturityAmount = 0;
        let timeDuration = 0;
    
        for (let i = age; i < retirement; i++) {
          // Calculate annual contribution
          const annualYourContribution = (yourContrib / 100) * salary * 12;
          const annualEmployerContribution = (employerContrib / 100) * salary * 12;
    
          // Update balance
          balance += annualYourContribution + annualEmployerContribution;
          balance += (balance * rateOfInterest) / 1200; // Monthly compounding
    
          // Calculate maturity amount at retirement age
          if (i === retirement - 1) {
            maturityAmount = balance;
          }
    
          totalContribution += annualYourContribution + annualEmployerContribution;
    
          // Apply annual increment
          yourContrib += (yourContrib * annualInc) / 100;
        }
    
        setTotalContribution(totalContribution.toFixed(2));
        setMaturityAmount(maturityAmount.toFixed(2));
        setTimeDuration(retirement - age);
      };


    useEffect(() => {
        calculateEPF()

    }, [monthlySalary, currentAge, retirementAge, yourContribution, annualIncrement, interestRate])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>PF Calculator</h2>
                <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col items-center'>
                            <div>
                                <label for="monthlySalary">
                                    <div className="flex justify-between">
                                        <p>Monthly Salary (Basic + DA)</p>
                                        <Form.Control
                                            type="number"
                                            value={monthlySalary}
                                            id="monthlySalary"
                                            min="1000"
                                            onChange={(e) => setMonthlySalary(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="monthlySalary"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="2000000"
                                            value={monthlySalary}
                                            onChange={(e) => setMonthlySalary(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div>
                                <label for="currentAge">
                                    <div className="w-full flex justify-between ">
                                        Current Age:
                                        <Form.Control
                                            type="number"
                                            value={currentAge}
                                            id="currentAge"
                                            min="0"
                                            onChange={(e) => setCurrentAge(parseInt(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="currentAge"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={currentAge}
                                            onChange={(e) => setCurrentAge(parseInt(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div>
                                <label for="retirementAge">
                                    <div className="w-full flex justify-between ">
                                        Retirement Age:
                                        <Form.Control
                                            type="number"
                                            value={retirementAge}
                                            id="retirementAge"
                                            min="0"
                                            onChange={(e) => setRetirementAge(parseInt(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="retirementAge"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={retirementAge}
                                            onChange={(e) => setRetirementAge(parseInt(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div>
                                <label for="yourContribution">
                                    <div className="w-full flex justify-between ">
                                        Your Contribution to EPF (%):
                                        <Form.Control
                                            type="number"
                                            value={yourContribution}
                                            id="yourContribution"
                                            min="0"
                                            onChange={(e) => setYourContribution(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="yourContribution"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={yourContribution}
                                            onChange={(e) => setYourContribution(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div>
                                <label for="annualIncrement">
                                    <div className="w-full flex justify-between ">
                                        Average Annual Increment (%):
                                        <Form.Control
                                            type="number"
                                            value={annualIncrement}
                                            id="annualIncrement"
                                            min="0"
                                            onChange={(e) => setAnnualIncrement(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="annualIncrement"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={annualIncrement}
                                            onChange={(e) => setAnnualIncrement(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div>
                                <label for="interestRate">
                                    <div className="flex justify-between">
                                        Current Rate of Interest (%):
                                        <Form.Control
                                            type="number"
                                            value={interestRate}
                                            id="interestRate"
                                            min="0"
                                            onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="interestRate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="25"
                                            value={interestRate}
                                            onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        {/* <div className='h-auto'><Pie data={data} /></div> */}
                        <p>Total Contribution: {totalContribution}</p>
                        <p>Maturity Amount: {maturityAmount}</p>
                        <p>Time Duration: {timeDuration} years</p>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {/* {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: {totalInterest}</div>}
                    {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount}</div>}*/} </div>

                <div>
                    <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a Personal Loan EMI Calculator?</h1>
                    <p className='text__16 leading-7 mb-2'>A personal monthlySalary EMI calculator helps you calculate your personal monthlySalary EMI (Equated Monthly Instalment) obligations. Calculating your EMI beforehand can help you plan your monthlySalary and manage your finances in a better way.</p>
                    <p className='text__16 leading-7'>Navi’s personal monthlySalary EMI calculator carries a user-friendly design and is extremely easy to use. All you need to do is just select the monthlySalary amount you wish to borrow, interest rate and tenure. The online calculator will give you the EMI details in just a few seconds.</p>
                </div>
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default PfCalculator