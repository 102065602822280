import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const ApyCalculator = () => {
  const [joiningAge, setJoiningAge] = useState(25);
  const [desiredPension, setDesiredPension] = useState(1000);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);
  const [investmentDuration, setInvestmentDuration] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  // chart 

  

  //calculation 
  const calculateAPY = () => {
    const retirementAge = 60; // APY assumes retirement at joiningAge 60
    const remainingYears = retirementAge - joiningAge;
    const monthsToInvest = remainingYears * 12;
    const annualPension = desiredPension * 12;
    const monthlyInvestment = annualPension / ((1 + 0.08) ** monthsToInvest - 1) * (1 + 0.08);
    const investmentDuration = remainingYears;
    const totalAmount = monthlyInvestment * 12 * monthsToInvest;

    setMonthlyInvestment(monthlyInvestment.toFixed(2));
    setInvestmentDuration(investmentDuration);
    setTotalAmount(totalAmount.toFixed(2));
  };


  useEffect(() => {
    calculateAPY()
  }, [joiningAge,desiredPension])
  return (
    <section >
    <Container>
      <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>Atal Pension Yojana Calculator</h2>
      <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
        <div className=' w-full sm:w-1/2 p-3 h-max h-[60vh]'>
          <div className='flex flex-col items-center'>
            <div>
              <label for="joiningAge">
                <div className="row">
                  <p className='col-6'>Your Joining Age:</p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={joiningAge}
                    id="joiningAge"
                    min="1000"
                    step="1000"
                    onChange={(e) => setJoiningAge(parseInt(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    id="joiningAge"
                    className='form-range'
                    name="points"
                    min="0"
                    max="1000000"
                    step="200000"
                    value={joiningAge}
                    onChange={(e) => setJoiningAge(parseInt(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
            <br />
       
            <div>
              <label for="desiredPension">
                <div className="row">
                 <p className='col-6'> Desired Monthly Pension:</p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={desiredPension}
                    id="desiredPension"
                    max="5"
                    min="5"
                    onChange={(e) => setDesiredPension(parseInt(e.target.value) || 0)}
                  />{" "}
                </div>
              <div className="text-center">
                  <input
                    type="range"
                    id="desiredPension"
                    className='form-range'
                    name="points"
                    min="0"
                    max="200"
                    step="25"
                    value={desiredPension}
                    onChange={(e) => setDesiredPension(parseInt(e.target.value) || 0)}
                  />
                </div> 
              </label>
            </div>
          </div>

        </div>
        <div className=' w-full sm:w-1/2 p-3 h-max flex justify-center text-center items-center'>
          {/* <div className='h-auto'><Pie data={data}/></div> */}
          {monthlyInvestment && <div className='text__24 leading-9'>Monthly Investment: <br/> <span className='font-bold text__32'>{monthlyInvestment}</span></div>}
          {/* {duration && <div className='text__24 leading-9'>Duration: <br/> <span className='font-bold text__32'>{duration} Years</span></div>} */}
          {totalAmount && <div className='text__24 leading-9'>Total Amount: <br/> <span className='font-bold text__32'>{totalAmount}</span></div>}
          
          </div>
      
      </div>

    
      <div>
        <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is ApyCalculator Calculator</h1>
        <p className='text__16 leading-7 mb-2'>A ApyCalculator Calculator is a financial tool or application that helps individuals and employers calculate the ApyCalculator amount that is payable to an employee upon their retirement or resignation from a job. ApyCalculator is a form of financial reward or compensation provided by an employer to an employee for their long-term service and is typically governed by labor laws or employment regulations in many countries.</p>
        <p className='text__16 leading-7'>In the Union Budget 2023, the deposit limit for individual accounts was increased from ₹4.5 Lakh to ₹9 Lakh. Similarly, the deposit limit for joint accounts was raised to ₹15 Lakh from ₹9 Lakh. Until 31st March 2023, the savings scheme is offering returns at 7.1% to its investors. However, if the interest paid every month is not claimed by a depositor, such an amount will not earn any additional interest. </p>
      </div>
      <PopularCalculator />
    </Container>
  </section>  
  )
}

export default ApyCalculator