import React from "react";
// import cn from "classnames";
import "./Overviewcss.css";
// import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../Card";
// import Icon from "../../../components/Icon";
import Tooltip from "../../Tooltip/index";
import TooltipGlodal from "../../TooltipGlodal";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { FiActivity } from "react-icons/fi";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { PiContactlessPaymentFill } from "react-icons/pi";

const Overview = () => {
  const { user } = useSelector((state) => state.otp);

  const items = [
    {
      title: "Available Credit Limit",
      counter: `₹ ${
        user?.data?.availableCreditLimit == undefined
          ? 0
          : user?.data?.availableCreditLimit
      }`,
      icon: <FiActivity />,
      color: "#B5E4CA",
      tooltip: "Small description Funds",
    },
    {
      title: "Total Dues",
      counter: `₹ ${
        user?.data?.currentOutstandingAmount == undefined
          ? 0
          : user?.data?.currentOutstandingAmount
      }`,
      icon: <FaBalanceScaleLeft />,
      color: "#CABDFF",
      tooltip: "Small description Earning",
    },
    {
      title: "Pay Now",
      counter: "₹ 0",
      icon: <PiContactlessPaymentFill />,
      color: "#B1E5FC",
      tooltip: "Small description Fees",
    },
  ];
  return (
    <>
      <Card className="card">
        <div>
          <div className="list">
            {items.map((x, index) => (
              <div className="item" key={index}>
                <div className="icon" style={{ backgroundColor: x.color }}>
                  {/* <Icon name="icon" size="24" /> */}
                  <p className="text-2xl">{x.icon}</p>
                </div>
                <div className="details">
                  <div className="label">
                    {x.title}
                    <Tooltip
                      className="tooltip"
                      title={x.tooltip}
                      icon="info"
                      place="top"
                    />
                  </div>
                  <div className="counter">{x.counter}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
