import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";

const ProtectedRouter = () => {
  const [access, setAccess] = useState(localStorage.getItem("accessToken"));
  const navigate = useNavigate();

  useEffect(() => {
    // setAccess(localStorage.getItem("accessToken"));
    if (access) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [access]);

  // Redirect to home if not authenticated

  return <Dashboard />;
};

export default ProtectedRouter;
