// ScrollSpy.js
import React, { useState, useEffect } from 'react';
import './ScrollSpycss.css'

const ScrollSpy = ({ headings }) => {
  const [activeHeading, setActiveHeading] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      // const scrollY = window.scrollY;

      // Determine the active heading based on scroll position
    //   const newActiveHeading = headings.find(({ ref }) => {
    //     const { offsetTop, offsetHeight } = ref.current;
    //     return scrollY >= offsetTop - 100 && scrollY < offsetTop + offsetHeight - 100;
    //   });

    //   if (newActiveHeading !== activeHeading) {
    //     setActiveHeading(newActiveHeading);
    //   }
    // };

    // Attach the scroll event listener when the component mounts
    // window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [activeHeading, headings]);


  // New useEffect

  const scrollY = window.scrollY + 50; // Apply an offset
      const active = headings.find((heading) => {
        const element = document.getElementById(heading.id);
        if (element) {
          const elementTop = element.offsetTop;
          const elementBottom = elementTop + element.clientHeight;
          return scrollY >= elementTop && scrollY < elementBottom;
        }
        return false;
      });

      // Update the active heading
      if (active && active.id !== activeHeading) {
        setActiveHeading(active.id);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeHeading, headings]);

  

  return (

  <div className="scrollcontainer">
    <h2>Table Of Content :</h2>
    <hr/>
    <ul className='scrollspy'>
    {headings.map((heading) => (
          <li key={heading.id}>
            <a
              href={`#${heading.id}`}
              className={heading.id === activeHeading ? 'active' : ''}
            >
              {heading.text}
            </a>
          </li>
        ))}
    </ul>
    </div>
  );
};

export default ScrollSpy;
