import React from 'react'

const Quickloan = () => {
  return (
    <section>
    <div><h2>Quickloan</h2></div>
    </section>
  )
}

export default Quickloan