import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { RightArrowIcon } from '../Components/Icon/Icon'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const DetailOrder = () => {
    const [phone, setPhone] = useState("1")
    return (
        <section className='bg-Mbg'>
            <Container>
                <div className="w-full p-3 sm:!p-6 bg-white rounded-[24px]">
                    <Row className='gap-y-6'>
                        <Col md={7} lg={8}>
                            <div className="flex items-center gap-1 sm:gap-2 text__16">
                                <span className='text-[#A3A3A3]'>Ticket</span>
                                <RightArrowIcon width={24} height={24} color='#A3A3A3' />
                                <span className='text-black'>Detail Order</span>
                                <RightArrowIcon width={24} height={24} color='#171717' />
                                <span className='text-[#A3A3A3]'>Payment Method</span>
                            </div>

                            <h4 className='font-medium text__24 my-4'>Detail Order</h4>

                            <Form.Group className='mb-4'>
                                <Form.Label className="text__14 text-[#A3A3A3]">Email</Form.Label>
                                <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                    <Form.Control type="email" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="Email" />
                                </div>
                            </Form.Group>
                            <Form.Group className='mb-4'>
                                <Form.Label className="text__14 text-[#A3A3A3]">Full Name</Form.Label>
                                <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                    <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="Full Name" />
                                </div>
                            </Form.Group>
                            <Row>
                                <Col md={8}>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className="text__14 text-[#A3A3A3]">Phone Number</Form.Label>
                                        <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                            <div className="inline-block">
                                                <div className="flex items-center bg-white px-[12px] py-[6px] rounded-full">
                                                    <PhoneInput
                                                        className="color__black phoneModFiled"
                                                        country={'us'}
                                                        value={phone}
                                                        onChange={(e) => setPhone(e)}
                                                    />
                                                    <div className="ml-[2rem]">+{phone}</div>
                                                </div>
                                            </div>
                                            <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="Phone Number" />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className='mb-4'>
                                        <Form.Label className="text__14 text-[#A3A3A3]">Age</Form.Label>
                                        <div className="flex items-center gap-2 bg-[#FAFAFA] border border-solid border-[#F5F5F5] px-3 rounded-[16px] w-full">
                                            <Form.Control type="text" className='bg-transparent outline-none border-none shadow-none focus:shadow-none focus:bg-transparent focus:outline-none focus:border-none text__14 !text-black placeholder-[#A3A3A3] h-[54px] px-0 w-full' placeholder="18" />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>


                        </Col>
                        <Col md={5} lg={4}>
                            <div className="w-full p-4 border border-solid border-[#F5F5F5] rounded-[20px]">
                                <h4 className='font-medium text__20 mb-2'>September Perform 2023 (Standing - Early Bird)</h4>
                                <p className='text__14 text-Mgray mb-4'>Wed 24 Sep 2023 - Tue 25 Sep 2023</p>

                                <div className="flex flex-wrap gap-3 mb-6">
                                    <div className="w-full flex items-center justify-between">
                                        <p className='text__16 text-Mgray'>Total Price</p>
                                        <h5 className='font-semibold text__18'>$20</h5>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <p className='text__16 text-Mgray'>Tax Platform</p>
                                        <h5 className='font-semibold text__18'>-</h5>
                                    </div>
                                    <hr className='!border-[#E5E5E5] my-0 w-full' />
                                    <div className="w-full flex items-center justify-between">
                                        <p className='text__16 text-Mgray'>Order Total</p>
                                        <h5 className='font-semibold text__18'>$20</h5>
                                    </div>
                                </div>

                                <NavLink to="/order/detail/payment" className='font-medium text__16 inline-block cursor-pointer font-medium text__14 text-white btnClass !py-[16px] bg-Mpurple w-full text-center'>Next</NavLink>

                                <h5 className='text__18 mb-2 mt-4'>Share this event:</h5>
                                <div className="flex items-center gap-2">
                                    <a href="#!">
                                        <img src="./../images/sos (1).svg" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/sos (2).svg" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/sos (3).svg" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/sos (4).svg" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/sos (5).svg" alt="" />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>
            </Container>
        </section>
    )
}

export default DetailOrder
