import React from 'react'
import { Fragment } from 'react'
import SecContact from '../Components/Pages/SecContact'

const Contact = () => {
    return (
        <Fragment>
            <SecContact />
        </Fragment>
    )
}

export default Contact
