import {
  SET_CONTACT_NUMBER,
  CLEAN_UP_SIGN_IN,
  USER_OTP_SENT,
  OTP_SENT_FAILED,
  LOGIN_USER_LOADING,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESSFUL,
  SET_CURRENT_USER,
  SET_TOKEN,
  REMOVE_TOKEN,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_FAILED,
  GET_SEARCH_TRANSACTION,
  GET_SEARCH_TRANSACTION_FAILED,
  DOWNLOAD_TRANSACTION,
  CLEAN_UP_TRANSACTION,
} from "./ActionType";
import axios from "axios";
import { ip } from "../../config/config";
import setAuthToken from "../../utils/setAuthToken";
import moment from "moment-timezone";
// import jwt_decode from "jwt-decode";
const setLoginLoading = () => {
  return {
    type: LOGIN_USER_LOADING,
  };
};

export const loginUserSuccessful = (user) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
  };
};

export const sendOtp = (contactNumber, history) => {
  return (dispatch) => {
    dispatch(setLoginLoading());
    axios
      .post(`${ip}/users/send-otp`, { contactNumber })
      .then((res) => {
        debugger;
        dispatch({
          type: USER_OTP_SENT,
          payload: res,
        });
        history(`/auth/${contactNumber}`);
      })
      .catch((err) => {
        debugger;
        dispatch({
          type: OTP_SENT_FAILED,
          payload: err.response,
        });
      });
  };
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: { decoded },
  };
};
export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setContactNumbers = (contactNumber) => {
  return {
    type: SET_CONTACT_NUMBER,
    payload: contactNumber,
  };
};

export const UserVerify = (contactNumber, otp, history) => async (dispatch) => {
  try {
    const res = await axios.post(`${ip}/users/verify-otp`, {
      contactNumber,
      otp,
    });

    if (res.data !== null) {
      console.log("res>>>", res?.data);
      const { accessToken } = res?.data?.data;
      localStorage.setItem("accessToken", accessToken);
      dispatch(setToken(accessToken));
      setAuthToken(accessToken);
      // const decoded = jwt_decode(token);
      dispatch(loginUserSuccessful(res?.data));
      if (accessToken) {
        // window.location.href = 'http://localhost:3001';
        history("/dashboard");
      } else {
        history("/");
      }
    }
  } catch (error) {
    debugger;
    console.log(error);
    dispatch({
      type: LOGIN_USER_FAILED,
      payload: error?.response,
    });
  }
};

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
};

export const getTransaction = (id, pageNumber, selectedDate) => {
  return (dispatch) => {
    dispatch(setLoginLoading());
    axios
      .get(
        `${ip}/transactions?filter=userId||eq||${id}&page=${pageNumber}&limit=10&sort=id,DESC`
        // `${ip}/transactions?filter=userId||eq||${id}||createdTimestamp||between||${endDate}||${startDate}&page=${pageNumber}&limit=10&sort=id,DESC`
        // `${ip}/transactions?filter=userId||$eq||${id}||&filter=createdTimestamp||$gte||${startDate}||&filter=createdTimestamp||$lte||${endDate}||&page=${pageNumber}&limit=10&sort=id,DESC`
      )
      .then((res) => {
        console.log(res.data);
        debugger;
        dispatch({
          type: GET_TRANSACTIONS,
          payload: res.data,
        });
        // history(`/auth/${contactNumber}`);
      })
      .catch((err) => {
        debugger;
        dispatch({
          type: GET_TRANSACTIONS_FAILED,
          payload: err.response?.data?.message,
        });
      });
  };
};

export const cleanup_transaction = () => {
  return {
    type: CLEAN_UP_TRANSACTION,
  };
};

export const downloadTransaction = (id, selectedDate) => {
  console.log(selectedDate, ">>>checkdate");

  const currentDate = new Date();

  // const istTimeString = moment(currentDate)
  //   .tz("Asia/Kolkata")
  //   .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  // console.log(istTimeString, "Curre");

  // Subtract 30 days
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - (selectedDate || 15));

  // Convert to ISO string

  const startDate = thirtyDaysAgo.toISOString();
  // const startDate = initialDate.setHours(0, 0, 0, 0);
  console.log(startDate, "Start");
  console.log(currentDate, "CurrentDate");
  const endDate = currentDate.toISOString();
  console.log(endDate, "EndDate");
  return (dispatch) => {
    dispatch(setLoginLoading());
    axios
      .get(
        // `${ip}/transactions?filter=userId||eq||${id}&page=${pageNumber}&limit=10&sort=id,DESC`
        // `${ip}/transactions?filter=userId||eq||${id}||createdTimestamp||between||${endDate}||${startDate}&page=${pageNumber}&limit=10&sort=id,DESC`
        `${ip}/transactions?filter=userId||$eq||${id}||&filter=createdTimestamp||$gte||${startDate}||&filter=createdTimestamp||$lte||${endDate}||&sort=id,DESC`
      )
      .then((res) => {
        console.log("from action", res.data);
        debugger;
        dispatch({
          type: DOWNLOAD_TRANSACTION,
          payload: res.data,
        });
        // history(`/auth/${contactNumber}`);
      })
      .catch((err) => {
        debugger;
        dispatch({
          type: GET_TRANSACTIONS_FAILED,
          payload: err?.response?.data?.message,
        });
      });
  };
};

export const getUsersSearchTranscation = (id, search, pageNumber) => {
  return (dispatch) => {
    dispatch(setLoginLoading());
    axios
      .get(
        `${ip}/transactions?s={"$and":
        [{"userId":{"$eq":${id}}},{"partner.businessName":
        {"$contL":"${search}"}}]}&page=${pageNumber}&limit=10&sort=id,DESC`
      )
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_SEARCH_TRANSACTION,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SEARCH_TRANSACTION_FAILED,
          payload: err.response.data,
        });
      });
  };
};

export const cleanUpSignIn = () => {
  return {
    type: CLEAN_UP_SIGN_IN,
  };
};
