import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const NpsCalculator = () => {
  const [investedPerMonth, setInvestedPerMonth] = useState(10000);
  const [expectedReturn, setExpectedReturn] = useState(9);
  const [age, setAge] = useState(20);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [interestEarned, setInterestEarned] = useState(0);
  const [maturityAmount, setMaturityAmount] = useState(0);
  const [minAnnuityInvestment, setMinAnnuityInvestment] = useState(0);
  // console.log(investedPerMonth,annualInterestRate,investmentPeriod,expectedReturn,maturityAmount,totalInvestment,totalInterest)
  // chart 

  const data = {
    labels: [
      'Investment Amount',
      'Total Interest',
    ],
    datasets: [{
      label: 'Eazr',
      data: [investedPerMonth, interestEarned],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateNPS = () => {
    const yearsToInvest = 60 - age; // Assuming retirement age is 60
    const totalInvestment = investedPerMonth * 12 * yearsToInvest;
    setTotalInvestment(totalInvestment);

    const interestEarned = (totalInvestment * expectedReturn) / 100;
    setInterestEarned(interestEarned);

    const maturityAmount = totalInvestment + interestEarned;
    setMaturityAmount(maturityAmount);

    const minAnnuityInvestment = maturityAmount / 20; // Assuming annuity investment is 5% of the maturity amount
    setMinAnnuityInvestment(minAnnuityInvestment);
  };

  useEffect(() => {
    calculateNPS()
  }, [investedPerMonth, expectedReturn, age])

  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>NPS Calculator</h2>
        <p className='text__16 mb-3'>Plan your retirement with precision using our NPS Calculator. Designed to help you understand the potential corpus of your NPS investments, this tool is essential for those looking to secure their golden years.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Specify your monthly NPS contribution (The amount you intend to deposit in the NPS every month). <br />
          2. Adjust the expected annual returns (The average yearly return you anticipate from the NPS investments).<br />
          3. Choose your retirement age (The age at which you plan to retire and start withdrawing from the NPS). <br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="investedPerMonth">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Investemt Per month</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={investedPerMonth}
                      id="investedPerMonth"
                      min="1000"
                      step="1000"
                      onChange={(e) => setInvestedPerMonth(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="investedPerMonth"
                      className='form-range'
                      name="points"
                      min="0"
                      max="1000000"
                      step="2000"
                      value={investedPerMonth}
                      onChange={(e) => setInvestedPerMonth(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="expectedreturn">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Expected return (P.a) :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={expectedReturn}
                      id="expectedreturn"
                      min="0"
                      onChange={(e) => setExpectedReturn(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="expectedreturn"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="0.5"
                      value={expectedReturn}
                      onChange={(e) => setExpectedReturn(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="age">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'> Your age:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={age}
                      id="age"
                      onChange={(e) => setAge(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      className='form-range'
                      id="tenure"
                      name="points"
                      min="0"
                      max="200"
                      step="1"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>

          </div>

        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {totalInvestment && <div className='m-auto'>Total Investment: {totalInvestment}</div>}
          {interestEarned && <div className='m-auto'>Interest Earned: {interestEarned}</div>}
          {maturityAmount && <div className='m-auto'>maturityAmount Amount : {maturityAmount}</div>}
          {minAnnuityInvestment && <div className='m-auto'>Min. Annuity Investment: {minAnnuityInvestment}</div>}
        </div>


        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Retirement Planning: Understand the potential outcomes of your NPS investments and plan your retirement accordingly. <br />
            2. Flexible Forecasts: Experiment with different contribution amounts or expected returns to see how they impact your retirement corpus.<br />
            3. Informed Decision Making: Gain clarity on the NPS as an investment avenue and its benefits over the long term. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default NpsCalculator