import React from 'react'
import { NavLink } from 'react-router-dom'
import './CalFootercss.css'

const CalFooter = () => {
  return (
    <div className='pt-4'>
      <span style={{ color: "#2960de" }} className='font-semibold'>Calculators</span> :
      <div >
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/pesonalloan">Personal Loan EMI Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/postoffice">Post Office MIS Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/homeloan">Home Loan EMI Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/sip">SIP Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/nps">NPS Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/mutualfund">Mutual Fund Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/lumpsum">LumpSum Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/carloan">Car Loan EMI Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/bikeloan">Bike Loan EMI Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/simpleinterest">Simple Interest Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/compoundinterest">Compound Interest Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/gratuity">Gratuity Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/nsc">NSC Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/cagr">CAGR Calculator</NavLink> | </p>
        {/* <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/npv">NPV Calculator</NavLink> | </p> */}
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/roi">ROI Calculator</NavLink> | </p>
        <p className='inline text__14 text-Mlightblack'><NavLink to="calculator/homeloan-prepayment">Home Loan PrePayment Calculator</NavLink></p>

      </div>

      {/* <span style={{color:"#2960de"}} className='font-semibold'>Personal Loan </span> : 
    <div >
    <p className='inline text__14'><NavLink to="loan/quickloan">Quick Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/unsecuredloan">Unsecured Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/flexiloan">Flexi Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/bestpersonalloan">Best Personal Loan App</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/consumerloan">Consumer Durable Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loanagainstlic">Loan Against Lic Policy</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loanrepayment">Loan Repayment</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Loan Syndication</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/peertopeer">Peer To Peer Lending</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Top Up Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Cash Credit Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Emergency Fund</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Loan Against Shares</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">EPF Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Marksheet Loan</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Pashu Kishan Credit Card</NavLink> | </p>
    <p className='inline text__14'><NavLink to="loan/loansyndication">Prime Lending Rate</NavLink> | </p>

    </div> */}
      <div className='py-3'>
        <img src="/images/madeinbharat.svg" alt="madeinbharat" />
      </div>

    </div>
  )
}

export default CalFooter