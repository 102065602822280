import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom'
import PopularBlogs from './PopulorBlogs'

const SingleBlog = () => {

  const data = [
    {
      id: "1",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210449012-why-buy-halth-insura.jpg",
      title: " Add On for your health Insurance ",
      category: "Health Insurance",
      eventKey: "Why buy Health Insurance",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'> <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> <br />
              <span >Health insurance is a crucial financial tool that provides individuals with a range of benefits and protections. Here are some specific and professional reasons why you should consider buying health insurance:</span></h3>

            <p className='block text-left text__18 mb-3 font-light'>1. Financial Protection: Health insurance helps protect you from the high costs of medical care. Without insurance, a serious illness or injury could lead to substantial medical bills, potentially causing financial ruin. Health insurance ensures that you have coverage for medical expenses, reducing your out-of-pocket costs.</p>

            <p className='block text-left text__18 mb-3 font-light'>2. Access to Quality Healthcare: Health insurance provides you with access to a network of healthcare providers and facilities. You can choose from a variety of doctors, specialists, and hospitals, ensuring you receive quality medical care when needed.</p>

            <p className='block text-left text__18 mb-3 font-light'>3. Preventive Care: Many health insurance plans cover preventive services, such as vaccinations, screenings, and annual check-ups. This encourages early detection of health issues, which can lead to more effective treatment and better outcomes.</p>

            <p className='block text-left text__18 mb-3 font-light' >4. Emergency Care: In emergencies, having health insurance can mean the difference between life and death. It ensures that you can seek immediate medical attention without worrying about the cost. </p>

            <p className='block text-left text__18 mb-3 font-light' >5. Chronic Disease Management: For individuals with chronic health conditions, health insurance is essential. It covers ongoing treatments, medications, and therapies, making it more affordable to manage long-term health issues.</p>

            <p className='block text-left text__18 mb-3 font-light'>6. Prescription Drug Coverage: Health insurance often includes coverage for prescription medications. This can significantly reduce the cost of necessary drugs, improving your ability to manage and treat medical conditions.</p>

            <p className='block text-left text__18 mb-3 font-light' >7. Peace of Mind: Knowing that you have health insurance provides peace of mind. You won't need to constantly worry about the financial implications of unexpected medical expenses.</p>

            <p className='block text-left text__18 mb-3 font-light' >8. Family Coverage: Health insurance plans often offer coverage for family members, ensuring that your loved ones also have access to healthcare services.</p>

            <p className='block text-left text__18 mb-3 font-light' >9. Tax Benefits: In some countries, health insurance premiums may be tax-deductible, providing a financial incentive for obtaining coverage.</p>

            <p className='block text-left text__18 mb-3 font-light' >10. Global Pandemics and Health Crises: The COVID-19 pandemic highlighted the importance of having health insurance. It can protect you during widespread health crises and ensure you receive necessary treatment and care.</p>
            <h3 className='block text-left text__20 mb-3 font-semibold'>Acquiring Health Insurance with Eazr</h3>
            <p className='block text-left text__18 mb-3 font-light '>Forget the days of cumbersome paperwork and long waits to buy health insurance. Eazr simplifies the process:</p>
            <p className='block text-left text__18 mb-3 font-light'>Digital Ease: Eazr's platform facilitates a smooth online journey. Compare policies, comprehend benefits, and make knowledgeable choices—all from home.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Expert Guidance: Confused about the right policy? Eazr offers expert advice, ensuring you get ample coverage at the best rates.</p>
            <p className='block text-left text__18 mb-3 font-light'>Swift and Simple: Eazr is built for efficiency. Their user-friendly platform ensures a quick and uncomplicated insurance acquisition process.</p>
            <p className='block text-left text__18 mb-3 font-light'>In summary, health insurance is a prudent investment in your overall well-being and financial security. It safeguards you against unexpected medical expenses, grants you access to quality healthcare, and supports your physical and financial health in the long run.</p>
            <p className='block text-left text__18 mb-3 font-light'>Platforms like Eazr have made procuring this shield straightforward and accessible. </p>
            <p className='block text-left text__18 mb-3 font-light'>Prioritize your health; get insured today!</p>
          </p>
        </>
      )
    },
    {
      id: "2",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210802915-rising-demands-of-we.jpg",
      title: "Rising demand of wellness plans",
      category: "Health Insurance",
      eventKey: "Rising demand of wellness plans",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'> <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br />
              <span >The rising demand for wellness plans can be attributed to several realistic and professionally relevant factors:</span></h3>

            <p className='block text-left text__18 mb-3 font-light'>1. Focus on Preventive Healthcare: Wellness plans emphasize preventive healthcare measures, such as regular check-ups, vaccinations, and health screenings. This proactive approach can help individuals detect health issues early, reducing the need for costly medical interventions in the long run.</p>

            <p className='block text-left text__18 mb-3 font-light'>2. Rising Healthcare Costs: As healthcare costs continue to rise, individuals are seeking ways to manage their healthcare expenses. Wellness plans often cover preventive services at no additional cost, making them an attractive option for budget-conscious consumers.</p>

            <p className='block text-left text__18 mb-3 font-light'>3. Health Consciousness: There is a growing trend toward healthier lifestyles. People are becoming more health-conscious and are willing to invest in programs that promote their overall well-being. Wellness plans offer resources and incentives to adopt and maintain healthier habits..</p>

            <p className='block text-left text__18 mb-3 font-light' >4. Employer Offerings: Many employers are recognizing the value of wellness plans as part of their employee benefits packages. Offering these plans can lead to a healthier and more productive workforce, reduce absenteeism, and contribute to employee retention. </p>

            <p className='block text-left text__18 mb-3 font-light' >5. Customized Approach: Wellness plans are often tailored to an individual's or a family's specific health needs and goals. This customization allows people to address their unique health concerns and preferences, making the plans more appealing.</p>

            <p className='block text-left text__18 mb-3 font-light'>6. Stress Reduction: Wellness plans often include stress management and mental health components. In an increasingly stressful world, individuals are seeking resources to help them cope with the demands of daily life, both physically and mentally.</p>

            <p className='block text-left text__18 mb-3 font-light' >7. Healthcare Consumerism: Individuals are taking a more active role in their healthcare decisions. Wellness plans empower them to make informed choices about their health, encouraging personal responsibility and engagement.</p>

            <p className='block text-left text__18 mb-3 font-light' >8. Chronic Disease Management: The prevalence of chronic diseases, such as diabetes and heart disease, is increasing. Wellness plans often include support for managing chronic conditions, which can improve individuals' quality of life and reduce the burden on the healthcare system.</p>

            <p className='block text-left text__18 mb-3 font-light' >9. Technology Integration: Many wellness plans leverage technology, such as mobile apps and wearables, to track health metrics, provide health information, and offer guidance. This appeals to tech-savvy consumers who want convenient and data-driven ways to manage their health.</p>

            <p className='block text-left text__18 mb-3 font-light' >10. Insurance Integration: Some wellness plans are integrated with health insurance, offering incentives such as premium discounts, lower deductibles, or cash rewards for meeting wellness goals. This integration makes it financially beneficial for individuals to participate in wellness programs.</p>
            <p className='block text-left text__18 mb-3 font-light '>11. Aging Population: As the population ages, there is a growing demand for services that promote healthy aging and help individuals maintain their independence and quality of life as they get older. Wellness plans often cater to this demographic.</p>
            <p className='block text-left text__18 mb-3 font-light'>12. Pandemic Awareness: The COVID-19 pandemic heightened awareness of the importance of overall health and well-being. People are more motivated to invest in programs that enhance their immune systems and overall health.
            </p>
            <p className='block text-left text__18 mb-3 font-light font-semibold'>Conclusion:</p>
            <p className='block text-left text__18 mb-3 font-light'>In conclusion, the rising demand for wellness plans is driven by a combination of factors, including a desire for preventive healthcare, cost-saving measures, a shift toward healthier lifestyles, and the recognition of the value of wellness programs in promoting physical and mental well-being.</p>
          </p>
        </>
      )
    },
    {
      id: "3",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698222399553-tax-benefits-of-heal.jpg",
      title: "Tax benifits of health insurance",
      category: "Health Insurance",
      eventKey: "Tax benifits of health insurance",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >In today's fast-paced world, the age-old adage, "Health is wealth," holds more significance than ever before. Beyond safeguarding your well-being, health insurance comes with a range of financial perks, and Eazr takes this a step further. Let's explore the tax benefits of health insurance and how Eazr's services can make it a smart investment for your health and finances.</span></h3>
            <h3 className='text-left font-semibold'>Deductions on Premiums Paid:</h3>
            <p className='block text-left text__18 mb-3 font-light'>One of the most significant tax advantages of health insurance is the deduction you can claim on the premiums paid. Eazr offers comprehensive health insurance plans with customizable premium options. Depending on your jurisdiction's regulations, you can often deduct a substantial amount of your premium, effectively reducing your taxable income. Moreover, if you're paying premiums for elderly parents or dependents, Eazr's policies may offer even higher deduction limits, further optimizing your savings.</p>
            <h3 className='text-left font-semibold'>Benefits for Senior Citizens:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Eazr recognizes the unique healthcare needs of senior citizens and tailors its plans accordingly. Many tax systems offer additional benefits to senior citizens, and Eazr ensures that higher deduction limits are set for this demographic. This makes health insurance even more financially attractive for older individuals, providing them with comprehensive coverage and tax advantages.</p>
            <h3 className='text-left font-semibold'>Preventative Health Check-ups:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Eazr's health insurance policies often include free or subsidized preventative health check-ups. These check-ups not only promote early detection and better health outcomes but can also lead to tax deductions in some jurisdictions. You may be eligible for deductions on expenses incurred during these check-ups, up to a specified limit, making proactive healthcare a financially wise choice.</p>
            <h3 className='text-left font-semibold'>Coverage for Specific Diseases:</h3>

            <p className='block text-left text__18 mb-3 font-light' >Certain Eazr health insurance plans provide coverage for specific diseases or critical illnesses, further enhancing your tax benefits. By investing in these plans, you not only secure coverage for serious health conditions but also gain the advantage of claiming deductions, ensuring financial peace of mind during challenging times.</p>
            <h3 className='text-left font-semibold'>Group Health Insurance and Corporate Benefits:</h3>

            <p className='block text-left text__18 mb-3 font-light' >For employees covered under Eazr's group health insurance provided by their employer, there's a dual benefit. The premium paid by the employer is often not considered a taxable perk. Additionally, employees can claim deductions on their contributions, maximizing their tax savings while enjoying comprehensive healthcare coverage. <br /> <br />
              Eazr's primary focus is to shield you against medical emergencies and promote your well-being. However, the tax incentives attached to Eazr's health insurance plans make it an even more financially savvy decision. It's a two-fold benefit: securing your health and optimizing your financial savings. So, when you evaluate your tax-saving options, remember that Eazr's health insurance offers more than just medical coverage—it's a boon for your wallet too!
            </p>
          </p>
        </>
      )
    },
    {
      id: "4",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698222621734-insure-now--pay-late.jpg",
      title: "Insure Now, Pay Later",
      category: "Health Insurance",
      eventKey: "Insure Now, Pay Later",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >The world of insurance is undergoing a dynamic shift, adapting to the demands of modern consumers and their financial habits. A testament to this evolution is the emergence of the "Insure Now, Pay Later" model. At the intersection of convenience and financial flexibility, this approach is redefining how we perceive and engage with insurance. Let’s unpack its nuances and advantages.</span></h3>
            <h3 className='text-left font-semibold'>Understanding "Insure Now, Pay Later"</h3>
            <p className='block text-left text__18 mb-3 font-light'>Much like the popular "Buy Now, Pay Later" options seen in e-commerce, "Insure Now, Pay Later" offers policyholders the chance to immediately secure their insurance cover, with the payment deferred to a later date or structured into manageable installments.</p>
            <h3 className='text-left font-semibold'>The Advantages</h3>

            <p className='block text-left text__18 mb-3 font-light'>Immediate Coverage: In emergencies or sudden realizations of risk exposure, waiting isn't an option. This model ensures that coverage begins immediately, offering immediate peace of mind.</p>

            <p className='block text-left text__18 mb-3 font-light'>Financial Flexibility: For many, chunky upfront premiums can be a deterrent. Deferring payments or breaking them down offers breathing room, making insurance more accessible to a broader audience.</p>

            <p className='block text-left text__18 mb-3 font-light' >Boosted Take-up Rates: By removing initial cost barriers, more people are likely to opt for insurance, thereby increasing overall coverage rates and fostering a culture of protection.</p>

            <p className='block text-left text__18 mb-3 font-light' >Encouraging Comprehensive Policies: With the eased financial burden, individuals might opt for more comprehensive coverage, ensuring they’re better protected against a range of contingencies.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Digital Integration: Typically, "Insure Now, Pay Later" services are integrated with digital platforms, offering users a seamless, user-friendly experience. From policy selection to payment scheduling, it's smooth sailing.</p>
            <p className='block text-left text__18 mb-3 font-light'>Navigating the Caution Points:
              While the advantages are clear, it's essential to exercise prudence. Policyholders should be wary of their financial commitments, ensuring they can manage deferred payments to avoid falling into debt or compromising their coverage.
            </p>
            <h3 className='text-left font-semibold'>Conclusion:</h3>

            <p className='block text-left text__18 mb-3 font-light'>The "Insure Now, Pay Later" model is an exciting progression in the insurance world, aligning with modern financial behaviors and emphasizing inclusivity and accessibility. As with all financial decisions, striking a balance between convenience and responsibility is key. Embrace the flexibility, but always insure with insight! Check your insure now, pay later eligibility with EAZR now!!</p>

          </p>
        </>
      )
    },
    {
      id: "5",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210671878-how-to-select-right-.jpg",
      title: "How to select right coverage for your health insurance",
      category: "Health Insurance",
      eventKey: "How to select right coverage for your health insurance",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> */}
              <br />
              <span >Selecting the right health insurance coverage is a critical decision that requires careful consideration. Here are some professional and realistic steps to help you choose the right coverage:
              </span></h3>
            <p className='block text-left text__18 mb-3 font-light'>1. Assess Your Needs: Begin by evaluating your current health and healthcare needs. Consider your age, medical history, and any ongoing health conditions. This assessment will help you determine the type of coverage that suits your situation.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>2. Understand Plan Types:
              -HMO (Health Maintenance Organization): HMO plans offer lower premiums but require you to choose a primary care physician (PCP) and get referrals to see specialists.
              -PPO (Preferred Provider Organization): PPO plans provide more flexibility but come with higher premiums. You can see any doctor without a referral.
              - EPO (Exclusive Provider Organization): EPO plans are a mix of HMO and PPO, offering a limited network like HMO but without requiring referrals.
              - POS (Point of Service): POS plans combine aspects of HMO and PPO, with a primary care physician and referrals but some out-of-network coverage.

            </p>

            <p className='block text-left text__18 mb-3 font-light'>3. Consider Network Size: Check the network of doctors, specialists, and hospitals included in the plan. Ensure that your preferred healthcare providers are in-network to maximize coverage and minimize out-of-pocket expenses.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >4. Review Coverage and Benefits:
              - Examine what each plan covers, including hospitalization, outpatient care, prescription drugs, preventive services, and maternity care.
              - Pay attention to the cost-sharing features like deductibles, copayments, and coinsurance. Assess how these impact your budget and decide what you can afford.


            </p>

            <p className='block text-left text__18 mb-3 font-light' >5. Out-of-Pocket Costs: Consider the total cost of the plan, which includes premiums, deductibles, and maximum out-of-pocket expenses. Balancing monthly premiums with out-of-pocket costs is crucial.

            </p>
            <p className='block text-left text__18 mb-3 font-light'>6. Prescription Drug Coverage: If you take prescription medications, ensure that the plan covers the drugs you need. Review the formulary to see which medications are included.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>7. Check for Additional Benefits:
              - Look for extra perks, such as wellness programs, telehealth services, or maternity care benefits, which can enhance the value of the plan.

            </p>
            <p className='block text-left text__18 mb-3 font-light'>8. Financial Considerations:
              - Assess your budget and determine how much you can comfortably spend on health insurance premiums.
              - Explore any available subsidies, employer contributions, or government assistance programs that can help reduce costs.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>9. Review Quality Ratings: Consult resources like the National Committee for Quality Assurance (NCQA) or healthcare quality ratings to gauge the quality and customer satisfaction of different insurance providers.</p>

            <p className='block text-left text__18 mb-3 font-light'>10. Consider Future Life Changes:
              - Think about any significant life changes on the horizon, such as marriage, having children, or retirement. Your healthcare needs may evolve, so choose a plan that can accommodate these changes.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>11. Read the Fine Print: Carefully read the plan's terms and conditions, including exclusions, limitations, and any pre-authorization requirements for specific treatments or procedures.</p>
            <p className='block text-left text__18 mb-3 font-light'>12. Compare Multiple Plans: Don't rush into a decision. Compare several health insurance plans, both within and outside the Health Insurance Marketplace (if applicable), to ensure you get the best value for your needs.</p>
            <p className='block text-left text__18 mb-3 font-light'>13. Seek Professional Advice: If you find the process overwhelming, consider consulting with a licensed insurance broker or a healthcare consultant who can provide expert guidance.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>14. Ask Questions: If you have any doubts or need clarification, contact the insurance provider directly to ask questions and seek clarification on any aspects of the policy.</p>
            <p className='block text-left text__18 mb-3 font-light'>15. Review and Reassess Annually: Healthcare needs can change, so it's important to reassess your health insurance coverage annually during the open enrollment period to ensure it still meets your requirements.</p>

            <h3 className='text__18 font-semibold text-left'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Ultimately, selecting the right health insurance coverage is a highly individualized process. It's crucial to take your time, research, and choose a plan that aligns with your health needs, financial situation, and future expectations.</p>

          </p>
        </>
      )
    },

    {
      id: "6",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210540332-is-your-health-cover.jpg",
      title: "Is Your Health Coverage Keeping Up with Your Needs?",
      category: "Health Insurance",
      eventKey: "Is Your Health Coverage Keeping Up with Your Needs?",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'> <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br />
              <span >In a rapidly evolving world, change is the only constant. Just as life progresses, so do our health requirements. And therein lies an essential question - is your health insurance adapting as swiftly as your needs? Let’s take a guided tour with Eazr, a platform dedicated to ensuring your coverage remains as dynamic as your life.
              </span></h3>
            <p className='block text-left text__18 mb-3 font-light'>1. The Changing Landscape of Health:
              From welcoming a new family member to entering a different age bracket, our health needs shift. Diseases once distant on the horizon might suddenly seem closer, and conditions we never anticipated can become realities. This fluid landscape necessitates a reevaluation of coverage.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>2. Eazr: Your Personalized Health Coverage Guide: <br />
              Eazr stands out in its niche, offering a platform that doesn’t just help you find insurance but ensures it aligns perfectly with your evolving requirements. With intuitive prompts and personalized suggestions, Eazr takes the guesswork out of insurance adjustments.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>3. Periodic Assessments with Eazr:
              A standout feature of Eazr is its periodic assessment tool. By regularly prompting users to review their health status and life changes, it nudges them to consider if their current plan is still the best fit.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >4. Comprehensive Coverage Exploration:
              Beyond basic medical coverage, Eazr encourages users to think about holistic health. Be it mental health support, vision care, or dental check-ups, Eazr's expansive database ensures you're covered from all angles.

            </p>

            <p className='block text-left text__18 mb-3 font-light' >5. Community Insights:
              Eazr boasts a community-driven feedback system. By reading real experiences from individuals with similar needs or life phases, you can gauge if your current plan still holds water.

            </p>
            <p className='block text-left text__18 mb-3 font-light'>6. Expert Consultations at Your Fingertips:
              While Eazr’s platform is user-friendly, it recognizes the value of expert intervention. With easy access to insurance experts, you're never alone in your quest for the best coverage.
            </p>

            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Staying insured isn’t just about having a policy; it’s about ensuring that policy walks in tandem with your health journey. With platforms like Eazr, regular reassessments become not just feasible but effortless. Dive in, reevaluate, and ensure that your health coverage is not just active but actively serving you. Because in the realm of health, being proactive is always the best policy.</p>

          </p>
        </>
      )
    },

    {
      id: "7",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698211446277-what-s-hospicash.jpg",
      title: "What's hospicash ?",
      category: "Health Insurance",
      eventKey: "What's hospicash ?",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Amid the vast lexicon of insurance jargon, 'Hospicash' stands out, offering a unique financial cushion during hospitalization. But what exactly is Hospicash, and how does it fit into your health coverage mosaic? Let's dive deep, and with Eazr by our side, navigate the benefits seamlessly.
              </span></h3>
            <h3 className='text-left text__18 mb-2 font-semibold'>Understanding Hospicash: </h3>
            <p className='block text-left text__18 mb-3 font-light'>At its core, Hospicash is a daily hospital cash benefit policy. Instead of directly dealing with specific medical bills, it provides policyholders with a daily fixed sum for each day of hospitalization. This amount can be used to manage incidental expenses, such as travel, food, or even loss of income during one's stay at the hospital.
            </p>
            <h3 className='text-left text__18 mb-2 font-semibold'>Benefits of Hospicash: </h3>

            <p className='block text-left text__18 mb-3 font-light'>Financial Flexibility: Unlike structured claims that go directly to specific treatments, Hospicash provides flexibility. You can use the daily sum as per your discretion, catering to unforeseen hospitalization-related expenses.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Supplementary Support: While your primary health insurance covers significant medical bills, Hospicash fills the gaps, ensuring that auxiliary costs don’t strain your wallet.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >No Bills Required: To avail the daily cash benefit, there's no need to produce detailed medical bills. Just proof of hospitalization suffices, making the process hassle-free.

            </p>

            <p className='block text-left text__18 mb-3 font-light' >Coverage Beyond Boundaries: Hospicash often extends its benefits irrespective of the city or even country of hospitalization, ensuring you're protected globally.

              <h3 className='text-left text__18 font-semibold'>Eazr & Hospicash: Simplifying Selection</h3>

            </p>
            <p className='block text-left text__18 mb-3 font-light'>With numerous Hospicash policies flooding the market, how do you choose the one that aligns with your needs? This is where Eazr shines.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Tailored Suggestions: Eazr's platform assesses your profile and offers Hospicash policies that best suit your needs and budget.

            </p> <p className='block text-left text__18 mb-3 font-light'>Transparent Comparisons: With Eazr, you can juxtapose multiple Hospicash policies, understand their benefits, and gauge their value propositions, all on a single platform..
            </p> <p className='block text-left text__18 mb-3 font-light'>Expert Consultations: If in doubt, Eazr connects you to insurance experts who can guide you through the nuances, ensuring your choice is well-informed.

            </p>

            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Hospicash, with its promise of daily financial support during hospital stays, offers a unique buffer in the world of health insurance. And with platforms like Eazr, understanding, selecting, and benefiting from Hospicash becomes as easy as a click. Dive into the world of informed health insurance choices, because every hospitalization day should be about recovery, not financial worry.</p>

          </p>
        </>
      )
    },
    {
      id: "8",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210540332-is-your-health-cover.jpg",
      title: "Is Your Health Coverage Keeping Up with Your Needs",
      category: "Health Insurance",
      eventKey: "Is Your Health Coverage Keeping Up with Your Needs",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >In a world where immediacy is cherished, the concept of cashless health insurance emerges as a beacon of convenience. It's more than just a policy; it's an embodiment of ease during health crises. Let's delve into this modern insurance wonder and see how Eazr streamlines its benefits for you.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>The Essence of Cashless Health Insurance:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Cashless health insurance enables you to receive medical treatments without directly paying from your pocket at the point of service. Instead, your insurance company coordinates directly with the hospital to settle the bills, provided the hospital is within the insurer's network.
            </p>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>Why Opt for Cashless Health Insurance?</h3>

            <p className='block text-left text__18 mb-3 font-light'>Immediate Care: In emergencies, the last thing one needs is financial formalities. Cashless policies ensure that treatments are not delayed due to payment processes.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Financial Relief: No upfront payment means no immediate strain on your savings. Your focus remains where it should be: on recovery.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Streamlined Process: The bureaucratic process of claim reimbursements is sidestepped. No collecting bills, no lengthy paperwork, just timely medical care.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>How Eazr Elevates Your Cashless Experience:</h3>

            <p className='block text-left text__18 mb-3 font-light' >Expensive Network Exploration: Eazr lists hospitals within your insurer’s network, ensuring you know exactly where you can avail cashless treatments.

            </p>
            <p className='block text-left text__18 mb-3 font-light'>Tailored Policy Recommendations: Based on your preferences, health needs, and location, Eazr suggests cashless health insurance policies that align perfectly with your requirements.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Digital Authorization: With integrated features, Eazr can help expedite pre-authorization requirements for cashless treatments, reducing waiting times.
            </p>   <p className='block text-left text__18 mb-3 font-light'>Transparent Policy Breakdown: Eazr demystifies insurance jargon. Understand your policy's features, benefits, and exclusions with ease, ensuring you’re never caught off-guard.
            </p>   <p className='block text-left text__18 mb-3 font-light'>Expert Assistance: Should you ever be uncertain about your cashless benefits or policy details, Eazr connects you with professionals to guide you through the process.
            </p>

            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Cashless health insurance is revolutionizing how we perceive medical treatments, adding a layer of convenience to the often stressful realm of healthcare. And with platforms like Eazr, the journey from understanding to availing becomes smoother. Dive into the world of cashless health insurance and let Eazr be your compass, ensuring every health challenge is met with expertise and ease.</p>

          </p>
        </>
      )
    },
    {
      id: "9",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210575769-your-complete-health.jpg",
      title: "Your complete health insurance package",
      category: "Health Insurance",
      eventKey: "Your complete health insurance package",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >In today's dynamic healthcare environment, having a health insurance policy isn't merely about medical bill coverage—it's about a comprehensive approach to your well-being. A complete health insurance package addresses varied aspects of health, ensuring you're covered from every angle. And with platforms like Eazr, crafting this full-circle protection becomes an effortless journey.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>Features of a Complete Health Insurance Package:</h3>
            <p className='block text-left text__18 mb-3 font-light'>In-Patient Hospitalization: The foundation of any good health policy, it covers expenses when you're admitted to the hospital.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Out-Patient Treatment: Not all treatments require hospital stays. Out-patient cover ensures consultations, minor surgical procedures, and other day-to-day treatments are included.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Pre and Post Hospitalization: Often overlooked, costs incurred before being hospitalized (diagnostics, medication) and after (rehabilitation, medication) can be substantial. A complete package recognizes and addresses this.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Critical Illness Coverage: Certain diseases, like cancer or stroke, can have monumental treatment costs. This ensures you're financially insulated against such life-altering ailments.

            </p>

            <p className='block text-left text__18 mb-3 font-light' >Wellness Benefits: These address preventive healthcare, like health check-ups, consultations for nutrition, and more.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>Navigating Comprehensive Coverage with Eazr:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Personalized Recommendations: Eazr’s advanced algorithms assess your profile and recommend policies that offer well-rounded protection, aligning with your health needs and financial capacity.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Transparent Comparisons: With Eazr, juxtapose features of multiple policies side-by-side. Understand the extent of coverage, exclusions, and benefits in clear terms.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Add-Ons and Customizations: Want to add dental or vision coverage? Or increase critical illness protection? Eazr’s platform allows easy customization, ensuring your policy truly resonates with 'completeness'.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Expert Guidance: Eazr offers a direct line to insurance experts. Any confusion about what constitutes a comprehensive package for you can be clarified in a consultation.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Seamless Policy Management: From renewals to claim assistance, Eazr ensures your comprehensive package is not just about buying but also about efficient management.
            </p>

            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>A complete health insurance package is not a luxury; in today's world, it's a necessity. It's the assurance that no matter the health challenge, you're adequately shielded. And with Eazr, building this shield becomes a straightforward, informed process. Dive in, and let Eazr be your guide to holistic health coverage, where every aspect of your well-being is given prime importance.</p>

          </p>
        </>
      )
    },
    {
      id: "10",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210879599-holistic-wellness.jpg",
      title: "Holistic Wellness: Embracing Total Well-Being with Eazr",
      category: "Wellness Insurance",
      eventKey: "Holistic Wellness: Embracing Total Well-Being with Eazr",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Wellness is no longer defined merely by the absence of disease. Today, it encapsulates a more holistic approach that combines the mental, physical, and emotional aspects of well-being. It's about achieving a balanced state of health, where mind, body, and spirit harmoniously coexist. Enter Eazr, a platform that doesn’t just emphasize health insurance but champions the cause of holistic wellness.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>Understanding Holistic Wellness:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Physical Wellness: Beyond just freedom from illness, it involves daily habits that ensure the optimal functioning of the body, like nutrition, exercise, and regular check-ups.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Mental and Emotional Wellness: This emphasizes mental health, stress management, and emotional stability. Practices like meditation, counseling, and therapeutic treatments play a crucial role.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Spiritual Wellness: Connecting with one's inner self, seeking purpose, and understanding life's deeper meanings contribute to spiritual health.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Social Wellness: Building meaningful relationships, understanding the importance of community, and nurturing social connections form the crux.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>How Eazr Champions Holistic Wellness:</h3>

            <p className='block text-left text__18 mb-3 font-light' >Tailored Insurance Packages: Eazr recommends policies that go beyond just hospitalization coverage. With benefits encompassing mental health therapy sessions, regular health check-ups, and alternative treatments, it ensures all facets of your well-being are addressed.

            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2 text__20'>Navigating Comprehensive Coverage with Eazr:</h3> */}

            <p className='block text-left text__18 mb-3 font-light'>Wellness Resources: Eazr offers a plethora of resources, from articles on mindfulness practices to expert-led webinars on nutrition, ensuring users are always informed.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Holistic Service Providers: Whether it's a renowned yoga center or a top-tier counseling service, Eazr's extensive network introduces you to establishments and professionals dedicated to various wellness dimensions.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Community Forums: Eazr believes in the power of collective wisdom. Its forums allow users to share personal wellness journeys, tips, and seek advice, fostering a sense of community.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Wellness Trackers: Integrated features on Eazr allow users to track various wellness goals, be it meditation hours, steps walked, or therapeutic sessions attended.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Holistic wellness acknowledges that every aspect of our lives affects our overall health. It's a call to acknowledge and nurture each dimension, ensuring a fulfilled, balanced life. With platforms like Eazr, the journey towards holistic wellness becomes not only attainable but also a shared experience. Dive deep into the world of comprehensive well-being, with Eazr as your steadfast ally, guiding, informing, and connecting every step of the way.</p>

          </p>
        </>
      )
    },
    {
      id: "11",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210723317-add-ons-for-your-hea.jpg",
      title: " Add On for your health Insurance",
      category: "Health Insurance",
      eventKey: "Add On for your health Insurance",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2 text__20'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Health insurance is a vital safeguard, offering financial security and access to quality healthcare. Add-ons enhance this coverage by addressing specific needs, ensuring comprehensive protection. These supplementary benefits fill gaps in regular health insurance, promoting better well-being and financial security.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2 text__20'>Differentiating add-on health insurance from regular health insurance involves understanding the following key points:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Enhanced Coverage: Add-ons offer extra layers of protection, covering specific conditions beyond regular health insurance.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Specific Focus: They are designed to address particular healthcare needs, such as maternity or critical illness coverage.
            </p>

            <p className='block text-left text__18 mb-3 font-light'>Supplementary Benefits: Add-ons provide additional features not typically included in standard policies, like hospital cash benefits or ambulance cover.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Customization: Policyholders can tailor add-ons to suit their unique requirements, unlike one-size-fits-all regular policies.

            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>How Eazr Champions Holistic Wellness:</h3> */}

            <p className='block text-left text__18 mb-3 font-light' >Lump Sum Payments: Some riders offer lump sum payouts upon specific diagnoses or events, contrasting with standard health insurance that reimburses actual medical expenses.

            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Navigating Comprehensive Coverage with Eazr:</h3> */}

            <p className='block text-left text__18 mb-3 font-light'>Cost-Effective: Add-ons are cost-effective ways to address specific needs without purchasing entirely new policies, making them flexible and budget-friendly.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Comprehensive Protection: They ensure comprehensive protection for various healthcare scenarios, providing peace of mind.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Tailored to Life Stage: Add-ons can be tailored to different life stages, catering to evolving healthcare needs.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Flexibility: Policyholders can choose specific benefits, creating personalized insurance packages that align with their circumstances.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Insurance Expertise: Insurance providers' expertise in designing specialized add-ons demonstrates their commitment to meeting diverse healthcare needs.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>In summary, add-on health insurance is a customizable, supplementary solution designed to address specific healthcare needs, while regular health insurance offers broad, comprehensive coverage for a range of healthcare scenarios.</p>

          </p>
        </>
      )
    },
    {
      id: "12",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698211446277-what-s-hospicash.jpg",
      title: "What's hospicash ?",
      category: "Hospicash",
      eventKey: "What's hospicash",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Amid the vast lexicon of insurance jargon, 'Hospicash' stands out, offering a unique financial cushion during hospitalization. But what exactly is Hospicash, and how does it fit into your health coverage mosaic? Let's dive deep, and with Eazr by our side, navigate the benefits seamlessly.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3>
            <p className='block text-left text__18 mb-3 font-light'>At its core, Hospicash is a daily hospital cash benefit policy. Instead of directly dealing with specific medical bills, it provides policyholders with a daily fixed sum for each day of hospitalization. This amount can be used to manage incidental expenses, such as travel, food, or even loss of income during one's stay at the hospital.
            </p>

            <h3 className='text-left text__18 font-semibold mb-2'>Benefits of Hospicash:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Financial Flexibility: Unlike structured claims that go directly to specific treatments, Hospicash provides flexibility. You can use the daily sum as per your discretion, catering to unforeseen hospitalization-related expenses.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Supplementary Support: While your primary health insurance covers significant medical bills, Hospicash fills the gaps, ensuring that auxiliary costs don’t strain your wallet.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>No Bills Required: To avail the daily cash benefit, there's no need to produce detailed medical bills. Just proof of hospitalization suffices, making the process hassle-free.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Coverage Beyond Boundaries: Hospicash often extends its benefits irrespective of the city or even country of hospitalization, ensuring you're protected globally.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2'>Eazr & Hospicash: Simplifying Selection</h3>

            <p className='block text-left text__18 mb-3 font-light'>With numerous Hospicash policies flooding the market, how do you choose the one that aligns with your needs? This is where Eazr shines.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Tailored Suggestions: Eazr's platform assesses your profile and offers Hospicash policies that best suit your needs and budget.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Transparent Comparisons: With Eazr, you can juxtapose multiple Hospicash policies, understand their benefits, and gauge their value propositions, all on a single platform.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Expert Consultations: If in doubt, Eazr connects you to insurance experts who can guide you through the nuances, ensuring your choice is well-informed.
            </p>
            {/* <p className='block text-left text__18 mb-3 font-light'>Insurance Expertise: Insurance providers' expertise in designing specialized add-ons demonstrates their commitment to meeting diverse healthcare needs.
            </p> */}


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Hospicash, with its promise of daily financial support during hospital stays, offers a unique buffer in the world of health insurance. And with platforms like Eazr, understanding, selecting, and benefiting from Hospicash becomes as easy as a click. Dive into the world of informed health insurance choices, because every hospitalization day should be about recovery, not financial worry.</p>

          </p>
        </>
      )
    },
    {
      id: "13",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698222526106-cash-crunch---during.jpg",
      title: "Cash Crunch? During Hospitalization",
      category: "Finance",
      eventKey: "Cash Crunch During Hospitalization",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Hospitalization is daunting, both emotionally and financially. With soaring medical costs in today’s healthcare landscape, even a brief hospital stay can lead to a significant cash crunch. It’s a challenge that many face, but with the right assistance and preparedness, it can be effectively navigated. Enter Eazr, a platform dedicated to alleviating this financial stress and ensuring that your health remains the sole focus during such trying times.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>Understanding the Cash Crunch Conundrum:</h3>
            <p className='block text-left text__18 mb-3 font-light'>When faced with sudden hospitalization, most families encounter unexpected expenses—from medical bills to ancillary costs like transportation, special diets, and more. These expenses, coupled with potential loss of income during the recovery period, can create a daunting financial strain.
            </p>

            <h3 className='text-left text__18 font-semibold mb-2'>Benefits of Hospicash:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Financial Flexibility: Unlike structured claims that go directly to specific treatments, Hospicash provides flexibility. You can use the daily sum as per your discretion, catering to unforeseen hospitalization-related expenses.
            </p>

            <h3 className='text-left text__18 font-semibold mb-2'>Why Cash Crunches are Challenging:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Impedes Treatment: Financial limitations might lead to sub-optimal treatment choices.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Mental Stress: Worries about finances can hinder the healing process.

            </p>

            <p className='block text-left text__18 mb-3 font-light' >Long-term Implications: Draining one’s savings can have repercussions on future financial plans and goals.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2'>Eazr’s Role in Combatting the Cash Crunch:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Comprehensive Health Insurance: Eazr recommends policies that cover a wide range of hospitalization expenses, ensuring that most costs are borne by the insurer.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Cashless Hospitalization: Through tie-ups with a vast network of hospitals, Eazr facilitates cashless treatments, meaning the hospital coordinates directly with the insurer for payments.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Transparent Policy Breakdown: Eazr deciphers insurance jargon, allowing users to understand clearly what is covered and what isn’t, ensuring no surprises.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Prompt Claim Assistance: Eazr’s dedicated team ensures that claim processes are hassle-free and swift, reducing the waiting period for reimbursements.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Flexible Payment Options: Recognizing that premiums can be a burden, Eazr offers flexible payment plans, ensuring insurance remains accessible to all.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>While the emotional toll of hospitalization is inevitable, the financial stress can be mitigated. It’s about planning, understanding, and leveraging the right resources. With Eazr, the journey from hospitalization to recovery becomes less about financial woes and more about health and healing. Navigate these challenges with an ally like Eazr by your side, and ensure that when health crises strike, you’re equipped, both medically and monetarily.</p>

          </p>
        </>
      )
    },
    {
      id: "14",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698211076571-critical-illness-you.jpg",
      title: "Critical illness you can’t ignore",
      category: "Critical Illness",
      eventKey: "Critical illness you can’t ignore",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Critical illnesses, such as cancer, heart disease, and organ failure, are life-threatening conditions that demand intensive medical attention. Early detection and prevention are crucial, and Eazr Insurance plays a pivotal role in achieving these goals.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>Early Detection and Prevention through Eazr:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Regular Health Check-ups: Eazr Insurance offers comprehensive coverage for regular health check-ups, including screenings like mammograms, colonoscopies, and blood tests. These evaluations enable the early detection of critical illnesses, leading to timely intervention.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Benefits of Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Lifestyle Support: Eazr Insurance promotes healthy living by covering wellness programs, fitness incentives, and nutritional counseling. It encourages individuals to adopt heart-healthy diets and exercise routines, reducing the risk of heart disease and related complications.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Stress Management: Stress is a contributing factor to many critical illnesses. Eazr Insurance covers stress-reduction activities such as meditation and yoga, supporting individuals in managing stress effectively.
            </p>

            <h3 className='text-left text__18 font-semibold mb-2'>Importance of Early Detection:</h3>
            <h3 className='text-left text__18 font-semibold mb-2'>Early detection is paramount for several reasons:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Better Treatment Outcomes: Identifying critical illnesses early leads to more effective treatment and better outcomes. Early intervention can prevent the progression of the illness to advanced stages.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Increased Survival Rates: Early detection significantly improves the chances of survival and recovery. For instance, early cancer detection allows for more successful surgical removal or targeted therapies.

            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Eazr & Hospicash: Simplifying Selection</h3> */}

            <p className='block text-left text__18 mb-3 font-light'>Reduced Healthcare Costs: Treating advanced-stage critical illnesses is costlier and resource-intensive. Early detection saves both individuals and healthcare systems substantial expenses.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Improved Quality of Life: Early detection and treatment help individuals maintain a better quality of life, preventing the development of complications associated with advanced illnesses.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Less Invasive Treatments: Early detection can enable less invasive treatment options. Smaller tumors, for example, may only require minimally invasive procedures.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Emotional Well-being: A timely diagnosis provides individuals and their families with a sense of control and hope, reducing emotional distress.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Preventing Disease Spread: Early detection of infectious diseases is crucial in preventing their spread to others. Timely diagnosis facilitates isolation and treatment, reducing the risk of outbreaks.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>In conclusion, early detection is essential for managing critical illnesses effectively. Individuals should engage in regular health check-ups, screenings, and self-examinations as appropriate for their risk factors. Timely detection, coupled with Eazr Insurance's support, plays a pivotal role in promoting overall well-being and ensuring better health outcomes.</p>

          </p>
        </>
      )
    },
    {
      id: "14",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698212442811-what-s-in-personal-a.jpg",
      title: "Rising Accidents in India",
      category: "Personal Accidental Coverage",
      eventKey: "Rising Accidents in India",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >The escalating rate of accidents in India has been a growing concern for policymakers, healthcare professionals, and the public alike. From bustling urban streets to serene rural routes, no area remains untouched by this peril. With such a backdrop, accident insurance isn't just a safety net—it's a necessity. In this evolving scenario, platforms like Eazr become invaluable, ensuring you are adequately protected.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>The Startling Reality:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Accidents in India have become increasingly frequent due to factors like traffic congestion, disregard for road rules, infrastructural lapses, and lack of awareness. These incidents not only result in physical trauma but also bring about financial distress, particularly if the injured person is a family's primary breadwinner.
            </p>
            <h3 className='text-left text__18 font-semibold mb-2'>Why Accident Insurance is Important:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Financial Buffer: Hospitalization due to accidents can be expensive. Accident insurance ensures that the financial burden is lightened with coverages like medical expense reimbursements.
            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Income Protection: Many policies offer compensation for loss of income during the recovery period, ensuring that your family’s livelihood isn't compromised.
            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Importance of Early Detection:</h3> */}
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Early detection is paramount for several reasons:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Disability Cover: Accidents might result in permanent or temporary disability. Appropriate policies provide lump-sum amounts or annuities in such cases, securing the individual’s financial future.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Death Benefit: In the unfortunate event of loss of life, accident insurance provides a lump-sum amount to the nominee, ensuring the family's financial stability.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2'>Navigating Accident Insurance with Eazr:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Customized Policy Recommendations: Eazr evaluates your needs and recommends the most fitting accident insurance policies, ensuring comprehensive protection.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Transparent Comparisons: Understand the nuances of different policies at a glance, helping you make an informed choice.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Hassle-Free Claim Process: Eazr streamlines the claim process, reducing the bureaucratic red tape and ensuring timely settlements.
            </p>
            {/* <p className='block text-left text__18 mb-3 font-light'>Emotional Well-being: A timely diagnosis provides individuals and their families with a sense of control and hope, reducing emotional distress.
            </p> */}
            <p className='block text-left text__18 mb-3 font-light'>Expert Consultations: Confused about the terms or the extent of coverage? Eazr connects you with insurance experts to clarify any doubts.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Given the surge in accidents in India, precautionary measures go beyond helmets and seat belts. Financial preparedness, in the form of accident insurance, is vital. And with platforms like Eazr, the journey from understanding to availing becomes smoother and more efficient. Gear up, stay informed, and ensure that unexpected accidents don't lead to prolonged financial downturns.</p>

          </p>
        </>
      )
    },
    {
      id: "15",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698212442811-what-s-in-personal-a.jpg",
      title: "What’s in? Personal Accident",
      category: "Personal Accidental Coverage",
      eventKey: "What’s in",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >Personal accidental cover is a crucial form of insurance that safeguards individuals and their families against the financial repercussions of accidental injury or death. It provides peace of mind and a safety net in case unforeseen accidents occur, covering medical expenses, rehabilitation costs, and lost income. Understanding its key features and benefits is essential for making informed decisions about your insurance needs.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>Key Features and Benefits:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Financial Security: Personal accidental cover offers financial protection, ensuring that medical expenses and income loss are covered in the event of an accident.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Why Accident Insurance is Important:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Comprehensive Coverage: It encompasses various provisions such as accidental death benefits, permanent and partial disability coverage, medical expense reimbursement, ambulance and transportation benefits, and more.

            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Customization: Policies can be tailored to meet specific requirements, ensuring that coverage aligns with individual needs and budgets.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Importance of Early Detection:</h3> */}
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Early detection is paramount for several reasons:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Tax Advantages: Depending on the region, premiums may be tax-deductible, providing potential tax benefits.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Prompt Claim Settlement: Insurers prioritize quick claim settlements, ensuring that beneficiaries receive timely financial support.

            </p>
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Navigating Accident Insurance with Eazr:</h3> */}

            <p className='block text-left text__18 mb-3 font-light'>Global Portability: Many policies offer global coverage, making them valuable for individuals who travel frequently or relocate.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Peace of Mind: Having personal accidental cover in place offers peace of mind, reducing stress and anxiety during challenging times.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Support for Dependents: In cases of accidental death or disability, the policy provides essential financial support for dependents, maintaining their quality of life.
            </p>
            {/* <p className='block text-left text__18 mb-3 font-light'>Emotional Well-being: A timely diagnosis provides individuals and their families with a sense of control and hope, reducing emotional distress.
            </p> */}
            <p className='block text-left text__18 mb-3 font-light'>Flexible Terms: Policies can be designed to suit both short-term and long-term coverage needs, ensuring flexibility.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Personal accidental cover, with its array of features and benefits, serves as a vital financial safety net during unexpected accidents or untimely incidents. It offers comprehensive protection, peace of mind, and support for both individuals and their families. By considering your unique requirements and understanding the flexibility and advantages of such policies, you can make informed choices to ensure that you and your loved ones are well-protected in times of need.</p>

          </p>
        </>
      )
    },
    {
      id: "16",
      img: "https://eazr.s3.ap-south-1.amazonaws.com/1698210309631-understanding-cashle.jpg",
      title: "Understanding Cashless Health Insurance",
      category: "Health",
      eventKey: "Understanding Cashless Health Insurance",
      content: (
        <>
          <p>
            <h3 className='text-left mb-3 text__18'>
              {/* <span className='font-semibold mb-2'>Why Invest in Health Insurance? Get Covered with Eazr!</span> <br /> */}
              <span >In a world where immediacy is cherished, the concept of cashless health insurance emerges as a beacon of convenience. It's more than just a policy; it's an embodiment of ease during health crises. Let's delve into this modern insurance wonder and see how Eazr streamlines its benefits for you.
              </span></h3>
            <h3 className='text-left text__18 font-semibold mb-2'>The Essence of Cashless Health Insurance:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Cashless health insurance enables you to receive medical treatments without directly paying from your pocket at the point of service. Instead, your insurance company coordinates directly with the hospital to settle the bills, provided the hospital is within the insurer's network.
            </p>

            <h3 className='text-left text__18 font-semibold mb-2'>Why Opt for Cashless Health Insurance?
            </h3>
            <p className='block text-left text__18 mb-3 font-light'>Immediate Care: In emergencies, the last thing one needs is financial formalities. Cashless policies ensure that treatments are not delayed due to payment processes.

            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Understanding Hospicash:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Financial Relief: No upfront payment means no immediate strain on your savings. Your focus remains where it should be: on recovery.
            </p>

            {/* <h3 className='text-left text__18 font-semibold mb-2'>Importance of Early Detection:</h3> */}
            {/* <h3 className='text-left text__18 font-semibold mb-2'>Early detection is paramount for several reasons:</h3> */}
            <p className='block text-left text__18 mb-3 font-light'>Streamlined Process: The bureaucratic process of claim reimbursements is sidestepped. No collecting bills, no lengthy paperwork, just timely medical care.
            </p>

            <p className='block text-left text__18 mb-3 font-light' >Prompt Claim Settlement: Insurers prioritize quick claim settlements, ensuring that beneficiaries receive timely financial support.

            </p>
            <h3 className='text-left text__18 font-semibold mb-2'>How Eazr Elevates Your Cashless Experience:</h3>

            <p className='block text-left text__18 mb-3 font-light'>Expensive Network Exploration: Eazr lists hospitals within your insurer’s network, ensuring you know exactly where you can avail cashless treatments.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Tailored Policy Recommendations: Based on your preferences, health needs, and location, Eazr suggests cashless health insurance policies that align perfectly with your requirements.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Digital Authorization: With integrated features, Eazr can help expedite pre-authorization requirements for cashless treatments, reducing waiting times.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Transparent Policy Breakdown: Eazr demystifies insurance jargon. Understand your policy's features, benefits, and exclusions with ease, ensuring you’re never caught off-guard.
            </p>
            <p className='block text-left text__18 mb-3 font-light'>Expert Assistance: Should you ever be uncertain about your cashless benefits or policy details, Eazr connects you with professionals to guide you through the process.
            </p>


            <h3 className='text__18 font-semibold text-left mb-2'>Conclusion:</h3>
            <p className='block text-left text__18 mb-3 font-light'>Cashless health insurance is revolutionizing how we perceive medical treatments, adding a layer of convenience to the often stressful realm of healthcare. And with platforms like Eazr, the journey from understanding to availing becomes smoother. Dive into the world of cashless health insurance and let Eazr be your compass, ensuring every health challenge is met with expertise and ease.</p>

          </p>
        </>
      )
    },
  ]

  const { name } = useParams()
  console.log(name)
  const renderData = data?.filter((item) => item.eventKey == name)
  console.log(renderData)

  return (
    <div>
      <Container>
        <div className='flex justify-center'>
          <NavLink to="/BlogPage"><img src="images/arthshala.png" alt="arthshala" height={200} width={200} /></NavLink>

        </div>
        <div>
          <Container>
            <PopularBlogs />
          </Container>
        </div>

      </Container>
      <section className="py-3">
        <Container>
          {
            renderData?.map((val) => {
              return (
                <Container className='px-40'>
                  <div className="text-center mb-10">
                    <div className='mb-3'>
                      <img src={val?.img} alt="images" className='banner' />
                    </div>
                    <h2 className="font-medium">
                      <span className="inline-block mb-3 text__48 sm:text__24">
                        {val.title}
                      </span>
                      {/* <span className=" inline-block mb-2 sm:block text__18">
                                  {" "}
                                  {val?.category}
                              </span> */}

                    </h2>
                    <p className='text_18'>
                      {val?.content}
                    </p>
                  </div>
                </Container>
              )
            })
          }

        </Container>
      </section>



    </div>
  )
}

export default SingleBlog