import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const MutualFund = () => {
    // const { name } = useParams();
    const [totalInvestment, setTotalInvestment] = useState(25000);
    const [expectedReturns, setExpectedReturns] = useState(12);
    const [timePeriod, setTimePeriod] = useState(10);
    const [estimatedReturns, setEstimatedReturns] = useState(0);
    const [totalValue, setTotalValue] = useState(0)
    // chart 

    const data = {
        labels: [
            'Principle Amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [totalInvestment, estimatedReturns],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculateEstimatedReturns = () => {
        const estimatedReturns =
            totalInvestment * ((1 + expectedReturns / 100) ** timePeriod - 1);
        setEstimatedReturns(estimatedReturns);
        setTotalValue(totalInvestment + parseInt(estimatedReturns))
    };


    useEffect(() => {
        calculateEstimatedReturns()

    }, [totalInvestment, timePeriod, expectedReturns])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Mutual Fund Calculator</h2>
                <p className='text__16 mb-3'>Embarking on an investment journey? Our Mutual Fund Calculator is designed to assist you in forecasting the future value of your mutual fund investments, enabling you to strategize and optimize your financial goals.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Select the investment mode: SIP (Systematic Investment Plan) or Lump Sum.
                    For SIP: Enter your monthly contribution amount. For Lump Sum: Enter your initial investment amount.
                    <br />
                    2. Adjust the expected annual returns (The average yearly return you anticipate from your mutual fund investments).<br />
                    3. Define the investment duration (Number of years you plan to stay invested). <br />
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount">
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Total Investment:</p>
                                        <input
                                            type="number"
                                            value={totalInvestment}
                                            id="loanamount"
                                            min="1000"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            onChange={(e) => setTotalInvestment(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="1000000"
                                            step="2000"
                                            value={totalInvestment}
                                            onChange={(e) => setTotalInvestment(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="expectedReturns">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Expected Return (p.a):</p>
                                        <input
                                            type="number"
                                            value={expectedReturns}
                                            id="expectedReturns"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            min="0"
                                            onChange={(e) => setExpectedReturns(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="expectedReturns"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={expectedReturns}
                                            onChange={(e) => setExpectedReturns(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="timePeriod">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Time Period (Years):</p>
                                        <input
                                            type="number"
                                            value={timePeriod}
                                            id="timePeriod"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            min="0"
                                            onChange={(e) => setTimePeriod(parseFloat(e.target.value) || 0)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="timePeriod"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="25"
                                            value={timePeriod}
                                            onChange={(e) => setTimePeriod(parseFloat(e.target.value) || 0)}
                                        />
                                    </div>
                                </label>
                            </div>

                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {totalInvestment && <div className='m-auto'>Total Investment: {totalInvestment}</div>}
                    {estimatedReturns && <div className='m-auto'>Est. Return: {estimatedReturns}</div>}
                    {totalValue && <div className='m-auto'>Total Value {totalValue}</div>} </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Strategic Planning: Get a clearer understanding of potential outcomes to plan your investments more strategically. <br />
                        2. Risk Assessment: By simulating different return rates, understand the impact of market volatility on your investments.<br />
                        3. Efficiency: Avoid complex manual calculations and get instant, clear results. <br />
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default MutualFund