import React from 'react'
import { Fragment } from 'react'
import { LogoIcon } from '../../Components/Icon/Icon'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux'
import { UserVerify } from '../../Redux/authTransaction/Action'
import { useEffect } from 'react';
import './Otpverificationcss.css';
const OtpVerification = () => {
    const [tooglePassword, settooglePassword] = useState(true)
    const [toogleChecklist, settoogleChecklist] = useState(false)
    const [access, setAccess] = useState()
    const [otp, setOtp] = useState('');
    const [errormsg, setErrormsg] = useState(null)
    const [isvalid, setIsvalid] = useState(false)
    console.log(otp)
    const dispatch = useDispatch()
    const history = useNavigate()

    const { contactNumber, accessToken, error } = useSelector(state => state?.otp);
    console.log(error?.status)
    useEffect(() => {
        if (error?.status === 200) {
            console.log(error?.data?.message);
        } else {
            console.log(error?.data?.message);
            setErrormsg(error?.data?.message);
        }
    }, [error]);

    console.log(error)
    console.log(contactNumber, "CN")
    const customInputStyle = {
        width: '50px',
        height: '50px',
        fontSize: '18px',
        margin: '0 8px',
        borderRadius: '5px',
        border: '1px solid #ccc', // Add the border style here
        textAlign: 'center',
        margin: 'auto'
    };


    const submit = () => {
        dispatch(UserVerify(contactNumber, otp, history));
    }


    return (
        <Fragment>
            <NavLink to="/">
                <div className="flex items-center gap-2 mb-10">
                    <LogoIcon />
                    {/* <span className='font-medium text__20 text-Mpurple'>TicketHub</span> */}
                </div>
            </NavLink>
            <div className='relative py-20'>
                <div className=''>
                    <div className="text-center mb-10">
                        <h4 className='font-medium text__32 mb-2'>Please Enter OTP 👋</h4>
                        {/* <p>Don’t have an account? <NavLink to="/auth/signup" className='text-Mblue'>Sign Up</NavLink></p> */}
                    </div>


                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='font-normal text__20 text-[#A3A3A3]'>OTP </Form.Label>
                        {/* <Form.Control type="tel" placeholder="9800 XXX XXX" min="10" max="10"className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' /> */}
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span> -</span>}
                            renderInput={(props, index) => (
                                <input {...props} style={customInputStyle} key={index} />
                            )}
                        />
                    </Form.Group>
                    <p className='mb-2 text__12'>{(errormsg) ? <p className='text-red-500 text__12 text-center'>{errormsg}</p> : null}</p>



                    <div className="text-center">
                        <button onClick={() => submit()}><NavLink
                            // to={ access ? "http://localhost:3001" :"/download"} 
                            className='inline-block cursor-pointer text-center font-medium text__16 text-white !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Login</NavLink></button>

                    </div>
                </div>
            </div>
            <div className='yellowribbon'>

            </div>
        </Fragment>
    )
}

export default OtpVerification
