import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const BikeLoan = () => {
    const [loanAmount, setLoanAmount] = useState(700000);
    const [interestRate, setInterestRate] = useState(9.9);
    const [loanTenure, setLoanTenure] = useState(36);
    const [emi, setEmi] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    // chart 

    const data = {
        labels: [
            'Principle Amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [loanAmount, totalInterest],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculateEMI = () => {
        const principal = parseFloat(loanAmount);
        const rateOfInterest = parseFloat(interestRate) / 1200; // Monthly interest rate
        const tenureInMonths = parseFloat(loanTenure); // Loan tenure in months

        if (principal && rateOfInterest && tenureInMonths) {
            const emiAmount =
                (principal * rateOfInterest * Math.pow(1 + rateOfInterest, tenureInMonths)) /
                (Math.pow(1 + rateOfInterest, tenureInMonths) - 1);
            setEmi(emiAmount.toFixed(2));

            const totalRepayment = emiAmount * tenureInMonths;
            const totalInterestPaid = totalRepayment - principal;

            setTotalInterest(totalInterestPaid.toFixed(2));
            setTotalAmount(totalRepayment.toFixed(2));
        } else {
            setEmi('');
            setTotalInterest('');
            setTotalAmount('');
        }
    };

    useEffect(() => {
        calculateEMI()

    }, [loanAmount, interestRate, loanTenure])
    return (
        <section className='py-6'>
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Bike Loan EMI Calculator</h2>
                <p className='text__16 mb-3'>Ready to hit the road with your dream bike? Navigate your financial journey with our Bike Loan EMI Calculator. It gives you a clear perspective on your monthly installments, ensuring that your ride is both exciting and financially sound.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Specify the bike loan amount (The total sum you're considering to borrow).<br />
                    2. Input the interest rate (The annual rate quoted by your bike loan provider).<br />
                    3. Choose the loan tenure (The period over which you plan to repay the loan). <br />
                    4. Click "Calculate" to unveil your monthly EMI, total interest to be paid, and a thorough repayment schedule.
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount" >
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Loan Amount:</p>
                                        <input
                                            type="number"
                                            id="loanAmount"
                                            value={loanAmount}
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            onChange={(e) => setLoanAmount(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="1000"
                                            value={loanAmount}
                                            onChange={(e) => setLoanAmount(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Interest Rate (p.a):</p>
                                        <input
                                            type="number"
                                            id="interestRate"
                                            value={interestRate}
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="45"
                                            step="0.1"
                                            value={interestRate}
                                            onChange={(e) => setInterestRate(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="loantenure">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Loan Tenture (Months) :</p>
                                        <input
                                            type="number"
                                            id="loanTenure"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            value={loanTenure}
                                            onChange={(e) => setLoanTenure(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="72"
                                            step="1"
                                            value={loanTenure}
                                            onChange={(e) => setLoanTenure(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: {totalInterest || 0}</div>}
                    {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount || 0}</div>} </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Financial Planning: Determine your monthly financial obligations and plan your budget accordingly. <br />
                        2. Loan Evaluation: By tweaking interest rates and loan durations, compare various bike loan offers to find the most suitable option.<br />
                        3. Swift and Accurate: Say goodbye to complex manual calculations and get precise results in a flash. <br />
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>
                {/* <Statements/> */}
                <PopularCalculator />
            </Container>
        </section>
    )
}

export default BikeLoan