import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const Gst = () => {
    const [exclusiveAmount, setExclusiveAmount] = useState('');
    const [inclusiveAmount, setInclusiveAmount] = useState('');
    const [gstRate, setGstRate] = useState('');
    const [isExclusive, setIsExclusive] = useState(true);
    const [totalGst, setTotalGst] = useState('');
    const [postGstAmount, setPostGstAmount] = useState('');
    const [preGstAmount, setPreGstAmount] = useState('');
    // chart 

    // const data = {
    //     labels: [
    //         'principle amount',
    //         'Total Interest',
    //     ],
    //     datasets: [{
    //         label: 'Eazr',
    //         data: [loan, totalInterest],
    //         backgroundColor: [
    //             'rgb(174, 230, 255)',
    //             'rgb(13, 75, 218)',
    //             // 'rgb(255, 205, 86)'
    //         ],
    //         // hoverOffset: 1
    //     }]
    // }

    //calculation 
    const handleAmountChange = (event) => {
        const { name, value } = event.target;
        if (name === 'exclusiveAmount') {
          setExclusiveAmount(value);
        } else if (name === 'inclusiveAmount') {
          setInclusiveAmount(value);
        }
      };
    
      const handleGstRateChange = (event) => {
        const value = event.target.value;
        setGstRate(value);
      };
    
      const handleCalculation = () => {
        if (isExclusive) {
          const exclusive = parseFloat(exclusiveAmount);
          const rate = parseFloat(gstRate) / 100;
          const gst = exclusive * rate;
          const inclusive = exclusive + gst;
    
          setTotalGst(gst.toFixed(2));
          setPostGstAmount(inclusive.toFixed(2));
          setPreGstAmount(exclusiveAmount);
        } else {
          const inclusive = parseFloat(inclusiveAmount);
          const rate = parseFloat(gstRate) / 100;
          const gst = inclusive - (inclusive / (1 + rate));
          const exclusive = inclusive - gst;
    
          setTotalGst(gst.toFixed(2));
          setPostGstAmount(inclusiveAmount);
          setPreGstAmount(exclusive.toFixed(2));
        }
      };

    // useEffect(() => {
    //     calculateEMI()

    // }, [loan, loantenure, roi])
    return (
        <section className='py-6'>
            {/* <Container>
                <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>GST Calculator</h2>
                <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col items-center'>
                            <div className='w-100'>
                                <label for="loanamount" >
                                    <div className="flex justify-between items-center">
                                        <p className="w-60">Loan Amount (&#8377;):</p>
                                        <Form.Control
                                            type="number"
                                            value={loan}
                                            id="loanamount"
                                            className='w-50'
                                            min="1000"
                                            onChange={(e) => setLoan(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="2000000"
                                            value={loan}
                                            onChange={(e) => setLoan(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between items-center">
                                       <p className="w-40 sm:w-60">Interest Rate (p.a):</p> 
                                        <Form.Control
                                            type="number"
                                            value={roi}
                                            id="intersetrate"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setRoi(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="40"
                                            step="2"
                                            value={roi}
                                            onChange={(e) => setRoi(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='w-100'>
                                <label for="loantenure">
                                    <div className="flex justify-between items-center">
                                        <p className="w-40 sm:w-60">Loan Tenture (Months) :</p>
                                        <Form.Control
                                            type="number"
                                            value={loantenure}
                                            id="loantenure"
                                            className='w-50'
                                            min="0"
                                            onChange={(e) => setloantenure(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="text-center">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="200"
                                            step="25"
                                            value={loantenure}
                                            onChange={(e) => setloantenure(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-auto'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: {totalInterest || 0}</div>}
                    {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount || 0}</div>} </div>

                <div>
                    <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>What is a Pension Calculator?</h1>
                    <p className='text__16 leading-7 mb-2'>A personal loan EMI calculator helps you calculate your personal loan EMI (Equated Monthly Instalment) obligations. Calculating your EMI beforehand can help you plan your loan and manage your finances in a better way.</p>
                    <p className='text__16 leading-7'>Navi’s personal loan EMI calculator carries a user-friendly design and is extremely easy to use. All you need to do is just select the loan amount you wish to borrow, interest rate and tenure. The online calculator will give you the EMI details in just a few seconds.</p>
                </div>
                <Statements/>
                <PopularCalculator />
            </Container> */}
        </section>
    )
}

export default Gst