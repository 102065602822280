import React, { useState } from "react";
import "./Transactionscss.css";
import cn from "classnames";
import * as XLSX from "xlsx";
import Card from "../../Card";
import { numberWithCommas } from "../../../../utils/utils";
import Dropdown from "../../Dropdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanup_transaction,
  downloadTransaction,
  getTransaction,
  getUsersSearchTranscation,
} from "../../../../Redux/authTransaction/Action";
import { FetchUserProfile } from "../../../../Redux/user/Action";
import ReactPaginate from "react-paginate";

const intervals = ["Last 30 days", "Last 20 days", "Last 10 days"];

const Transactions = ({ className }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [selectdate, setSelecteddate] = useState(null);
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState(intervals[0]);
  const [searchTranscation, setSearchTransaction] = useState("");

  const { transactions, user, search, download_transaction } = useSelector(
    (state) => state.otp
  );

  const userId = user?.data?.id;

  // console.log(userId);
  // useEffect(() => {});
  useEffect(() => {
    debugger;
    // dispatch();
    dispatch(cleanup_transaction());
    dispatch(downloadTransaction(userId, selectdate));
    console.log(transactions?.data, "transaction");
    console.log(download_transaction, "statement");
  }, [selectdate]);

  // console.log(download_transaction, "download transaction");

  const handleSearch = (e) => {
    setSearchTransaction(e.target.value);
    dispatch(getUsersSearchTranscation(userId, searchTranscation, pageNumber));
  };

  const changePage = ({ selected }) => {
    const newSelect = selected + 1;
    setPageNumber(newSelect);
  };

  useEffect(() => {
    dispatch(getTransaction(userId, pageNumber, 10));
  }, [pageNumber]);

  useEffect(() => {
    dispatch(FetchUserProfile());
  }, []);

  const DownlaodButton = () => {
    {
      download_transaction?.length > 0
        ? handleDownload()
        : alert("No Data Found");
    }
  };

  // Excel File
  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(download_transaction);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  return (
    <Card
      className="card"
      classCardHead="head"
      title="Transactions"
      classTitle="title"
      head={
        <>
          {/* <Dropdown
            className="dropdown"
            classDropdownHead="dropdownHead"
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          /> */}
          <div>
            <select
              className="w-content bg-blueGray-100 py-2 rounded-md visited:outline-none"
              onChange={(e) => setSelecteddate(e.target.value)}
            >
              <option value="15">15 Days</option>
              <option value="30">30 Days</option>
              {/* <option>Monthly</option> */}
            </select>

            <button
              className="button button-small me-auto"
              onClick={DownlaodButton}
            >
              Download SCV
            </button>
          </div>
        </>
      }
    >
      <div className="wrapper">
        <div className="table">
          <div style={{ padding: "10px 16px", width: "100%" }}>
            {/* <h6 style={{marginLeft:"auto"}}>Search Transactions</h6> */}
            {/* <div style={{display: 'flex', flexDirection: 'row'}}> */}
            <input
              type="text"
              value={searchTranscation}
              placeholder="Search for ..."
              onChange={handleSearch}
              className="searchbox"
            />
            <i className="fa fa-search"></i>
            {/* </div> */}
          </div>
          <div className="row">
            <div className="col">Transaction to</div>
            <div className="col">Amount</div>
            <div className="col">Status</div>
            <div className="col">Status</div>
            <div className="col">Transaction At</div>
          </div>

          {searchTranscation !== ""
            ? search?.data?.map((x, index) => (
                <div className="row" key={index}>
                  <div className="col">
                    {x?.partner?.businessName || "Eazr"}
                  </div>
                  <div className="col">
                    {x?.partner?.status ? (
                      <div
                        className={`{ "status-green-dark":x.partner.status === 0 } status status-green-dark`}
                      >
                        Sale
                      </div>
                    ) : (
                      <div
                        className={`{ "status-red-dark":x.partner.status === false } status status-red-dark`}
                      >
                        ₹ {x.amount}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="product">
                      {x.status === 0
                        ? "Initiated"
                        : "" || x.status === 1
                        ? "Failed"
                        : "" || x.status === 2
                        ? "Success"
                        : ""}{" "}
                    </div>
                  </div>
                  {/* <div className="col">{x.debit === true ? "Yes" : "No"}</div> */}
                  <div className="col">{x?.createdTimestamp}</div>
                </div>
              ))
            : transactions?.data?.map((x, index) => (
                // <div className="row " key={index}>
                //   <div className="col">
                //     {x?.partner?.businessName || "Eazr"}
                //   </div>
                //   <div className="col">
                //     {x?.partner?.status ? (
                //       <div
                //         className={`{ "status-green-dark":x.partner.status === 0 } status status-green-dark`}
                //       >
                //         Sale
                //       </div>
                //     ) : (
                //       <div
                //         className={`{ "status-red-dark":x.partner.status === false } status status-red-dark`}
                //       >
                //         ₹ {x.amount}
                //       </div>
                //     )}
                //   </div>
                //   <div className="col">
                //     <div className="product">
                //       {x.status === 0
                //         ? "Initiated"
                //         : "" || x.status === 1
                //         ? "Failed"
                //         : "" || x.status === 2
                //         ? "Success"
                //         : ""}{" "}
                //     </div>
                //   </div>
                //   <div className="col">{x.debit === true ? "Yes" : "No"}</div>
                //   <div className="col">{x?.createdTimestamp}</div>
                // </div>
                <div className="row" key={index}>
                  <div className="col">
                    {x?.partner?.businessName || "Eazr"}
                  </div>
                  <div className="col">
                    {x?.partner?.status ? (
                      <div
                        className={`{"status-green-dark":x.partner.status === 0 } status status-green-dark`}
                      >
                        Sale
                      </div>
                    ) : (
                      <div
                        className={`{ "status-red-dark":x.partner.status === false } status status-red-dark`}
                      >
                        ₹ {x.amount}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="product">
                      {x.status === 0
                        ? "Initiated"
                        : "" || x.status === 1
                        ? "Failed"
                        : "" || x.status === 2
                        ? "Success"
                        : ""}{" "}
                    </div>
                  </div>
                  <div className="col">{x.debit === true ? "Yes" : "No"}</div>
                  <div className="col">{x?.createdTimestamp}</div>
                </div>
              ))}

          {/* {transactions?.length > 0 ? (
            transactions?.data?.map((x, index) => (
              <div className="row" key={index}>
                <div className="col">{x?.partner?.businessName || "Eazr"}</div>
                <div className="col">
                  {x?.partner?.status ? (
                    <div
                      className={`{ "status-green-dark": x.partner.status === 0 } status status-green-dark`}
                    >
                      Sale
                    </div>
                  ) : (
                    <div
                      className={`{ "status-red-dark": x.partner.status === false } status status-red-dark`}
                    >
                      ₹ {x.amount}
                    </div>
                  )}
                </div>
                <div className="col">
                  <div className="product">
                    {x.status === 0
                      ? "Initiated"
                      : "" || x.status === 1
                      ? "Failed"
                      : "" || x.status === 2
                      ? "Success"
                      : ""}{" "}
                  </div>
                </div>
                <div className="col">{x.debit === true ? "Yes" : "No"}</div>
                <div className="col">{x?.createdTimestamp}</div>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>No Data Found</div>
          )} */}
        </div>
      </div>
      <div style={{ display: "flex", margin: "auto" }}>
        {transactions?.data?.length > 0 && searchTranscation === "" ? (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={transactions?.pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns flex"}
            previousLinkClassName={
              "previousBttn  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            }
            nextLinkClassName={
              "nextBttn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            }
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive "}
          />
        ) : (
          ""
        )}
      </div>
      <div className="foot">
        {/* <button className="button">
          <Loader className="loader" />
          <span>Load more</span>
        </button> */}
      </div>
    </Card>
  );
};

export default Transactions;
