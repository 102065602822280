import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";

const CalculatorComp = () => {
  const { name } = useParams();
  let [loan, setLoan] = useState(0);
  let [roi, setRoi] = useState(0);
  let [loantenture, setLoantenture] = useState(0);
  console.log(name);

  // switch(name){
  //     case 'Personal Loan':
  //         return {
  //             // loan = 400,
  //             // roi = 2
  //         }

  //     default:
  //         return {
  //             ...loan
  //         }
  // }

  const calculateEmi = () => {};
  return (
    <section className="flex justify-center">
      <div className="flex justify-center flex-column items-center w-[50%] h-[auto] border">
        <h1 className="text-center font-medium mb-5 text__32">
          {" "}
          {`${name} Calculator`}{" "}
        </h1>
        <div>
          <label for="loanamount">
            <div className="flex justify-between">
              Loan Amount:
              <Form.Control
                type="number"
                value={loan}
                id="loanamount"
                onChange={(e) => setLoan(e.target.value)}
              />{" "}
            </div>
            <div className="text-center">
              <input
                type="range"
                id="loanamount"
                name="points"
                min="0"
                max="100"
                step="20"
                value={loan}
                onChange={(e) => setLoan(e.target.value)}
              />
            </div>
          </label>
        </div>
        <br />
        <div>
          <label for="intersetrate">
            <div className="w-full flex justify-between ">
              Interest Rate :
              <Form.Control
                type="number"
                value={roi}
                id="intersetrate"
                onChange={(e) => setRoi(e.target.value)}
              />{" "}
            </div>
            <div className="text-center">
              <input
                type="range"
                id="intersetrate"
                name="points"
                min="0"
                max="100"
                step="20"
                value={roi}
                onChange={(e) => setRoi(e.target.value)}
              />
            </div>
          </label>
        </div>
        <br />
        <div>
          <label for="loantenture">
            <div className="flex justify-between">
              Loan Tenture (Months) :
              <Form.Control
                type="number"
                value={loantenture}
                id="loantenture"
                onChange={(e) => setLoantenture(e.target.value)}
              />{" "}
            </div>
            <div className="text-center">
              <input
                type="range"
                id="loantenture"
                name="points"
                min="0"
                max="100"
                step="20"
                value={loantenture}
                onChange={(e) => setLoantenture(e.target.value)}
              />
            </div>
          </label>
        </div>
        <br />
        {/* <button onClick={calculateEmi}>Calculate EMI</button> */}
        {/* {emi > 0 && <p>EMI: {emi}</p>} */}
      </div>
    </section>
  );
};

export default CalculatorComp;
