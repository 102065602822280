import React from 'react'
import { Container, Form, InputGroup } from 'react-bootstrap'
import { Fragment } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { LogoIcon } from '../Icon/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { removeToken } from '../../Redux/authTransaction/Action'
import './Navbarcss.css'

const Navbar = () => {

    let location = useLocation();
    const [ToogleMenuResponsive, setToogleMenuResponsive] = useState(false);
    const [navabarScroll, setnavabarScroll] = useState(false)
    // const [access, setAccess] = useState(localStorage.getItem("accessToken"))


    const stickNavabr = () => {
        if (window.scrollY > 100) {
            setnavabarScroll(true)
        } else {
            setnavabarScroll(false)
        }
    }
    const dispatch = useDispatch()
    const history = useNavigate()

    const { accessToken } = useSelector(state => state.otp)
    // console.log(accessToken, "REdux")

    useEffect(() => {
        window.addEventListener('scroll', stickNavabr);
    }, [location])


    const logOut = () => {
        localStorage.removeItem("accessToken")
        dispatch(removeToken())
        history("/")
    }

    return (
      <Fragment>
        <div
          className={
            "fixed w-full bg-[#000000] z-[95] pt-[100px] pb-2 menuMobile " +
            (ToogleMenuResponsive ? "active" : "")
          }
        >
          <Container>
            <ul className="list-none p-0 m-0 flex items-center flex-wrap gap-4 text-[20px] w-full">
              <li className="w-full">
                <NavLink
                  to="/"
                  onClick={() =>
                    setToogleMenuResponsive(!ToogleMenuResponsive)
                  }
                  className="font-medium text-white"
                >
                  Credit
                </NavLink>
              </li>
              {/* <li className='w-full'>
                            <NavLink to="/for-business" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium text-white'>Credit</NavLink>
                        </li> */}
              <li className="w-full">
                <NavLink
                  to="/help"
                  onClick={() =>
                    setToogleMenuResponsive(!ToogleMenuResponsive)
                  }
                  className="font-medium text-white"
                >
                  Help
                </NavLink>
              </li>
              <li className="w-full">
                <NavLink
                  to="/about"
                  onClick={() =>
                    setToogleMenuResponsive(!ToogleMenuResponsive)
                  }
                  className="font-medium text-white"
                >
                  About
                </NavLink>
              </li>
              <li className="w-full">
                <NavLink
                  to="/contact"
                  onClick={() =>
                    setToogleMenuResponsive(!ToogleMenuResponsive)
                  }
                  className="font-medium text-white"
                >
                  Contact
                </NavLink>
              </li>
              <li className="w-full">
                <NavLink
                  to="/BlogPage"
                  onClick={() =>
                    setToogleMenuResponsive(!ToogleMenuResponsive)
                  }
                  className="font-medium text-white"
                >
                  ArthShaala
                </NavLink>
              </li>
              {!accessToken ? (
                <li className="w-full">
                  <button>
                    <NavLink
                      to="https://checkin.eazr.in/"
                      className="cursor-pointer font-medium text-[14px] text-Mblue !border-Mblue btnClass hover:bg-Mblue hover:text-Mwhite"
                    >
                      Log In
                    </NavLink>
                  </button>
                </li>
              ) : (
                <li className="w-full">
                  <button
                    onClick={() => {
                      logOut();
                    }}
                  >
                    <NavLink
                      to="/auth/login"
                      className="cursor-pointer font-medium text-[14px] text-Mblue !border-Mblue btnClass hover:bg-Mblue hover:text-Mwhite"
                    >
                      Log Out
                    </NavLink>
                  </button>
                </li>
              )}
            </ul>
          </Container>
        </div>

        <div
          className={
            "py-4 w-full z-20 left-0 top-0 bg-black text-white navbar"
          }
        >
          <Container className="relative flex items-center">
            <NavLink to="/" className="">
              <div className="flex items-center gap-2">
                <LogoIcon />
                {/* <span className='font-medium text__20 text-Mpurple'>TicketHub</span>  */}
                {/* <img src="./../images/Eazr-Logo-Blue.png"/> */}
              </div>
            </NavLink>

            <ul className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 list-none p-0 m-0 hidden lg:flex items-left gap-5 text__16">
              <li>
                <NavLink to="/" className={"font-medium text-white"}>
                  Credit
                </NavLink>
              </li>
              {/* <li>
                            <NavLink to="/for-business" className={'font-medium text-white'}>Credit</NavLink>
                        </li> */}
              <li>
                <NavLink
                  to="/help"
                  className={"font-medium text-white"}
                >
                  Help
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={"font-medium text-white"}
                >
                  About
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/contact"
                  className={"font-medium text-white"}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/BlogPage"
                  className={"font-medium text-white"}
                >
                  ArthShaala
                </NavLink>
              </li>
            </ul>

            {!accessToken ? (
              <button className="ml-auto hidden lg:block">
                <NavLink to="https://checkin.eazr.in/">
                  <div
                    className={
                      "!hidden md:!inline-block cursor-pointer font-medium text__14 text-Mblue !border-Mblue btnClass hover:bg-Mblue hover:text-white"
                    }
                  >
                    Log In
                  </div>
                </NavLink>
              </button>
            ) : (
              <button
                onClick={() => {
                  logOut();
                }}
                className="ml-auto hidden lg:block"
              >
                <NavLink>
                  <div
                    className={
                      "!hidden md:!inline-block cursor-pointer font-medium text__14 bg-Mblue text-white  btnClass hover:bg-Mblack hover:text-white"
                    }
                  >
                    Log Out
                  </div>
                </NavLink>
              </button>
            )}
            {/* <NavLink to="/auth/login" className="ml-auto hidden lg:block">
                        <div className={'!hidden md:!inline-block cursor-pointer font-medium text__14 text-Mblue !border-Mblue btnClass hover:bg-Mblue hover:text-white'}>Log In</div>
                    </NavLink> */}

            <div
              onClick={() =>
                setToogleMenuResponsive(!ToogleMenuResponsive)
              }
              className={
                "relative px-1 py-1 barIcon w-[30px] h-[30px] cursor-pointer lg:hidden ml-auto " +
                (ToogleMenuResponsive ? "active" : "")
              }
            >
              <div className={"!bg-white"}></div>
              <div className={"!bg-white"}></div>
              <div className={"!bg-white"}></div>
            </div>
          </Container>
        </div>
      </Fragment>
    );
}

export default Navbar
