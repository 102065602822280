import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Fintechassociation = () => {
    return (
        <Fragment>
            <section className='bg-Mbg py-16'>
                <Container>
                    <div className="text-center mb-2 relative">
                        <h3 className='text__48 font-medium mb-2 relative z-2'>fintech association for consumer empowerment</h3>
                        <p className='text__18 text-Mgray'>Effective Date: November 28, 2023</p>

                        <img src="./../images/ballon.svg" className='absolute w-[3rem] lg:w-auto -top-[5rem] lg:-top-[3rem] left-[90%] md:left-[80%] lg:left-[73%] -translate-x-1/2' alt="" />
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className='justify-center'>
                        <Col md={10}>

                            <div className="mb-3">
                                <h5 className='font-medium text__20 mb-3'>Fintechassociation & Conditions</h5>
                                <p className='text__18 mb-3'>This document is an electronic record in Fintechassociation of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                <p className='text__18 mb-3'>These Fintechassociation and conditions under this agreement (“User Fintechassociation”) apply on the usage of our website www.itseazr.com (“Website”), Service and to all information, recommendations and/or services provided to you on or through the Website and/or through the Customer Care (“CC”) at eaza@itseazr.com.</p>
                                <p className='text__18 mb-3'>The Fintechassociation and conditions mentioned in this contract along with the Privacy Policy constitute the entire contract between the User (as defined below) and Eazr (as defined below) with respect to access to and use of the website and the Services offered by Eazr, superseding any prior written or oral contracts in relation to the same subject matter herein.</p>
                                <p className='text__18 mb-3'>The term “You / Your” refers to the User of Service, Website and the CC.</p>
                                <p className='text__18 mb-3'>EAZR DIGIPAYMENTS PRIVATE LIMITED is a private company registered under the Companies Act, 2013 having its registered office at 103 Renuka, Kalanagar, Near Guru Nanak Hospital, Bandra East, Mumbai 400051, Maharashtra, India. (hereinafter referred to as “Eazr”).</p>

                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text__20 mb-3'>Definitions</h5>
                                <p className='text__18 mb-3'>“Applicable Law” shall mean any statute, law, regulation, ordinance, rule, judgment, order, decree, by-law, approval from the concerned authority, government resolution, order, directive, guideline, policy, requirement, or other governmental restriction or any similar form of decision of, or determination by, or any interpretation or adjudication having the force of law of any of the foregoing, by any concerned authority having jurisdiction over the matter in question;</p>
                                <p className='text__18 mb-3'>“Service” shall mean technology platform provided by Eazr that allows Merchants to offer a differentiated, hassle free buying experience to eligible Users including a tab offered by the Merchants to the Users;</p>

                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text__20 mb-2'>Intellectual Property</h5>
                                <p className='text__18'>The Service and its original content, features, and functionality are and will remain the exclusive property of [company name] and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of [company name].</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text__20 mb-2'>Links To Other Web Sites</h5>
                                <p className='text__18'>Our Service may contain links to third-party web sites or services that are not owned or controlled by [company name]. [company name] has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that [company name] shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text__20 mb-2'>Changes</h5>
                                <p className='text__18'>We reserve the right, at our sole discretion, to modify or replace these Fintechassociation at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new Fintechassociation taking effect. What constitutes a material change will be determined at our sole discretion.
                                    Contact Us. If you have any questions about these Fintechassociation, please contact us.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Fintechassociation
