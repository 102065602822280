import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS
} from "chart.js/auto"
import Statements from '../../Container/Statements';

const HomeLoan = () => {
    // const { name } = useParams();
    let [loan, setLoan] = useState(2500000);
    let [roi, setRoi] = useState(8.45);
    let [loantenure, setloantenure] = useState(20);
    const [emi, setEmi] = useState('');
    const [totalInterest, setTotalInterest] = useState(1);
    const [totalAmount, setTotalAmount] = useState(1);
    const [principleamount, setPrinicipleamount] = useState(0)
    // chart 

    const data = {
        labels: [
            'Principle Amount',
            'Total Interest',
        ],
        datasets: [{
            label: 'Eazr',
            data: [loan, totalInterest],
            backgroundColor: [
                'rgb(174, 230, 255)',
                'rgb(13, 75, 218)',
                // 'rgb(255, 205, 86)'
            ],
            // hoverOffset: 1
        }]
    }

    //calculation 
    const calculateEMI = () => {
        const principal = parseFloat(loan);
        const rate = parseFloat(roi) / 12 / 100;
        const tenure = parseFloat(loantenure) * 12;

        if (principal > 0 && rate > 0 && tenure > 0) {
            const emiValue = (principal * rate * Math.pow(1 + rate, tenure)) / (Math.pow(1 + rate, tenure) - 1);
            setEmi(emiValue.toFixed(2));
            const totalPayment = emiValue * tenure;
            const totalInterestValue = totalPayment - principal;
            setTotalInterest(totalInterestValue.toFixed(2));

            setTotalAmount(totalPayment.toFixed(2));
        } else {
            setEmi('');
            setTotalInterest('');
            setTotalAmount('');
        }
    };


    useEffect(() => {
        calculateEMI()

    }, [loan, loantenure, roi])
    return (
        <section className='py-6' >
            <Container>
                <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Home Loan EMI Calculator</h2>
                <p className='text__16 mb-3'>Taking a step towards your dream home? Let our Home Loan EMI Calculator be your financial compass. Gain clarity on your monthly installments, total interest payable, and more with this intuitive tool.</p>
                <h2 className='text-left text__32 mb-3'>How to Use:</h2>
                <p className='text__16 mb-10'>
                    1. Enter the loan principal amount (The amount you intend to borrow). <br />
                    2. Specify the interest rate (The annual rate quoted by your housing finance provider). <br />
                    3. Adjust the loan tenure (Number of years you plan to repay the loan).<br />
                </p>
                <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
                    <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
                        <div className='flex flex-col'>
                            <div className='row w-full mb-5'>
                                <label for="loanamount">
                                    <div className="row w-full">
                                        <p className='col-6 w-[50%] text-left font-bold'>Loan Amount :</p>
                                        <input
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            type="number"
                                            value={loan}
                                            id="loanamount"
                                            min="1000"
                                            onChange={(e) => setLoan(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loanamount"
                                            name="points"
                                            className='form-range'
                                            min="0"
                                            max="10000000"
                                            step="5000"
                                            value={loan}
                                            onChange={(e) => setLoan(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full mb-5'>
                                <label for="intersetrate">
                                    <div className="w-full flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Interest Rate (p.a):</p>
                                        <input
                                            type="number"
                                            value={roi}
                                            id="intersetrate"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            min="0"
                                            onChange={(e) => setRoi(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="intersetrate"
                                            name="points"
                                            className='form-range '
                                            min="0"
                                            max="40"
                                            step="0.5"
                                            value={roi}
                                            onChange={(e) => setRoi(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                            <br />
                            <div className='row w-full'>
                                <label for="loantenure">
                                    <div className="flex justify-between row w-100">
                                        <p className='col-6 w-[50%] text-left font-bold'>Loan Tenture (Months) :</p>
                                        <input
                                            type="number"
                                            value={loantenure}
                                            id="loantenure"
                                            className='col-6 ml-auto w-[50%] text-right font-bold'
                                            min="0"
                                            onChange={(e) => setloantenure(e.target.value)}
                                        />{" "}
                                    </div>
                                    <div className="">
                                        <input
                                            type="range"
                                            id="loantenure"
                                            name="points"
                                            className='form-range w-100'
                                            min="1"
                                            max="200"
                                            step="2"
                                            value={loantenure}
                                            onChange={(e) => setloantenure(e.target.value)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
                        <div className='h-80 piechart'><Pie data={data} /></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
                    {totalInterest && <div className='m-auto'>Total Interest: {totalInterest}</div>}
                    {totalAmount && <div className='m-auto'>Total Amount Payable: {totalAmount}</div>} </div>

                <div>
                    <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
                    <p className='text__16 mb-4'>
                        1. Transparent Planning: Gauge your monthly financial commitment before taking the plunge. <br />
                        2. Loan Comparison: Evaluate offers from different lenders to see which one suits your budget.<br />
                        3. Hassle-free: Get a quick and clear perspective without diving into complex calculations. <br />
                    </p>
                    <p className='text-Mblue'>
                        Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
                    </p>
                </div>

                <PopularCalculator />
            </Container>
        </section>
    )
}

export default HomeLoan