import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const PostOffice = () => {
  const [investmentAmount, setInvestmentAmount] = useState(10000);
  const [interestRate, setInterestRate] = useState(6.6);
  const [tenure, setTenure] = useState(5);
  const [monthlyInterest, setMonthlyInterest] = useState('');
  const [totalInterest, setTotalInterest] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  // chart 

  const data = {
    labels: [
      'principle amount',
      'Total Interest',
    ],
    datasets: [{
      label: 'Eazr',
      data: [investmentAmount, interestRate],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateMIS = () => {
    const principal = parseFloat(investmentAmount);
    const rate = parseFloat(interestRate) / 100 / 12;
    const months = parseFloat(tenure) * 12;

    if (principal > 0 && rate > 0 && months > 0) {
      const monthlyInterestValue = (principal * rate);
      setMonthlyInterest(monthlyInterestValue.toFixed(2));

      const totalInterestValue = monthlyInterestValue * months;
      setTotalInterest(totalInterestValue.toFixed(2));

      const monthlyIncomeValue = (monthlyInterestValue * months) / months;
      setMonthlyIncome(monthlyIncomeValue.toFixed(2));
    } else {
      setMonthlyInterest('');
      setTotalInterest('');
      setMonthlyIncome('');
    }
  };


  useEffect(() => {
    calculateMIS()
  }, [investmentAmount, interestRate])
  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Post Office MIS Calculator</h2>
        <p className='text__16 mb-3'>Plan your investments wisely with our user-friendly Post Office MIS Calculator. This tool allows you to understand the monthly payouts you can expect from investing in the Post Office Monthly Income Scheme.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Enter the investment amount (Amount you plan to deposit in the MIS). <br />
          2. The current MIS interest rate will be pre-filled. (However, you can adjust if needed). <br />
        </p>

        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col '>
              <div className='row w-full mb-5'>
                <label for="loanamount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>investment Amount:</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={investmentAmount}
                      id="loanamount"
                      onChange={(e) => setInvestmentAmount(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanamount"
                      className='form-range'
                      name="points"
                      min="1000"
                      max="100000"
                      step="1000"
                      value={investmentAmount}
                      onChange={(e) => setInvestmentAmount(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="intersetrate">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Interest Rate :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={interestRate}
                      id="intersetrate"
                      min="0"
                      onChange={(e) => setInterestRate(e.target.value)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="intersetrate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="0.5"
                      value={interestRate}
                      onChange={(e) => setInterestRate(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="tenure">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'> investmentAmount Tenture (Months) :</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={tenure}
                      id="tenure"
                      max="5"
                      min="5"
                      onChange={(e) => setTenure(e.target.value)}
                    />{" "}
                  </div>
                  {/* <div className="text-center">
                  <input
                    type="range"
                    id="tenure"
                    name="points"
                    min="0"
                    max="200"
                    step="25"
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                  />
                </div> */}
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex justify-center text-center items-center'>
            {/* <div className='h-auto'><Pie data={data}/></div> */}
            {monthlyIncome && <div className='text__24 leading-9'>Monthly Income: <br /> <span className='font-bold text__32'>{monthlyIncome}</span></div>}
          </div>

        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Informed Decision Making: Understand potential returns before investing.<br />
            2. Financial Planning: Know your expected monthly income to better plan your expenditures.<br />
            3. Efficiency: Eliminate manual computations and reduce chances of errors. <br />
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default PostOffice