import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS
} from "chart.js/auto"

const Swp = () => {
  const [totalInvestment, setTotalInvestment] = useState(500000);
  const [withdrawalPerMonth, setWithdrawalPerMonth] = useState(10000);
  const [expectedReturns, setExpectedReturns] = useState(8);
  const [timePeriod, setTimePeriod] = useState(5);
  const [totalValue, setTotalValue] = useState(0);
  // chart 

  const data = {
    labels: [
      'Investment amount',
      'Total Interest',
    ],
    datasets: [{
      label: 'Eazr',
      data: [totalInvestment, expectedReturns],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateSWP = () => {
    const months = timePeriod * 12;
    const totalValue =
      totalInvestment *
      (1 + expectedReturns / 100) ** (months / 12) -
      (withdrawalPerMonth * ((1 - (1 + expectedReturns / 100) ** (months / 12)) / (-expectedReturns / 100)));
    setTotalValue(totalValue);
  };




  useEffect(() => {
    calculateSWP()
  }, [totalInvestment,withdrawalPerMonth,expectedReturns,timePeriod])
  return (
    <section >
    <Container>
      <h2 className='text-center text__32 mb-3' style={{ fontFamily: "Nayuki" }}>SWP Calculator</h2>
      <div className='flex flex-wrap mb-2 container' style={{   boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
        <div className=' w-full sm:w-1/2 p-3 h-max h-[60vh]'>
          <div className='flex flex-col items-center'>
            <div>
              <label for="investment">
                <div className="row">
                  <p className='col-6'>Total Investment:</p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={totalInvestment}
                    id="investment"
                    min="1000"
                    step="1000"
                    onChange={(e) => setTotalInvestment(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    id="investment"
                    className='form-range'
                    name="points"
                    min="0"
                    max="100000"
                    step="20000"
                    value={totalInvestment}
                    onChange={(e) => setTotalInvestment(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
            <br />
            <div>
              <label for="expectedreturn">
                <div className="row">
                 <p className='col-6'>Withdrawal per month</p> 
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={withdrawalPerMonth}
                    id="expectedreturn"
                    min="0"
                    onChange={(e) => setWithdrawalPerMonth(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    id="expectedreturn"
                    name="points"
                    className='form-range'
                    min="0"
                    max="40"
                    step="2"
                    value={withdrawalPerMonth}
                    onChange={(e) => setWithdrawalPerMonth(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
            <br/>
            <div>
              <label for="expectedReturns">
                <div className="row">
                 <p className='col-6'>Expected Returns (% per annum):</p> 
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={expectedReturns}
                    id="expectedReturns"
                    min="0"
                    onChange={(e) => setExpectedReturns(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    id="expectedReturns"
                    name="points"
                    className='form-range'
                    min="0"
                    max="40"
                    step="2"
                    value={expectedReturns}
                    onChange={(e) => setExpectedReturns(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
            <br />
            <div>
              <label for="timePeriod">
                <div className="row">
                 <p className='col-6'>Time Period (Years)</p>
                  <input
                    type="number"
                    className='col-6 w-[30%]'
                    value={timePeriod}
                    id="timePeriod"
                    onChange={(e) =>setTimePeriod(parseFloat(e.target.value) || 0)}
                  />{" "}
                </div>
                <div className="text-center">
                  <input
                    type="range"
                    className='form-range'
                    id="tenure"
                    name="points"
                    min="0"
                    max="200"
                    step="25"
                    value={timePeriod}
                    onChange={(e) =>setTimePeriod(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </label>
            </div>
          </div>

        </div>
        <div className=' w-full sm:w-1/2 p-3 h-max flex justify-center text-center items-center'>
          <div className='h-auto'><Pie data={data}/></div> 

          </div>
      
      </div>
      <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
                    {totalInvestment && <div className='m-auto'>Total Investment: {totalInvestment}</div>}
                    {expectedReturns && <div className='m-auto'>Expected Return: {expectedReturns}</div>} 
                    {totalValue && <div className='m-auto'>Total Value: {totalValue}</div>} </div>


      <div>
        <h1 className='text__24 mb-2' style={{ fontFamily: "Nayuki" }}>Post Office Monthly Income Scheme</h1>
        <p className='text__16 leading-7 mb-2'>The Post Office Monthly Scheme, also known as Post Office MIS or POMIS, is a government-backed deposit scheme that offers monthly returns in the form of interest payouts. It is a sovereign fixed-income investment vehicle. The government reviews and fixes the interest rate of the scheme on a quarterly basis. This scheme is open to both joint and individual investors, and minors aged 10 years and above.</p>
        <p className='text__16 leading-7'>In the Union Budget 2023, the deposit limit for individual accounts was increased from ₹4.5 Lakh to ₹9 Lakh. Similarly, the deposit limit for joint accounts was raised to ₹15 Lakh from ₹9 Lakh. Until 31st March 2023, the savings scheme is offering returns at 7.1% to its investors. However, if the interest paid every month is not claimed by a depositor, such an amount will not earn any additional interest. </p>
      </div>
      <PopularCalculator />
    </Container>
  </section>
  )
}

export default Swp