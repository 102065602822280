import axios from "axios";
import { ip } from "../../../src/config/config";
import {
  FETCH_ADMIN_FAILED,
  FETCH_ADMIN_PROFILE,
  FETCH_USER_PROFILE,
  FETCH_ADMIN_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
} from "./ActionType";

export const FetchUserRequest = () => {
  return {
    type: FETCH_USER_PROFILE,
  };
};

export const FetchUserSuccess = (users) => {
  console.log(users, "usersSuccess");
  return {
    type: FETCH_USER_SUCCESS,
    payload: users,
  };
};

export const FetchUserFailure = (error) => {
  return {
    type: FETCH_USER_FAILED,
    payload: error,
  };
};

export const FetchUserProfile = () => {
  debugger;
  return function (dispatch) {
    dispatch(FetchUserRequest()); // Call the action creator as a function

    const token = localStorage.getItem("accessToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios
      .get(`${ip}/users/me`)
      .then((res) => {
        console.log(res.data, "response");
        const users = res.data;
        dispatch(FetchUserSuccess(users));
        console.log(users, "users");
      })
      .catch((err) => {
        console.log(err);
        dispatch(FetchUserFailure(err.message));
        // alert('No data found')
      });
  };
};
