import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import PopularCalculator from '../PopularCalculator/PopularCalculator';
import { Pie } from 'react-chartjs-2';
// import './PersonalLoancss.css'
// import rupeelogo from './../../../LottieJson/eazrop-logo.png'

import {
  Chart as ChartJS
} from "chart.js/auto"

const HomeLoanPrePayment = () => {
  const [outstandingAmount, setOutstandingAmount] = useState(2500000);
  const [interestRate, setInterestRate] = useState(8.39);
  const [loanTenure, setLoanTenure] = useState(20);
  const [partPrepaymentAmount, setPartPrepaymentAmount] = useState(100000);
  const [emi, setEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [outstandingamount, setOutstandingamount] = useState(0)

  // chart 

  const data = {
    labels: [
      'Outstanding Amount',
      'Interest Amount',
    ],
    datasets: [{
      label: 'Eazr',
      data: [outstandingamount, totalInterest],
      backgroundColor: [
        'rgb(174, 230, 255)',
        'rgb(13, 75, 218)',
        // 'rgb(255, 205, 86)'
      ],
      // hoverOffset: 1
    }]
  }

  //calculation 
  const calculateLoan = () => {
    const principal = outstandingAmount - partPrepaymentAmount;
    const rateOfInterest = interestRate / 100 / 12;
    const tenureInMonths = loanTenure * 12;

    const emi =
      (principal * rateOfInterest * Math.pow(1 + rateOfInterest, tenureInMonths)) /
      (Math.pow(1 + rateOfInterest, tenureInMonths) - 1);

    const totalInterest = emi * tenureInMonths - principal;
    const totalAmount = principal + totalInterest;

    setEMI(emi.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setTotalAmount(totalAmount.toFixed(2));
    setOutstandingamount(totalAmount - totalInterest)
  };

  useEffect(() => {
    calculateLoan()
  }, [outstandingAmount, interestRate, loanTenure, partPrepaymentAmount])
  return (
    <section className='py-6'>
      <Container>
        <h2 className='text-left text__32 mb-3 bg-Mblue p-2 text-white rounded-lg'>Home Loan Prepayment Calculator</h2>
        <p className='text__16 mb-3'>Planning to prepay a part of your home loan? Use our Home Loan Prepayment Calculator to fathom the savings you can achieve on interest, and get insights into the revised loan tenure. Make an informed decision on whether prepayment aligns with your financial objectives.</p>
        <h2 className='text-left text__32 mb-3'>How to Use:</h2>
        <p className='text__16 mb-10'>
          1. Enter the original loan amount.<br />
          2. Specify the original loan tenure (in years or months).<br />
          3. Input the rate of interest applied to your home loan. <br />
        </p>
        <div className='flex flex-wrap mb-4 container py-4 emidiv rounded-lg' style={{ border: "2px solid #F5F5F5" }}>
          <div className=' w-full sm:w-1/2 p-3 py-4 h-max'>
            <div className='flex flex-col'>
              <div className='row w-full mb-5'>
                <label for="outstandingAmount">
                  <div className="row w-full">
                    <p className='col-6 w-[50%] text-left font-bold'>Outstanding Amount (₹):</p>
                    <input
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      type="number"
                      value={outstandingAmount}
                      id="outstandingAmount"
                      min="1000"
                      onChange={(e) => setOutstandingAmount(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="outstandingAmount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="2000"
                      value={outstandingAmount}
                      onChange={(e) => setOutstandingAmount(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full mb-5'>
                <label for="interestRate">
                  <div className="w-full flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Rate of Interest (% p.a):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={interestRate}
                      id="interestRate"
                      min="0"
                      onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="interestRate"
                      name="points"
                      className='form-range'
                      min="0"
                      max="40"
                      step="2"
                      value={interestRate}
                      onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="loanTenure">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Loan Tenure (Years):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={loanTenure}
                      id="loanTenure"
                      min="0"
                      onChange={(e) => setLoanTenure(parseInt(e.target.value) || 0)}

                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="loanTenure"
                      name="points"
                      className='form-range'
                      min="0"
                      max="200"
                      step="25"
                      value={loanTenure}
                      onChange={(e) => setLoanTenure(parseInt(e.target.value) || 0)}

                    />
                  </div>
                </label>
              </div>
              <br />
              <div className='row w-full'>
                <label for="partPrepaymentAmount">
                  <div className="flex justify-between row w-100">
                    <p className='col-6 w-[50%] text-left font-bold'>Part-prepayment Amount (₹):</p>
                    <input
                      type="number"
                      className='col-6 ml-auto w-[50%] text-right font-bold'
                      value={partPrepaymentAmount}
                      id="partPrepaymentAmount"
                      min="0"
                      onChange={(e) => setPartPrepaymentAmount(parseFloat(e.target.value) || 0)}

                    />{" "}
                  </div>
                  <div className="">
                    <input
                      type="range"
                      id="partPrepaymentAmount"
                      name="points"
                      className='form-range'
                      min="0"
                      max="1000000"
                      step="1000"
                      value={partPrepaymentAmount}
                      onChange={(e) => setPartPrepaymentAmount(parseFloat(e.target.value) || 0)}

                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className=' w-full sm:w-1/2 p-3 h-max flex flex-col items-center'>
            <div className='h-80 piechart'><Pie data={data} /></div>
          </div>
        </div>
        <div className='flex flex-col justify-center border p-3 bg-Mbluelight text-Mblue font-bold mb-4 rounded mt-2 md:flex-row' style={{}}>
          {emi && <div className='m-auto'>Monthly EMI: {emi}</div>}
          {totalInterest && <div className='m-auto'>Total Interest: {totalInterest}</div>}
          {totalAmount && <div className='m-auto'>Total Amount: {totalAmount}</div>}
        </div>

        <div>
          <h2 className='text-left text__32 mb-3'>Why Use Our EMI Calculator?</h2>
          <p className='text__16 mb-4'>
            1. Informed Decisions: Gauge whether prepayment aligns with your financial goals and if it offers significant savings.<br />
            2. Long-term Perspective: Get a clear picture of how prepayment affects your long-term financial commitments and goals.<br />
            3. Tailored Strategy: Play around with different prepayment amounts and timings to devise a strategy that best suits your needs.
          </p>
          <p className='text-Mblue'>
            Our Fintech platform is committed to empowering users with tools and resources for better financial management. Explore more calculators and financial tools to guide your financial journey.
          </p>
        </div>
        <PopularCalculator />
      </Container>
    </section>
  )
}

export default HomeLoanPrePayment